import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AgregarhorarioComponent } from './agregarhorario/agregarhorario.component';
import { EditHorariosMotivoComponent } from './change/changemotivo.component';
import { EditarhorarioComponent } from './editarhorario/editarhorario.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgbModule,
		NgxUiLoaderModule,
	],
	declarations:[AgregarhorarioComponent,EditHorariosMotivoComponent,EditarhorarioComponent],
	entryComponents:[AgregarhorarioComponent,EditHorariosMotivoComponent,EditarhorarioComponent],
})
export class ModalHorarioModule { }
