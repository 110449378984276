import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Beneficiarios} from './beneficiarios.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Eventos } from '../../eventos/evento/eventos.model';


@Injectable()
export class BeneficiariosServices {

	constructor(private http: HttpClient) { }

	getDataBeneficiarios():Observable<Beneficiarios[]>{
		return this.http.get<Beneficiarios[]>(Globals.BASE_URL_API_REST+'beneficiarios.json');
	}

	getDataBeneficiariosVista(page:number):Observable<Beneficiarios[]>{
		return this.http.get<Beneficiarios[]>(Globals.BASE_URL_API_REST+'beneficiariosvista.json?page='+page);
	}


	saveBeneficiarios(data:Beneficiarios){
		return this.http.post(Globals.BASE_URL_API_REST+'beneficiarios.json',data);
	}

	deleteBeneficiarios(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'beneficiarios/'+id+'.json');
	}

	updateBeneficiarios(data:Beneficiarios){
		 return this.http.patch(Globals.BASE_URL_API_REST+'beneficiarios/'+data.id+'.json',data);
	}

	viewBeneficiarios(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'beneficiarios/'+id+'.json');
	}

	listaactivos(data:Eventos){
		return this.http.patch(Globals.BASE_URL_API_REST+'beneficiarioslista.json',data);
	}

	listaactivosGastos(data:Eventos){
		return this.http.post(Globals.BASE_URL_API_REST+'beneficiarioslista.json',data);
	}

	buscarGastosBeneficiarios(){
		return this.http.put(Globals.BASE_URL_API_REST+'beneficiarioslista.json',{});
	}

	buscarBeneficiarios(data:Eventos){
		return this.http.put(Globals.BASE_URL_API_REST+'beneficiarioslistageneral.json',data);
	}

	buscar(data:Beneficiarios){
		return this.http.post(Globals.BASE_URL_API_REST+'beneficiarioslistageneral.json',data);
	}

	buscarAportesBeneficiarios(data:Eventos){
		return this.http.put(Globals.BASE_URL_API_REST+'beneficiarioslistados.json',data);
	}
	
	buscarCuotasBeneficiarios(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'beneficiarioslistados.json',data);
	}

	buscarBeneficiariosAdministrativo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'administrativoslista.json',data);
	}

	
}