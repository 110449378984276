import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Asistencias } from '../../asistencia/asistencias.model';
import { AsistenciasServices } from '../../asistencia/asistencias.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-edit-motivo-asistencia',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditAsistenciaMotivoComponent implements OnInit {

	@Input() Asistencia:Asistencias;
	public formAsistenciaMotivo:FormGroup;
	userdata:any;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private servicesAsistencias:AsistenciasServices,
		private Noti:NotificationServices,) { }

	ngOnInit(){
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.formAsistenciaMotivo=this.fb.group({
            motivo:[this.Asistencia.motivo,Validators.compose([Validators.required,Validators.minLength(15), Validators.maxLength(50)])],
            estado:[2,Validators.compose([Validators.required])],
		});
	}

	saveAsistenciaMotivo(){
		let AsistenciaDataModel:Asistencias=this.formAsistenciaMotivo.value;
		AsistenciaDataModel.id=this.Asistencia.id;
        this.Asistencia.motivo=AsistenciaDataModel.motivo;
		this.Asistencia.estado=AsistenciaDataModel.estado;
		
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				this.Asistencia.administrativo_id=datadmin.administrativo.id;
				AsistenciaDataModel.administrativo_id=datadmin.administrativo.id;
				this.servicesAsistencias.updateAsistencias(AsistenciaDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.activeModal.close(1);
							}
						});
					}else{
						this.Noti.setNotification(data);
					}
				});
			});
		}
	}

}