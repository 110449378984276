import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditCuotaComponent } from './edit/editcuota.component';
import { AddCuotaComponent } from './add/addcuota.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ListaCuotaPagadasComponent } from './lista/listcuotaspagadas.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditPagoMotivoCuotaComponent } from './change/changemotivo.component';
import { ListaCuotaPagarPagoComponent } from './listapagar/listcuotaspagadas.component';
import { ListaCuotaPagarFechasPagoComponent } from './listapagarcuotas/listcuotaspagadas.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from  'ngx-ui-loader';
@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[EditCuotaComponent,AddCuotaComponent,ListaCuotaPagadasComponent,EditPagoMotivoCuotaComponent,ListaCuotaPagarPagoComponent,ListaCuotaPagarFechasPagoComponent],
	entryComponents:[EditCuotaComponent,AddCuotaComponent,ListaCuotaPagadasComponent,EditPagoMotivoCuotaComponent,ListaCuotaPagarPagoComponent,ListaCuotaPagarFechasPagoComponent],
})
export class ModalCuotaModule { }
