import { Routes } from '@angular/router';
import { AuthGuard } from '../../appauth/auth/auth.guard';
import { SociosVistaComponent } from './vistas/sociovista.component';
import { SimuladorComponent } from './simulador/simulador.component';

export const SociosRoutes: Routes = [{
	path: '',
	redirectTo: 'index',
	pathMatch: 'full',
	canActivate: [AuthGuard]
},{
	path: '',
	children: [{
		path: 'index',
		component: SociosVistaComponent,
		canActivate: [AuthGuard],
		data: { 
			roles: ['Administrador','Director'],
			title: 'Listado de socios',
			urls: [{ title: 'Home', url: '/dashboard' }, { title: 'Página principal' }]
		}
	}]
},{
	path: '',
	children: [{
		path: 'simulador',
		component: SimuladorComponent,
		canActivate: [AuthGuard],
		data: { roles: ['Administrador','Director'] ,title:'Simulador de aporte minutos'}
	}]
}];
