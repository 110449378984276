import { Component, OnInit,ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../../global/global.model';
import { EvidenciasServices } from '../evidencia/evidencias.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
	selector: 'ms-imagenes',
	templateUrl:'./imagenes.component.html',
	styleUrls: ['./imagenes.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class EvidenciasImagenesVistaComponent implements OnInit {
	rows = [];
	public imgUrlBeneficiarios:string;
    pdfpath:any="";
    @Input() Pagogasto:any;

    ngOnInit(){
        this.imgUrlBeneficiarios=Globals.BASE_URL_API_REST;
        this.serviciosEvidencias.viewEvidencias(this.Pagogasto).subscribe((data:any)=>{
            this.rows=data.evidencias;
		});
    }

    constructor(public dialog: NgbModal,
        private ngxService: NgxUiLoaderService,
		private serviciosEvidencias:EvidenciasServices,
    ){}
    
}