import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Pagogastos } from '../../pagogasto/pagogastos.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { Cobrosmultas } from '../../../cobrosmultas/cobrosmulta/cobrosmultas.model';
import { CobrosmultasServices } from '../../../cobrosmultas/cobrosmulta/cobrosmultas.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
	selector: 'app-addPagogasto-sancion',
	templateUrl: './sancion.component.html',
	styleUrls: ['./sancion.component.scss']
})

export class AddSancionPagogastoEventoComponent implements OnInit {
	rows = [];
	temp = [];
    public imgUrlSocios:string;
	userdata:any;
	inconoLoder:string=SPINNER.wanderingCubes;
	
    @Input() Pagogasto = {'tipo':3} as Pagogastos;
	@Input() Multasperiodo = {'montomulta':0} as Multasperiodos;
	
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioSocios:SociosServices,
		private servicioCobrosMultas:CobrosmultasServices,
		private ngxService: NgxUiLoaderService,
		private servicioadministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
		//console.log(this.Pagogasto);
		
		//console.log(this.Multasperiodo);
		this.ngxService.startLoader('loaderMultasAgregarSosio');
		this.servicioSocios.listaactivosMultas(this.Pagogasto).subscribe((data:any)=>{
            this.rows=data.socios;
			this.temp = [...this.rows];
            this.ngxService.stopLoader('loaderMultasAgregarSosio');
        });
    }
    
    updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}

    selectgeneral(data:any){
		
		let dataPagos= {} as Cobrosmultas;
		this.ngxService.startLoader('loaderMultasAgregarSosio');
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				
				/*dataPagos.administrativo_id=datadmin.administrativo.id;
				dataPagos.pagogasto_id=this.Pagogasto.id;
				dataPagos.socio_id=data.id;
				dataPagos.abono=this.Multasperiodo.montomulta;
				dataPagos.estado=0;
				dataPagos.sectore_id=0;
				this.servicioCobrosMultas.saveCobrosmultas(dataPagos).subscribe((datasave:any)=>{
					if(datasave.estado){
						this.Noti.setNotification(datasave).then((datas)=>{
							if(datas){
								this.servicioSocios.listaactivosMultas(this.Pagogasto).subscribe((data:any)=>{
									this.rows=data.socios;
									this.temp = [...this.rows];
								});
								this.ngxService.stopLoader('loaderMultasAgregarSosio');
							}
						});
					}else{
						this.ngxService.stopLoader('loaderMultasAgregarSosio');
						this.Noti.setNotification(datasave);
					}
				});*/

				

			});
		}
	}

	select(data:any){
		
		
		this.ngxService.startLoader('loaderMultasAgregarSosio');
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				data.registros.forEach((obj:any,index) => {
					setTimeout(()=>{

						/*let dataPagos= {} as Cobrosmultas;
						dataPagos.administrativo_id=datadmin.administrativo.id;
						dataPagos.pagogasto_id=this.Pagogasto.id;
						dataPagos.socio_id=data.id;
						dataPagos.abono=this.Multasperiodo.montomulta;
						dataPagos.estado=0;
						dataPagos.sectore_id=obj.sectore_id;
						this.servicioCobrosMultas.saveCobrosmultas(dataPagos).subscribe((datasave:any)=>{
							if(datasave.estado){
								this.Noti.setNotification(datasave).then((datas)=>{
									if(datas){
										this.servicioSocios.listaactivosMultas(this.Pagogasto).subscribe((data:any)=>{
											this.rows=data.socios;
											this.temp = [...this.rows];
										});
										this.ngxService.stopLoader('loaderMultasAgregarSosio');
									}
								});
							}else{
								this.ngxService.stopLoader('loaderMultasAgregarSosio');
								this.Noti.setNotification(datasave);
							}
						});*/

					},1500*index);
				});
			});
		}
	}
	
}