import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Administrativos } from '../../administrativo/administrativos.model';
import { AdministrativosServices } from '../../administrativo/administrativos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Periodos } from '../../../periodos/periodo/periodos.model';
import { PeriodosServices } from '../../../periodos/periodo/periodos.services';

@Component({
	selector: 'app-edit-motivo',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditAdministrativoMotivoComponent implements OnInit {

	@Input() Administrativo:Administrativos;
	

	public formAdministrativoMotivo:FormGroup;
    
    constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioAdministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAdministrativoMotivo=this.fb.group({
            motivo:[this.Administrativo.motivo,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(200)])],
            estado:[this.Administrativo.estado,Validators.compose([Validators.required])],
		});
	}
	
	saveAdministrativoMotivo(){
		let AdministrativoDataModel:Administrativos=this.formAdministrativoMotivo.value;
		AdministrativoDataModel.id=this.Administrativo.id;
        this.Administrativo.motivo=AdministrativoDataModel.motivo;
        this.Administrativo.estado=AdministrativoDataModel.estado;

        if(this.formAdministrativoMotivo.value.estado)
            this.formAdministrativoMotivo.value.estado=1;
        else
            this.formAdministrativoMotivo.value.estado=0;

		this.servicioAdministrativo.updateAdministrativos(AdministrativoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

}