import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ElementComponentService } from '../servicios/servicios-component.services';

@Component({
  selector: 'app-sub-periodos-seleccion',
  templateUrl: './sub-periodos-seleccion.component.html',
  styleUrls: ['./sub-periodos-seleccion.component.scss']
})
export class SubPeriodosSeleccionComponent implements OnInit {
  rowps = [];
	temp = [];
  @Output() valueChange = new EventEmitter();
  
  @ViewChild('socioPeriodo') cbxperiodo: any;
	@ViewChild('socioSubPeriodo') cbxsubperiodo: any;
  
  constructor(private serviciosPeriodos: ElementComponentService,
		private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.listaPeriodos();
  }

  listaPeriodos() {
		this.ngxService.startLoader('princialLoder');
		this.serviciosPeriodos.getDataPeriodosElemento().subscribe((data: any) => {
			this.rowps = data.periodos
			this.ngxService.stopLoader('princialLoder');
		});
	}

  selecPeriodo(data: any) {
		if (data != undefined) {
			this.temp = data.subperiodos;
		} else {
			this.temp = [];
			this.cbxsubperiodo.handleClearClick();
		}
	}

  seleccionarSubPeriodo(data:any){
    this.valueChange.emit(data);
  }

}
