import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagos } from '../../pago/pagos.model';
import { PagosServices } from '../../pago/pagos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Eventos } from '../../../eventos/evento/eventos.model';
import { EventosServices } from '../../../eventos/evento/eventos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-editpago',
	templateUrl: './editpago.component.html',
	styleUrls: ['./editpago.component.scss']
})

export class EditPagoComponent implements OnInit {

	@Input() Pago:Pagos;
	public dataSocios={'cedula':'vacio'} as Socios;
	public sociosData:any;
	public dataEventos={'detalle':'vacio'} as Eventos;
	public eventosData:any;
	public dataAdministrativos={'cargo':'vacio'} as Administrativos;
	public administrativosData:any;

	public formPagoMontototal:FormGroup;
	public editMontototal:boolean=false;

	public formPagoTipo:FormGroup;
	public editTipo:boolean=false;

	public formPagoSocio:FormGroup;
	public editSocio:boolean=false;

	public formPagoEvento:FormGroup;
	public editEvento:boolean=false;

	public formPagoAdministrativo:FormGroup;
	public editAdministrativo:boolean=false;

	public formPagoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPago:PagosServices,
		private servicioSocio:SociosServices,
		private servicioEvento:EventosServices,
		private servicioAdministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.sociosData=data.socios;
			this.dataSocios=this.sociosData.find(obj => obj.id == this.Pago.socio_id);
		});
		this.servicioEvento.getDataEventos().subscribe((data:any)=>{
			this.eventosData=data.eventos;
			this.dataEventos=this.eventosData.find(obj => obj.id == this.Pago.evento_id);
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.administrativosData=data.administrativos;
			this.dataAdministrativos=this.administrativosData.find(obj => obj.id == this.Pago.administrativo_id);
		});


		this.formPagoTipo=this.fb.group({
			tipo:[this.Pago.tipo,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPagoSocio=this.fb.group({
			socio_id:[this.Pago.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPagoEvento=this.fb.group({
			evento_id:[this.Pago.evento_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPagoAdministrativo=this.fb.group({
			administrativo_id:[this.Pago.administrativo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	


	editPagoTipoEnable(){
		this.editTipo=true;
		this.formPagoTipo=this.fb.group({
			tipo:[this.Pago.tipo,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoTipoDisable(){
		this.editTipo=false;
	}

	savePagoTipo(){
		let PagoDataModel:Pagos=this.formPagoTipo.value;
		PagoDataModel.id=this.Pago.id;
		this.Pago.tipo=PagoDataModel.tipo;
		this.servicioPago.updatePagos(PagoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTipo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPagoSocioEnable(){
		this.editSocio=true;
		this.formPagoSocio=this.fb.group({
			socio_id:[this.Pago.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoSocioDisable(){
		this.editSocio=false;
	}

	savePagoSocio(){
		let PagoDataModel:Pagos=this.formPagoSocio.value;
		PagoDataModel.id=this.Pago.id;
		this.Pago.socio_id=PagoDataModel.socio_id;
		this.servicioPago.updatePagos(PagoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSocio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPagoEventoEnable(){
		this.editEvento=true;
		this.formPagoEvento=this.fb.group({
			evento_id:[this.Pago.evento_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoEventoDisable(){
		this.editEvento=false;
	}

	savePagoEvento(){
		let PagoDataModel:Pagos=this.formPagoEvento.value;
		PagoDataModel.id=this.Pago.id;
		this.Pago.evento_id=PagoDataModel.evento_id;
		this.servicioPago.updatePagos(PagoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEvento=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPagoAdministrativoEnable(){
		this.editAdministrativo=true;
		this.formPagoAdministrativo=this.fb.group({
			administrativo_id:[this.Pago.administrativo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoAdministrativoDisable(){
		this.editAdministrativo=false;
	}

	savePagoAdministrativo(){
		let PagoDataModel:Pagos=this.formPagoAdministrativo.value;
		PagoDataModel.id=this.Pago.id;
		this.Pago.administrativo_id=PagoDataModel.administrativo_id;
		this.servicioPago.updatePagos(PagoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editAdministrativo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

