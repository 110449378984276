import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Socios} from './socios.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Eventos } from '../../eventos/evento/eventos.model';


@Injectable()
export class SociosServices {

	constructor(private http: HttpClient) { }

	getDataSocios():Observable<Socios[]>{
		return this.http.get<Socios[]>(Globals.BASE_URL_API_REST+'socios.json');
	}

	getDataSociosVista():Observable<Socios[]>{
		return this.http.get<Socios[]>(Globals.BASE_URL_API_REST+'sociosvista.json');
	}


	saveSocios(data:Socios){
		return this.http.post(Globals.BASE_URL_API_REST+'socios.json',data);
	}

	deleteSocios(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'socios/'+id+'.json');
	}

	updateSocios(data:Socios){
		 return this.http.patch(Globals.BASE_URL_API_REST+'socios/'+data.id+'.json',data);
	}

	viewSocios(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'socios/'+id+'.json');
	}

	listaactivos(data:Eventos){
		return this.http.patch(Globals.BASE_URL_API_REST+'socioslista.json',data);
	}

	listaactivosMultas(data:Eventos){
		return this.http.post(Globals.BASE_URL_API_REST+'socioslista.json',data);
	}

	buscarMultasSocios(data:Eventos){
		return this.http.put(Globals.BASE_URL_API_REST+'socioslista.json',data);
	}

	buscarSocios(data:Eventos){
		return this.http.put(Globals.BASE_URL_API_REST+'socioslistageneral.json',data);
	}

	buscar(data:Socios){
		return this.http.post(Globals.BASE_URL_API_REST+'socioslistageneral.json',data);
	}

	buscarAportesSocios(data:Eventos){
		return this.http.put(Globals.BASE_URL_API_REST+'socioslistados.json',data);
	}
	
	buscarCuotasSocios(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'socioslistados.json',data);
	}

	buscarSociosAdministrativo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'administrativoslista.json',data);
	}

	buscarSociosPorSector(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'opsocios.json',data);
	}

	buscarSociosPorSectorActividad(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'opsociosfiltrosector.json',data);
	}
	
	
}