import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSolicitudprestamoComponent } from './edit/editsolicitudprestamo.component';
import { AddSolicitudprestamoComponent } from './add/addsolicitudprestamo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from  'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[EditSolicitudprestamoComponent,AddSolicitudprestamoComponent],
	entryComponents:[EditSolicitudprestamoComponent,AddSolicitudprestamoComponent],
})
export class ModalSolicitudprestamoModule { }
