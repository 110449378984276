import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Registros} from './registros.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class RegistrosServices {

	constructor(private http: HttpClient) { }

	getDataRegistros():Observable<Registros[]>{
		return this.http.get<Registros[]>(Globals.BASE_URL_API_REST+'registros.json');
	}

	saveRegistros(data:Registros){
		return this.http.post(Globals.BASE_URL_API_REST+'registros.json',data);
	}

	deleteRegistros(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'registros/'+id+'.json');
	}

	updateRegistros(data:Registros){
		 return this.http.patch(Globals.BASE_URL_API_REST+'registros/'+data.id+'.json',data);
	}

	viewRegistros(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'registros/'+id+'.json');
	}
}