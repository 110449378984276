import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-page-total-pago',
  templateUrl: './home-page-total-pago.component.html',
  styleUrls: ['./home-page-total-pago.component.scss']
})
export class HomePageTotalPagoComponent implements OnInit {
  @Input() pagos:any;
  total:number=0;
  cuotas:number=0;
  constructor() { }

  ngOnInit() {
   
    this.pagos.forEach(element => {
      let val=this.round(element.montototal);
      if(element.cuotas.length>0){
        val=this.round(val-element.cuotas[0].total);
      }
      this.total+=this.round(val);
    });
    this.total=this.round(this.total-this.cuotas);
  }

  round(num: any, decimales = 2) {
		let signo: any = (num >= 0 ? 1 : -1);
		num = num * signo;
		if (decimales === 0) //con 0 decimales
			return signo * Math.round(num);
		// round(x * 10 ^ decimales)
		num = num.toString().split('e');
		num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
		// x * 10 ^ (-decimales)
		num = num.toString().split('e');
		let val: any = num[0] + 'e';
		return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
	}

}
