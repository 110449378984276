import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAporteComponent } from './edit/editaporte.component';
import { AddAporteComponent } from './add/addaporte.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AddAporteStaticComponent } from './addstatic/addstaticaporte.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		NgxDatatableModule,
		NgbModule,
		ReactiveFormsModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[EditAporteComponent,AddAporteComponent,AddAporteStaticComponent],
	entryComponents:[EditAporteComponent,AddAporteComponent,AddAporteStaticComponent],
})
export class ModalAporteModule { }
