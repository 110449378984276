import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Solicitudprestamos} from '../../solicitudprestamos/solicitudprestamo/solicitudprestamos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Eventos } from '../../eventos/evento/eventos.model';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';
import { Socios } from '../../socios/socio/socios.model';


@Injectable()
export class SolicitudPrestamoServices {

	constructor(private http: HttpClient) { }

	getDataSubperiodoActivo():Observable<Subperiodos[]>{
		return this.http.get<Subperiodos[]>(Globals.BASE_URL_API_REST+'solicitud.json');
	}

	getDataSociosSinSolicitudes(data:any):Observable<Socios[]>{
		return this.http.patch<Socios[]>(Globals.BASE_URL_API_REST+'solicitudsocios.json',data);
	}

	getDataSociosSuma(data:any):Observable<any[]>{
		return this.http.patch<any[]>(Globals.BASE_URL_API_REST+'solicitud.json',data);
	}
	
	getDataSociosSumaPrestamosPendientes(data:any):Observable<any[]>{
		return this.http.post<any[]>(Globals.BASE_URL_API_REST+'solicitud.json',data);
	}
}