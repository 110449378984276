import { Component, OnInit, Input } from '@angular/core';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Globals } from '../../../../global/global.model';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tesorerosectores } from '../../../sectores/sectore/tesorerosectores.model';
import { TesorerosectoresServices } from '../../../sectores/sectore/tesorerosectores.services';

@Component({
  selector: 'app-asignar-sector-tesorero',
  templateUrl: './asignar-sector-tesorero.component.html',
  styleUrls: ['./asignar-sector-tesorero.component.scss']
})
export class AsignarSectorTesoreroComponent implements OnInit {
  @Input() Administrativo:any;
  dataSectores=[];
  public formAddTesoreroSectore: FormGroup;
  public imgUrlSocios:string;

  constructor(private activeModal:NgbActiveModal,private fb: FormBuilder,private Noti:NotificationServices,private servicioTesoreros:SectoresServices,private servicioTesoreroSector:TesorerosectoresServices) { }

  ngOnInit() {

    this.imgUrlSocios=Globals.BASE_URL_API_REST;

    this.servicioTesoreros.getDataSectoresTesoreros(this.Administrativo).subscribe((data:any)=>{
      if(data.sectores){
        this.dataSectores=data.sectores;
      }
    });

    this.formAddTesoreroSectore = this.fb.group({
			sectore_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			administrativo_id:[this.Administrativo.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
    });
    
  }

  save(){
		let registroDataModel:Tesorerosectores=this.formAddTesoreroSectore.value;
		this.servicioTesoreroSector.saveTesorerosectores(registroDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
  }
  
  cerrarModal(){
    this.activeModal.close(2);
  }

}
