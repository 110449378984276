import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Aportesperiodos } from '../../aportesperiodo/aportesperiodos.model';
import { AportesperiodosServices } from '../../aportesperiodo/aportesperiodos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Aportes } from '../../../aportes/aporte/aportes.model';
import { AportesServices } from '../../../aportes/aporte/aportes.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-editaportesperiodo',
	templateUrl: './editaportesperiodo.component.html',
	styleUrls: ['./editaportesperiodo.component.scss']
})

export class EditAportesperiodoComponent implements OnInit {

	@Input() Aportesperiodo:Aportesperiodos;
	public dataAportes={'motivo':'vacio'} as Aportes;
	public aportesData:any;
	public dataSubperiodos={'nombre':'vacio'} as Subperiodos;
	public subperiodosData:any;

	public formAportesperiodoMontoaporte:FormGroup;
	public editMontoaporte:boolean=false;

	public formAportesperiodoAporte:FormGroup;
	public editAporte:boolean=false;

	public formAportesperiodoSubperiodo:FormGroup;
	public editSubperiodo:boolean=false;

	public formAportesperiodoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioAportesperiodo:AportesperiodosServices,
		private servicioAporte:AportesServices,
		private servicioSubperiodo:SubperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioAporte.getDataAportes(1).subscribe((data:any)=>{
			this.aportesData=data.aportes;
			this.dataAportes=this.aportesData.find(obj => obj.id == this.Aportesperiodo.aporte_id);
		});
		this.servicioSubperiodo.getDataSubperiodos().subscribe((data:any)=>{
			this.subperiodosData=data.subperiodos;
			this.dataSubperiodos=this.subperiodosData.find(obj => obj.id == this.Aportesperiodo.subperiodo_id);
		});


		this.formAportesperiodoAporte=this.fb.group({
			aporte_id:[this.Aportesperiodo.aporte_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formAportesperiodoSubperiodo=this.fb.group({
			subperiodo_id:[this.Aportesperiodo.subperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editAportesperiodoAporteEnable(){
		this.editAporte=true;
		this.formAportesperiodoAporte=this.fb.group({
			aporte_id:[this.Aportesperiodo.aporte_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editAportesperiodoAporteDisable(){
		this.editAporte=false;
	}

	saveAportesperiodoAporte(){
		let AportesperiodoDataModel:Aportesperiodos=this.formAportesperiodoAporte.value;
		AportesperiodoDataModel.id=this.Aportesperiodo.id;
		this.Aportesperiodo.aporte_id=AportesperiodoDataModel.aporte_id;
		this.servicioAportesperiodo.updateAportesperiodos(AportesperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editAporte=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editAportesperiodoSubperiodoEnable(){
		this.editSubperiodo=true;
		this.formAportesperiodoSubperiodo=this.fb.group({
			subperiodo_id:[this.Aportesperiodo.subperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editAportesperiodoSubperiodoDisable(){
		this.editSubperiodo=false;
	}

	saveAportesperiodoSubperiodo(){
		let AportesperiodoDataModel:Aportesperiodos=this.formAportesperiodoSubperiodo.value;
		AportesperiodoDataModel.id=this.Aportesperiodo.id;
		this.Aportesperiodo.subperiodo_id=AportesperiodoDataModel.subperiodo_id;
		this.servicioAportesperiodo.updateAportesperiodos(AportesperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSubperiodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

