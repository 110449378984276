import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Beneficiarios } from '../../beneficiario/beneficiarios.model';
import { BeneficiariosServices } from '../../beneficiario/beneficiarios.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-editbeneficiario',
	templateUrl: './editbeneficiario.component.html',
	styleUrls: ['./editbeneficiario.component.scss']
})

export class EditBeneficiarioComponent implements OnInit {

	@Input() Beneficiario:Beneficiarios;

	public formBeneficiarioCedula:FormGroup;
	public editCedula:boolean=false;

	public formBeneficiarioNombres:FormGroup;
	public editNombres:boolean=false;

	public formBeneficiarioApellidos:FormGroup;
	public editApellidos:boolean=false;

	public formBeneficiarioDireccion:FormGroup;
	public editDireccion:boolean=false;

	public formBeneficiarioTelefono:FormGroup;
	public editTelefono:boolean=false;

	public formBeneficiarioCelular:FormGroup;
	public editCelular:boolean=false;

	public formBeneficiarioEmail:FormGroup;
	public editEmail:boolean=false;

	public formBeneficiarioEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private validatciones:customValidatorMondogo,
		private servicioBeneficiario:BeneficiariosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		if(this.Beneficiario.tipo==1){
			this.formBeneficiarioCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.Beneficiario.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formBeneficiarioCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Beneficiario.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
		

		this.formBeneficiarioNombres=this.fb.group({
			nombres:[this.Beneficiario.nombres,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});

		this.formBeneficiarioApellidos=this.fb.group({
			apellidos:[this.Beneficiario.apellidos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});

		this.formBeneficiarioDireccion=this.fb.group({
			direccion:[this.Beneficiario.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});

		this.formBeneficiarioTelefono=this.fb.group({
			telefono:[this.Beneficiario.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
		});

		this.formBeneficiarioCelular=this.fb.group({
			celular:[this.Beneficiario.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
		});

		this.formBeneficiarioEmail=this.fb.group({
			email:[this.Beneficiario.email,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}
	

	editBeneficiarioCedulaEnable(){
		this.editCedula=true;
		this.formBeneficiarioCedula=this.fb.group({
			cedula: [this.Beneficiario.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
		});
	}

	editBeneficiarioCedulaDisable(){
		this.editCedula=false;
	}

	saveBeneficiarioCedula(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioCedula.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.cedula=BeneficiarioDataModel.cedula;
		this.Beneficiario.tipo=BeneficiarioDataModel.tipo;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCedula=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editBeneficiarioNombresEnable(){
		this.editNombres=true;
		this.formBeneficiarioNombres=this.fb.group({
			nombres:[this.Beneficiario.nombres,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});
	}

	editBeneficiarioNombresDisable(){
		this.editNombres=false;
	}

	saveBeneficiarioNombres(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioNombres.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.nombres=BeneficiarioDataModel.nombres;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombres=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editBeneficiarioApellidosEnable(){
		this.editApellidos=true;
		this.formBeneficiarioApellidos=this.fb.group({
			apellidos:[this.Beneficiario.apellidos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});
	}

	editBeneficiarioApellidosDisable(){
		this.editApellidos=false;
	}

	saveBeneficiarioApellidos(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioApellidos.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.apellidos=BeneficiarioDataModel.apellidos;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editApellidos=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editBeneficiarioDireccionEnable(){
		this.editDireccion=true;
		this.formBeneficiarioDireccion=this.fb.group({
			direccion:[this.Beneficiario.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}

	editBeneficiarioDireccionDisable(){
		this.editDireccion=false;
	}

	saveBeneficiarioDireccion(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioDireccion.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.direccion=BeneficiarioDataModel.direccion;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editDireccion=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editBeneficiarioTelefonoEnable(){
		this.editTelefono=true;
		this.formBeneficiarioTelefono=this.fb.group({
			telefono:[this.Beneficiario.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
		});
	}

	editBeneficiarioTelefonoDisable(){
		this.editTelefono=false;
	}

	saveBeneficiarioTelefono(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioTelefono.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.telefono=BeneficiarioDataModel.telefono;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTelefono=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editBeneficiarioCelularEnable(){
		this.editCelular=true;
		this.formBeneficiarioCelular=this.fb.group({
			celular:[this.Beneficiario.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
		});
	}

	editBeneficiarioCelularDisable(){
		this.editCelular=false;
	}

	saveBeneficiarioCelular(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioCelular.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.celular=BeneficiarioDataModel.celular;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCelular=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editBeneficiarioEmailEnable(){
		this.editEmail=true;
		this.formBeneficiarioEmail=this.fb.group({
			email:[this.Beneficiario.email,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}

	editBeneficiarioEmailDisable(){
		this.editEmail=false;
	}

	saveBeneficiarioEmail(){
		let BeneficiarioDataModel:Beneficiarios=this.formBeneficiarioEmail.value;
		BeneficiarioDataModel.id=this.Beneficiario.id;
		this.Beneficiario.email=BeneficiarioDataModel.email;
		this.servicioBeneficiario.updateBeneficiarios(BeneficiarioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEmail=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	tipo(data:any){
		if(Number(data.target.value)===1){
			this.formBeneficiarioCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.Beneficiario.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formBeneficiarioCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Beneficiario.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

