import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Cobrosmultas } from '../../cobrosmulta/cobrosmultas.model';
import { CobrosmultasServices } from '../../cobrosmulta/cobrosmultas.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Actividades } from '../../../actividades/actividade/actividades.model';
import { ActividadesServices } from '../../../actividades/actividade/actividades.services';

@Component({
	selector: 'app-editcobrosmulta',
	templateUrl: './editcobrosmulta.component.html',
	styleUrls: ['./editcobrosmulta.component.scss']
})

export class EditCobrosmultaComponent implements OnInit {

	@Input() Cobrosmulta:Cobrosmultas;
	public dataSocios={'cedula':'vacio'} as Socios;
	public sociosData:any;
	public dataAdministrativos={'cargo':'vacio'} as Administrativos;
	public administrativosData:any;
	public dataActividades={'detalle':'vacio'} as Actividades;
	public actividadesData:any;

	public formCobrosmultaAbono:FormGroup;
	public editAbono:boolean=false;

	public formCobrosmultaSocio:FormGroup;
	public editSocio:boolean=false;

	public formCobrosmultaAdministrativo:FormGroup;
	public editAdministrativo:boolean=false;

	public formCobrosmultaActividade:FormGroup;
	public editActividade:boolean=false;

	public formCobrosmultaEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioCobrosmulta:CobrosmultasServices,
		private servicioSocio:SociosServices,
		private servicioAdministrativo:AdministrativosServices,
		private servicioActividade:ActividadesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.sociosData=data.socios;
			this.dataSocios=this.sociosData.find(obj => obj.id == this.Cobrosmulta.socio_id);
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.administrativosData=data.administrativos;
			this.dataAdministrativos=this.administrativosData.find(obj => obj.id == this.Cobrosmulta.administrativo_id);
		});
		this.servicioActividade.getDataActividades().subscribe((data:any)=>{
			this.actividadesData=data.actividades;
			this.dataActividades=this.actividadesData.find(obj => obj.id == this.Cobrosmulta.actividade_id);
		});


		this.formCobrosmultaSocio=this.fb.group({
			socio_id:[this.Cobrosmulta.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formCobrosmultaAdministrativo=this.fb.group({
			administrativo_id:[this.Cobrosmulta.administrativo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formCobrosmultaActividade=this.fb.group({
			actividade_id:[this.Cobrosmulta.actividade_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editCobrosmultaSocioEnable(){
		this.editSocio=true;
		this.formCobrosmultaSocio=this.fb.group({
			socio_id:[this.Cobrosmulta.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editCobrosmultaSocioDisable(){
		this.editSocio=false;
	}

	saveCobrosmultaSocio(){
		let CobrosmultaDataModel:Cobrosmultas=this.formCobrosmultaSocio.value;
		CobrosmultaDataModel.id=this.Cobrosmulta.id;
		this.Cobrosmulta.socio_id=CobrosmultaDataModel.socio_id;
		this.servicioCobrosmulta.updateCobrosmultas(CobrosmultaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSocio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editCobrosmultaAdministrativoEnable(){
		this.editAdministrativo=true;
		this.formCobrosmultaAdministrativo=this.fb.group({
			administrativo_id:[this.Cobrosmulta.administrativo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editCobrosmultaAdministrativoDisable(){
		this.editAdministrativo=false;
	}

	saveCobrosmultaAdministrativo(){
		let CobrosmultaDataModel:Cobrosmultas=this.formCobrosmultaAdministrativo.value;
		CobrosmultaDataModel.id=this.Cobrosmulta.id;
		this.Cobrosmulta.administrativo_id=CobrosmultaDataModel.administrativo_id;
		this.servicioCobrosmulta.updateCobrosmultas(CobrosmultaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editAdministrativo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editCobrosmultaActividadeEnable(){
		this.editActividade=true;
		this.formCobrosmultaActividade=this.fb.group({
			actividade_id:[this.Cobrosmulta.actividade_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editCobrosmultaActividadeDisable(){
		this.editActividade=false;
	}

	saveCobrosmultaActividade(){
		let CobrosmultaDataModel:Cobrosmultas=this.formCobrosmultaActividade.value;
		CobrosmultaDataModel.id=this.Cobrosmulta.id;
		this.Cobrosmulta.actividade_id=CobrosmultaDataModel.actividade_id;
		this.servicioCobrosmulta.updateCobrosmultas(CobrosmultaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editActividade=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

