import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Actividades } from '../../actividade/actividades.model';
import { ActividadesServices } from '../../actividade/actividades.services';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { MultasperiodosServices } from '../../../multasperiodos/multasperiodo/multasperiodos.services';

@Component({
	selector: 'app-addactividade',
	templateUrl: './addactividade.component.html',
	styleUrls: ['./addactividade.component.scss']
})

export class AddActividadeComponent implements OnInit {
	public dataMultasperiodos:any;
	public formAddActividade: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioActividade:ActividadesServices,
		private servicioMultasperiodo:MultasperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMultasperiodo.getDataMultasperiodos().subscribe((data:any)=>{
			this.dataMultasperiodos=data.multasperiodos;
		});
		this.formAddActividade = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			multasperiodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let actividadeDataModel:Actividades=this.formAddActividade.value;
		this.servicioActividade.saveActividades(actividadeDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}