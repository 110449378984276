import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Gastos} from './gastos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class GastosServices {

	constructor(private http: HttpClient) { }

	getDataGastos(page:number):Observable<Gastos[]>{
		return this.http.get<Gastos[]>(Globals.BASE_URL_API_REST+'gastos.json?page='+page);
	}

	saveGastos(data:Gastos){
		return this.http.post(Globals.BASE_URL_API_REST+'gastos.json',data);
	}

	deleteGastos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'gastos/'+id+'.json');
	}

	updateGastos(data:Gastos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'gastos/'+data.id+'.json',data);
	}

	viewGastos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'gastos/'+id+'.json');
	}

	viewGastosSubperiodo(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'listagastos.json',data);
	}

	saveGastosYPeriodo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listagastos.json',data);
	}

	getGastosSocio(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'gastosocios.json',data);
	}

}