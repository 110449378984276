import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import {AuthGuard} from './appauth/auth/auth.guard';
import {AuthLoginGuard} from './appauth/auth/auth.login.guard';


export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: 'home', 
        redirectTo: '/dashboard', 
        pathMatch: 'full',
        canActivate: [AuthGuard]
      },
      { path: 'dashboard', 
        loadChildren: './dashboards/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
        data: {
          title: 'Página principal',
        }
      },{
        path: 'group-pages',
        loadChildren: './appauth/group-pages/groups.module#GroupsModule',
        canActivate: [AuthGuard],
        data: { roles: ['Administrador'] }
      },{
        path: 'user-pages',
        loadChildren: './appauth/user-pages/users.module#UsersModule',
        canActivate: [AuthGuard]
      },{
        path: 'periodos',
        loadChildren: './appccamg/periodos/periodos.module#PeriodosModule',
        canActivate: [AuthGuard]
      },{
        path: 'socios',
        loadChildren: './appccamg/socios/socios.module#SociosModule',
        canActivate: [AuthGuard]
      },{
        path: 'multas',
        loadChildren: './appccamg/multas/multas.module#MultasModule',
        canActivate: [AuthGuard]
      },{
        path: 'gastos',
        loadChildren: './appccamg/gastos/gastos.module#GastosModule',
        canActivate: [AuthGuard]
      },{
        path: 'aportes',
        loadChildren: './appccamg/aportes/aportes.module#AportesModule',
        canActivate: [AuthGuard]
      },{
        path: 'subperiodo',
        loadChildren: './appccamg/subperiodos/subperiodos.module#SubperiodosModule',
        canActivate: [AuthGuard]
      },{
        path: 'administrativos',
        loadChildren: './appccamg/administrativos/administrativos.module#AdministrativosModule',
        canActivate: [AuthGuard]
      },{
        path: 'cobrosmultas',
        loadChildren: './appccamg/cobrosmultas/cobrosmultas.module#CobrosmultasModule',
        canActivate: [AuthGuard]
      },{
        path: 'eventos',
        loadChildren: './appccamg/eventos/eventos.module#EventosModule',
        canActivate: [AuthGuard]
      },{
        path: 'actividades',
        loadChildren: './appccamg/actividades/actividades.module#ActividadesModule',
        canActivate: [AuthGuard]
      },{
        path: 'pagos',
        loadChildren: './appccamg/pagos/pagos.module#PagosModule',
        canActivate: [AuthGuard]
      },{
        path: 'solicitud',
        loadChildren: './appccamg/solicitudcreditos/solicitudcreditos.module#SolicitudCreditosModule',
        canActivate: [AuthGuard]
      },{
        path: 'prestamo',
        loadChildren: './appccamg/prestamos/prestamos.module#PrestamosModule',
        canActivate: [AuthGuard]
      },{
        path: 'pago-prestamo',
        loadChildren: './appccamg/pagoprestamos/pagoprestamos.module#PagoprestamosModule',
        canActivate: [AuthGuard]
      },{
        path: 'reportes-recaudacion',
        loadChildren: './appccamg/reporte/reportes.module#ReportesRecaudaciobModule',
        canActivate: [AuthGuard],
        data: { roles: ['Tesorero','Secretario'] }
      },{
        path: 'consulta',
        loadChildren: './appccamg/consultas/consultas.module#ConsultasModule',
        canActivate: [AuthGuard],
        data: { roles: ['Tesorero','Secretario'] }
      },{
        path: 'sectores',
        loadChildren: './appccamg/sectores/sectores.module#SectoresModule',
        canActivate: [AuthGuard]
      },{
        path: 'pagogastos',
        loadChildren: './appccamg/pagogastos/pagogastos.module#PagogastosModule',
        canActivate: [AuthGuard],
      },{
        path: 'beneficiarios',
        loadChildren: './appccamg/beneficiarios/beneficiarios.module#BeneficiariosModule',
        canActivate: [AuthGuard]
      },{
        path: 'horarios',
        loadChildren: './appccamg/horarios/horarios.module#HorariosModule',
        canActivate: [AuthGuard],
        data: { roles: ['Director','Administrador'] }
      },{
        path: 'resumenes',
        loadChildren: './appccamg/resumenes/resumenes.module#ResumenesModule',
        canActivate: [AuthGuard],
        data: {
          title: 'Resumenes',
          roles: ['Tesorero'],
          urls: [{ title: 'Home', url: '/dashboard' }, { title: 'Resumen por periodos' }]
        },
      },{
        path: 'asistencias',
        loadChildren: './appccamg/asistencias/asistencias.module#AsistenciasModule',
        canActivate: [AuthGuard],
        data: {
          title: 'Asistencias',
          roles: ['Tesorero','Director'],
          urls: [{ title: 'Home', url: '/dashboard' }, { title: 'Asistencias a actividades' }]
        },
      },{
        path: 'cobros',
        loadChildren: './appcobros/cobros/cobros.module#CobrosModule',
        canActivate: [AuthGuard],
        data: {
          roles: ['Tesorero']
        },
      },{
        path: 'tesorero-sectorial',
        loadChildren: './appcobros/aportes/aportes.module#AporteTesorerosModule',
        canActivate: [AuthGuard],
        data: {
          roles: ['Tesorero Sectorial']
        },
      }
    ]
  },
  
];
