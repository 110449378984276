import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Cobrosmultas } from '../../cobrosmulta/cobrosmultas.model';
import { CobrosmultasServices } from '../../cobrosmulta/cobrosmultas.services';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Actividades } from '../../../actividades/actividade/actividades.model';
import { ActividadesServices } from '../../../actividades/actividade/actividades.services';

@Component({
	selector: 'app-addcobrosmulta',
	templateUrl: './addcobrosmulta.component.html',
	styleUrls: ['./addcobrosmulta.component.scss']
})

export class AddCobrosmultaComponent implements OnInit {
	public dataSocios:any;
	public dataAdministrativos:any;
	public dataActividades:any;
	public formAddCobrosmulta: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioCobrosmulta:CobrosmultasServices,
		private servicioSocio:SociosServices,
		private servicioAdministrativo:AdministrativosServices,
		private servicioActividade:ActividadesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.dataSocios=data.socios;
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.dataAdministrativos=data.administrativos;
		});
		this.servicioActividade.getDataActividades().subscribe((data:any)=>{
			this.dataActividades=data.actividades;
		});
		this.formAddCobrosmulta = this.fb.group({
			socio_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			administrativo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			actividade_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let cobrosmultaDataModel:Cobrosmultas=this.formAddCobrosmulta.value;
		this.servicioCobrosmulta.saveCobrosmultas(cobrosmultaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}