import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Gastosperiodos} from './gastosperiodos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class GastosperiodosServices {

	constructor(private http: HttpClient) { }

	getDataGastosperiodos():Observable<Gastosperiodos[]>{
		return this.http.get<Gastosperiodos[]>(Globals.BASE_URL_API_REST+'gastosperiodos.json');
	}

	saveGastosperiodos(data:Gastosperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'gastosperiodos.json',data);
	}

	deleteGastosperiodos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'gastosperiodos/'+id+'.json');
	}

	updateGastosperiodos(data:Gastosperiodos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'gastosperiodos/'+data.id+'.json',data);
	}

	viewGastosperiodos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'gastosperiodos/'+id+'.json');
	}

	viewGastosPeriodosAsignados(data:Subperiodos){
		return this.http.put(Globals.BASE_URL_API_REST+'gastosasignadas.json',data);
	}

	viewGastosPeriodosAsignadosConValores(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'gastosasignadas.json',data);
	}
	
	viewGastosPeriodosAsignadosConValoresTemporales(data:Subperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'gastosasignadas.json',data);
   	}
}