import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Pagoprestamos } from '../../pagoprestamo/pagoprestamos.model';
import { PagoprestamosServices } from '../../pagoprestamo/pagoprestamos.services';
import { Prestamos } from '../../../prestamos/prestamo/prestamos.model';
import { PrestamosServices } from '../../../prestamos/prestamo/prestamos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-addpagoprestamo',
	templateUrl: './addpagoprestamo.component.html',
	styleUrls: ['./addpagoprestamo.component.scss']
})

export class AddPagoprestamoComponent implements OnInit {
	public dataPrestamos:any;
	public dataAdministrativos:any;
	public formAddPagoprestamo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPagoprestamo:PagoprestamosServices,
		private servicioPrestamo:PrestamosServices,
		private servicioAdministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPrestamo.getDataPrestamos().subscribe((data:any)=>{
			this.dataPrestamos=data.prestamos;
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.dataAdministrativos=data.administrativos;
		});
		this.formAddPagoprestamo = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			monto:[null,Validators.compose([Validators.required,CustomValidators.number])],
			prestamo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			administrativo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let pagoprestamoDataModel:Pagoprestamos=this.formAddPagoprestamo.value;
		this.servicioPagoprestamo.savePagoprestamos(pagoprestamoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	
}