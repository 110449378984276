import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs/Observable';

@Injectable()
export class NotificationServices {
    
    constructor() { }

    setNotification(data:any){
        let promise = new Promise((resolve, reject) => {
            var est=null;
            for (var tipo in data.msg){
                est=data.msg[tipo];
                data.tipomsg='warning';
            }
            if(data.msg.ok){est=data.msg.ok; data.tipomsg='success';}
            if(data.msg.error){est=data.msg.error; data.tipomsg='error';}
           
            Swal({
                title: data.titulo,
                html:  est,
                timer: 5000,
                type:data.tipomsg,
                showConfirmButton: true,
                showLoaderOnConfirm: true,
            });
            resolve(true);
        });
        
        return promise;
       
    }

    msgEliminarConfir(data:any){
      
        let promise = new Promise((resolve, reject) => {
            Swal({   
                title:'¿Estás seguro?',   
                html: data.msg+' <code>'+data.val+'</code>',   
                type: 'warning',   
                showCancelButton: true,   
                confirmButtonColor: '#DD6B55',   
                confirmButtonText: 'Sí, estoy seguro!',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true
            }).then((result)=>{
                if (result.value) {
                    resolve(result.value);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    resolve(false);
                  }
               
            });
        });
        return promise;

    }

    msgConfir(data:any){
      
        let promise = new Promise((resolve, reject) => {
            Swal({   
                title:'¿Estás seguro?',   
                html: data.msg+' <code>'+data.val+'</code>',   
                type: 'warning',   
                showCancelButton: true,   
                confirmButtonColor: '#1875d2',   
                confirmButtonText: 'Sí, estoy seguro!',
                cancelButtonText: 'Cancelar',
                showLoaderOnConfirm: true
            }).then((result)=>{
                if (result.value) {
                    resolve(result.value);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    resolve(false);
                  }
               
            });
        });
        return promise;

    }

    setNotificationValor(data:any){
        let promise = new Promise((resolve, reject) => {
            var est=null;
            for (var tipo in data.msg){
                est=data.msg[tipo];
                data.tipomsg='warning';
            }
            if(data.msg.ok){est=data.msg.ok; data.tipomsg='success';}
            if(data.msg.error){est=data.msg.error; data.tipomsg='error';}
           
            Swal({
                title:'¿Estás seguro?',   
                html: data.msg+' <code>'+data.val+'</code>', 
                input: 'number',
                type:data.tipomsg,
                inputAttributes: {
                  min: '0',
                  step:'0.01'
                },
                showCancelButton: true,   
                showLoaderOnConfirm: true,
                confirmButtonText: 'Sí, estoy seguro!',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.value) {
                    resolve(result.value);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    resolve(false);
                  }
              })
        });
        
        return promise;
       
    }

    setNotificationMotivo(data:any){
        let promise = new Promise((resolve, reject) => {
            var est=null;
            for (var tipo in data.msg){
                est=data.msg[tipo];
                data.tipomsg='warning';
            }
            if(data.msg.ok){est=data.msg.ok; data.tipomsg='success';}
            if(data.msg.error){est=data.msg.error; data.tipomsg='error';}
           
            Swal({
                title:'¿Estás seguro?',   
                html: data.msg+' <code>'+data.val+'</code>', 
                input: 'textarea',
                type:data.tipomsg,
                inputAttributes: {
                  autocapitalize: 'off'
                },
                showCancelButton: true,   
                showLoaderOnConfirm: true,
                confirmButtonText: 'Sí, estoy seguro!',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !Swal.isLoading()
              }).then((result) => {
                if (result.value) {
                    resolve(result.value);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    resolve(false);
                  }
              })
        });
        
        return promise;
       
    }
}