import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditActividadeComponent } from './edit/editactividade.component';
import { AddActividadeComponent } from './add/addactividade.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AddSancionEventoComponent } from './sancion/sancion.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddGrupoEventoComponent } from './grupo/grupo.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddSectorEventoComponent } from './sector/sector.component';
@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxUiLoaderModule,
		NgbModule,
		NgSelectModule
	],
	declarations:[EditActividadeComponent,AddActividadeComponent,AddSancionEventoComponent,AddGrupoEventoComponent,AddSectorEventoComponent],
	entryComponents:[EditActividadeComponent,AddActividadeComponent,AddSancionEventoComponent,AddGrupoEventoComponent,AddSectorEventoComponent],
})
export class ModalActividadeModule { }
