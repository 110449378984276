import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-calcular-sectores',
  templateUrl: './calcular-sectores.component.html',
  styleUrls: ['./calcular-sectores.component.scss']
})
export class CalcularSectoresComponent implements OnInit {

  @Input() registro: any;
  @Input() datamin: number;
  @Input() datapre: number;
  @Output() valueChange = new EventEmitter();
  datos = { 'registro': [], 'tiempo': 0, 'hora': 0, 'min': 0, 'minutoc': 0, 'monto': 0 } as any;
  vermas: boolean = false;
  constructor() { }

  ngOnInit() {
    let tiempog: number = 0;
    for (let index = 0; index < this.registro.length; index++) {
      let minutos = [];
      let tiempop: number = 0;
      for (let indexh = 0; indexh < this.registro[index].horarios.length; indexh++) {
        let tipo;
        if (this.registro[index].horarios[indexh].tipo == 1) {
          tipo = 'Arriba';
        }
        if (this.registro[index].horarios[indexh].tipo == 2) {
          tipo = 'Abajo';
        }
        let dataM = { 'minutos': this.registro[index].horarios[indexh].minutos, 'tipo': tipo } as any;
        minutos.push(dataM);
      }
      let existe: boolean = false;
      let errorIgual: boolean = false;

      if (minutos.length > 0) {
        existe = true;
        for (let index = 0; index < minutos.length - 1; index++) {
          const element = minutos[index];
          if (element.minutos != minutos[index + 1].minutos) {
            errorIgual = true;
            break;
          }
        }
        if (errorIgual == false) {
          let catidad: number = 0;
          let suma: number = 0;
          for (let index = 0; index < minutos.length; index++) {
            const element = minutos[index];
            suma += minutos[index].minutos;
            catidad++;
          }
          suma = suma / catidad;
          tiempop = suma;
        }
      }
      tiempog += tiempop;
      let resulh: number = (tiempop / 60);
      resulh = Math.floor(resulh);
      let resulm = (tiempop - (resulh * 60));

      let minutoc = Math.round(tiempop / this.datamin);
      if (minutoc == 0 && tiempop > 0) {
          minutoc = 1;
      }
      let monto = this.round(minutoc * this.datapre);
      if(this.registro[index].estado==1){
        let data = { 'id': this.registro[index].sectore.id,'sector': this.registro[index].sectore.nombre, 'tiene': existe, 'error': errorIgual, 'horarios': minutos, 'hora': resulh, 'min': resulm, 'tiempo':tiempop ,'monto':monto} as any;
        this.datos.registro.push(data);
      }
      
    }
    
    let resulh: number = (tiempog / 60);
    resulh = Math.floor(resulh);
    let resulm = (tiempog - (resulh * 60));

    this.datos.tiempo = tiempog;
    this.datos.hora = resulh;
    this.datos.min = resulm;

    this.datos.minutoc = Math.round(this.datos.tiempo / this.datamin);
    if (this.datos.minutoc == 0 && this.datos.tiempo > 0) {
      this.datos.minutoc = 1;
    }
    this.datos.monto = this.round(this.datos.minutoc * this.datapre);
    this.valueChange.emit(this.datos.registro);


  }

  ver(val: boolean) {
    this.vermas = val;
  }

  round(num: any, decimales = 2) {
    let signo: any = (num >= 0 ? 1 : -1);
    num = num * signo;
    if (decimales === 0) //con 0 decimales
      return signo * Math.round(num);
    // round(x * 10 ^ decimales)
    num = num.toString().split('e');
    num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    num = num.toString().split('e');
    let val: any = num[0] + 'e';
    return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
  }

}
