import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Subperiodos} from './subperiodos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class SubperiodosServices {

	constructor(private http: HttpClient) { }

	getDataSubperiodos():Observable<Subperiodos[]>{
		return this.http.get<Subperiodos[]>(Globals.BASE_URL_API_REST+'subperiodos.json');
	}

	saveSubperiodos(data:Subperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'subperiodos.json',data);
	}

	deleteSubperiodos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'subperiodos/'+id+'.json');
	}

	updateSubperiodos(data:Subperiodos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'subperiodos/'+data.id+'.json',data);
	}

	viewSubperiodos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'subperiodos/'+id+'.json');
	}

	viewSubperiodosPorPeriodo(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'periodos_subperiodo_lista/'+id+'.json');
    }
	
}