import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Pagogastos } from '../../pagogasto/pagogastos.model';
import { PagogastosServices } from '../../pagogasto/pagogastos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-edit-motivo-pagos-gastos',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditPagoGastosMotivoComponent implements OnInit {

	@Input() Pagogasto:Pagogastos;
	public formPagoGastoMotivo:FormGroup;
	userdata:any;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private servicesPagogastos:PagogastosServices,
		private Noti:NotificationServices,) { }

	ngOnInit(){
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.formPagoGastoMotivo=this.fb.group({
            motivo:[this.Pagogasto.motivo,Validators.compose([Validators.required,Validators.minLength(15), Validators.maxLength(50)])],
            estado:[2,Validators.compose([Validators.required])],
		});
	}

	savePagoGastoMotivo(){
		let PagogastosDataModel:Pagogastos=this.formPagoGastoMotivo.value;
		PagogastosDataModel.id=this.Pagogasto.id;
        this.Pagogasto.motivo=PagogastosDataModel.motivo;
		this.Pagogasto.estado=PagogastosDataModel.estado;
		
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				this.Pagogasto.administrativo_id=datadmin.administrativo.id;
				PagogastosDataModel.administrativo_id=datadmin.administrativo.id;
				this.servicesPagogastos.updatePagogastos(PagogastosDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.activeModal.close(1);
							}
						});
					}else{
						this.Noti.setNotification(data);
					}
				});
			});
		}
	}

}