import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cuotas } from '../../cuota/cuotas.model';
import { CuotasServices } from '../../cuota/cuotas.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { EditPagoMotivoCuotaComponent } from '../change/changemotivo.component';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { ReportesServices } from '../../../reporte/reportes.services';
import { ImprimirMultasPagosComponent } from '../../../cobrosmultas/modal/imprimir/imprimir.component';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';
import * as moment from 'moment';

@Component({
	selector: 'app-edit-cuotas-pagar',
	templateUrl: './listcuotaspagadas.component.html',
	styleUrls: ['./listcuotaspagadas.component.scss']
})

export class ListaCuotaPagarPagoComponent implements OnInit {

	@Input() Pago:any;
    public imgUrlSocios:string;
    inconoLoder:string=SPINNER.wanderingCubes;
    userdata:any;
    temp = [];
    rows = [];
    dataPago:Pagos;
    totalAbono:number=0;
    totalPendiente:number=0;
    ip: string = '127.0.0.1';
    dataPagoG:any;

    public formAddCuota: FormGroup;
    dataAdministrativo ={'id':0} as Administrativos;

    configMotivo: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
    }
    config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}
    constructor(private fb: FormBuilder,
        public dialog: NgbModal,
		private activeModal:NgbActiveModal,
        private servicioCuota:CuotasServices,
        public localdata:localStorageServices,
        private ngxService: NgxUiLoaderService,
        private servicioadministrativo:AdministrativosServices,
        private validatciones:customValidatorMondogo,
        private servicesReportes:ReportesServices,
		private servicioPago:PagosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
        this.userdata=this.localdata.getValueFromLocalStorage();
        this.imgUrlSocios=Globals.BASE_URL_API_REST;
        this.servicioPago.viewPagos(this.Pago.id).subscribe((dataPa:any)=>{
            this.dataPagoG=dataPa.pago;
        });
        this.listaCuotas();

        if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
                this.dataAdministrativo=data.administrativo;
                
                if(data.administrativo.impresoras[0]!=undefined){
                    this.ip=data.administrativo.impresoras[0].ip;
                }
            });
        }

        let pipe = new DatePipe('en-US');

        this.formAddCuota = this.fb.group({
            abono:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
            administrativo_id:[this.dataAdministrativo.id,Validators.compose([Validators.required,CustomValidators.number])],
            pago_id:[this.Pago.id,Validators.compose([Validators.required,CustomValidators.number])],
            estado:[1,null],
        });

        console.log(this.Pago);
        

        
	}
	
    listaCuotas(){
        let promise = new Promise((resolve, reject) => {
            this.ngxService.startLoader('loaderAddCuotasPagar');
            this.servicioPago.viewPagos(this.Pago.id).subscribe((data:any)=>{
                this.totalAbono=0;
                this.totalAbono=0;
                this.dataPago=data.pago;
                this.rows=data.pago.cuotas;
                this.temp = [...this.rows];
                this.rows.forEach((obj:Cuotas,index)=>{
                    if(obj.estado===1 || obj.estado===2)
                        this.totalAbono+=(obj.abono);
                });
                this.totalAbono=this.round(this.totalAbono)
                this.totalPendiente=this.round(this.dataPago.montototal-this.totalAbono);
                resolve(true);
                this.ngxService.stopLoader('loaderAddCuotasPagar');
            });
        });
        return promise;
    }
    

    editarMotivoCuota(data:any){
        let dialogRef = this.dialog.open(EditPagoMotivoCuotaComponent, this.configMotivo);
		dialogRef.componentInstance.Cuota = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
                    this.formAddCuota.reset();
                   
                    this.formAddCuota = this.fb.group({
                        abono:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
                        administrativo_id:[this.dataAdministrativo.id,Validators.compose([Validators.required,CustomValidators.number])],
                        pago_id:[this.Pago.id,Validators.compose([Validators.required,CustomValidators.number])],
                        estado:[1,null],
                    });
					this.listaCuotas();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
    }

    save(){
        this.listaCuotas().then(
            result=>{
                if(result)
                    {
                        let pipe:any = new DatePipe('es-EC');
                        let cuotasDataModel:Cuotas=this.formAddCuota.value;
                        cuotasDataModel.fecha=pipe.transform(Date.now(), 'yyyy-MM-dd');
                        cuotasDataModel.administrativo_id=this.dataAdministrativo.id;
                        if(cuotasDataModel.abono > 0 && cuotasDataModel.abono <= this.totalPendiente){
                            this.servicioCuota.saveCuotas(cuotasDataModel).subscribe((data:any)=>{
                                if(data.estado){
                                    console.log(data);
                                    this.Noti.setNotification(data).then((datas)=>{
                                        if(datas){
                                            this.listaCuotas().then(result=>{
                                                if(result)
                                                {
                                                    let sendCod = { data: { "empresa": "AGUA DE RIEGO QUINDIALO", 
                                                    "accion": "RECIBO DE PAGO EVENTO", 
                                                    "codigo": "PAGC-"+data.datos.id, 
                                                    "fecha": moment(data.datos.modified).locale('es').format('LLL'), 
                                                    "valor": data.datos.pago.montototal,
                                                    "valorAbono": data.datos.abono,
                                                    "valorAbonoTotal": this.round(this.totalAbono),"aporte": {"subperiodo":data.datos.pago.evento.aportesperiodo.subperiodo.nombre, 
                                                                                        "nombre": data.datos.pago.evento.aportesperiodo.aporte.motivo, 
                                                                                        "detalle": data.datos.pago.evento.detalle, 
                                                                                        "fechaEvento": moment(data.datos.pago.evento.fecha).add(1, 'd').locale('es').format('LL'), 
                                                                                        "formaDePago": data.datos.pago.tipo }, 
                                                                            "usuario": { "identificador": this.Pago.socio.cedula, 
                                                                                        "nombre": this.Pago.socio.nombres, 
                                                                                        "apellido": this.Pago.socio.apellidos, 
                                                                                        "direccion": this.Pago.socio.direccion, 
                                                                                        "correo": this.Pago.socio.email, 
                                                                                        "telefono": this.Pago.socio.telefono, 
                                                                                        "celular": this.Pago.socio.celular },
                                                                            "sector":{"nombre":this.Pago.sectore.nombre},
                                                                            "tesorero": { "identificador": data.datos.administrativo.socio.cedula, 
                                                                                        "nombre": data.datos.administrativo.socio.nombres, 
                                                                                        "apellido": data.datos.administrativo.socio.apellidos } }, ip: this.ip } as any;
                                                    this.servicioPago.printerAporteCuota(sendCod).subscribe();
                                                }
                                            });
                                        }
                                    });
                                }else{
                                    this.Noti.setNotification(data);
                                }
                            });
                        }else{
                            let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto debe ser mayor que <code>0</code> y menor o igual que <code>'+this.totalPendiente+'</code>'}};
                            this.Noti.setNotification(smsFail);
                        }
                    }
                },
                reason=>{
                    console.log(reason);
                }
            );
    }
    
    pagarcuota(dataCuota:any){
        let dataPagos= {} as Pagos;
		let dataconfirmar={'val':dataCuota.abono,'msg':'Pagar abono cuota'};
        let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'Inicie sesión como <code>Tesorero</code>'}};
        
        this.servicioCuota.viewCuotas(dataCuota.id).subscribe((dataPago:any)=>{
            if(dataPago.cuota.estado==2){
                if(this.userdata.datos.socio == undefined){
                    this.Noti.setNotification(smsFail);
                }else{
                    this.Noti.msgEliminarConfir(dataconfirmar).then((datas)=>{
                        if(datas){
        
                            if(this.userdata.datos.socio){
                                this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
                                    
                                    dataPagos.administrativo_id=datadmin.administrativo.id;
                                    dataPagos.estado=1;
                                    dataPagos.id=dataCuota.id;
        
                                    this.servicioCuota.updateCuotas(dataPagos).subscribe((datasave:any)=>{
                                        if(datasave.estado){
                                            this.Noti.setNotification(datasave).then((datas)=>{
                                                if(datas){
                                                    this.listaCuotas();
                                                }
                                            });
                                        }else{
                                            this.Noti.setNotification(datasave);
                                        }
                                    });
                    
                                    
                    
                                });
                            }
                        
                        }
                    });
                }
            }else{
                let estadoPago="";
                if(dataPago.cuota.estado===1){
                    estadoPago="Pagado";
                }else if(dataPago.cuota.estado===0){
                    estadoPago="Anulado";
                }
                let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El pago ya esta <code>'+estadoPago+'</code>'}};
                this.Noti.setNotification(smsFail);
            }
        });
        
    }

    imprimir(data:any){
       console.log(data);
       
        this.ngxService.startLoader('loaderAddCuotasPagar');
        this.servicesReportes.generateReciboCuotasPdf(data).subscribe((datapdf:any)=>{
            if(datapdf.estado){
                    this.Noti.setNotification(datapdf).then((datas)=>{
                            if(datas){
                                let sendCod = { data: { "empresa": "AGUA DE RIEGO QUINDIALO", 
                                                    "accion": "RECIBO DE PAGO EVENTO", 
                                                    "codigo": "PAGC-"+data.id, 
                                                    "fecha": moment(data.modified).locale('es').format('LLL'), 
                                                    "valor": this.Pago.montototal,
                                                    "valorAbono": data.abono,
                                                    "valorAbonoTotal": this.round(this.totalAbono),"aporte": {"subperiodo":this.dataPagoG.evento.aportesperiodo.subperiodo.nombre, 
                                                                                        "nombre": this.dataPagoG.evento.aportesperiodo.aporte.motivo, 
                                                                                        "detalle": this.dataPagoG.evento.detalle, 
                                                                                        "fechaEvento": moment(this.dataPagoG.evento.fecha).add(1, 'd').locale('es').format('LL'), 
                                                                                        "formaDePago": this.dataPagoG.tipo }, 
                                                                            "usuario": { "identificador": this.Pago.socio.cedula, 
                                                                                        "nombre": this.Pago.socio.nombres, 
                                                                                        "apellido": this.Pago.socio.apellidos, 
                                                                                        "direccion": this.Pago.socio.direccion, 
                                                                                        "correo": this.Pago.socio.email, 
                                                                                        "telefono": this.Pago.socio.telefono, 
                                                                                        "celular": this.Pago.socio.celular },
                                                                            "sector":{"nombre":this.Pago.sectore.nombre},
                                                                            "tesorero": { "identificador": data.administrativo.socio.cedula, 
                                                                                        "nombre": data.administrativo.socio.nombres, 
                                                                                        "apellido": data.administrativo.socio.apellidos } }, ip: this.ip } as any;
                                this.servicioPago.printerAporteCuota(sendCod).subscribe();

                                let dialogRef = this.dialog.open(ImprimirMultasPagosComponent, this.config);
                                dialogRef.componentInstance.dataPdf = datapdf.datos.Cuotas.ruta;
                                dialogRef.result.then(
                                    result=>{
                                        
                                    },
                                    reason=>{
                                        console.log(reason);
                                    }
                                );
                                this.ngxService.stopLoader('loaderAddCuotasPagar');
                            }
                    });
            }else{
                    this.Noti.setNotification(datapdf);
            }
        });
    }

    round(num:any, decimales = 2) {
        let signo:any = (num >= 0 ? 1 : -1);
        num = num * signo;
        if (decimales === 0) //con 0 decimales
            return signo * Math.round(num);
        // round(x * 10 ^ decimales)
        num = num.toString().split('e');
        num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
        // x * 10 ^ (-decimales)
        num = num.toString().split('e');
        let val:any=num[0] + 'e';
        return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
      }

    cerrarModal(){
        this.activeModal.close(1);
    }
	

}

