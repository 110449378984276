import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Pagogastos } from '../../pagogasto/pagogastos.model';
import { PagogastosServices } from '../../pagogasto/pagogastos.services';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { MultasperiodosServices } from '../../../multasperiodos/multasperiodo/multasperiodos.services';

@Component({
	selector: 'app-addpagogasto',
	templateUrl: './addpagogasto.component.html',
	styleUrls: ['./addpagogasto.component.scss']
})

export class AddPagogastoComponent implements OnInit {
	public dataMultasperiodos:any;
	public formAddPagogasto: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPagogasto:PagogastosServices,
		private servicioMultasperiodo:MultasperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMultasperiodo.getDataMultasperiodos().subscribe((data:any)=>{
			this.dataMultasperiodos=data.multasperiodos;
		});
		this.formAddPagogasto = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			multasperiodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let pagogastoDataModel:Pagogastos=this.formAddPagogasto.value;
		this.servicioPagogasto.savePagogastos(pagogastoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}