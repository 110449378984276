import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Tesorerosectores} from './tesorerosectores.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class TesorerosectoresServices {

	constructor(private http: HttpClient) { }

	getDataTesorerosectores():Observable<Tesorerosectores[]>{
		return this.http.get<Tesorerosectores[]>(Globals.BASE_URL_API_REST+'tesorerosectores.json');
	}

	saveTesorerosectores(data:Tesorerosectores){
		return this.http.post(Globals.BASE_URL_API_REST+'tesorerosectores.json',data);
	}

	deleteTesorerosectores(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'tesorerosectores/'+id+'.json');
	}

	updateTesorerosectores(data:Tesorerosectores){
		 return this.http.patch(Globals.BASE_URL_API_REST+'tesorerosectores/'+data.id+'.json',data);
	}

	viewTesorerosectores(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'tesorerosectores/'+id+'.json');
	}
}