import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditRegistroComponent } from './edit/editregistro.component';
import { AddRegistroComponent } from './add/addregistro.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		NgbModule
	],
	declarations:[EditRegistroComponent,AddRegistroComponent],
	entryComponents:[EditRegistroComponent,AddRegistroComponent],
})
export class ModalRegistroModule { }
