import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Gastosperiodos } from '../../gastosperiodo/gastosperiodos.model';
import { GastosperiodosServices } from '../../gastosperiodo/gastosperiodos.services';
import { Gastos } from '../../../gastos/gasto/gastos.model';
import { GastosServices } from '../../../gastos/gasto/gastos.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-addgastosperiodo',
	templateUrl: './addgastosperiodo.component.html',
	styleUrls: ['./addgastosperiodo.component.scss']
})

export class AddGastosperiodoComponent implements OnInit {
	public dataGastos:any;
	public dataSubperiodos:any;
	public formAddGastosperiodo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioGastosperiodo:GastosperiodosServices,
		private servicioGasto:GastosServices,
		private servicioSubperiodo:SubperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioGasto.getDataGastos(1).subscribe((data:any)=>{
			this.dataGastos=data.gastos;
		});
		this.servicioSubperiodo.getDataSubperiodos().subscribe((data:any)=>{
			this.dataSubperiodos=data.subperiodos;
		});
		this.formAddGastosperiodo = this.fb.group({
			gasto_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			subperiodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let gastosperiodoDataModel:Gastosperiodos=this.formAddGastosperiodo.value;
		this.servicioGastosperiodo.saveGastosperiodos(gastosperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}