import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Periodos} from './periodos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class PeriodosServices {

	constructor(private http: HttpClient) { }

	getDataPeriodos():Observable<Periodos[]>{
		return this.http.get<Periodos[]>(Globals.BASE_URL_API_REST+'periodos.json');
	}

	savePeriodos(data:Periodos){
		return this.http.post(Globals.BASE_URL_API_REST+'periodos.json',data);
	}

	deletePeriodos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'periodos/'+id+'.json');
	}

	updatePeriodos(data:Periodos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'periodos/'+data.id+'.json',data);
	}

	viewPeriodos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'periodos/'+id+'.json');
	}

	viewPeriodoActivo(){
		return this.http.post(Globals.BASE_URL_API_REST+'periodosvalidaciones.json',{});
	}
	viewSubPeriodoActivo(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'periodosvalidaciones.json',data);
   	}   


	
}