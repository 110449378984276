import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NotFoundComponent } from './404/not-found.component';
import { LockComponent } from './lock/lock.component';
import { LoginComponent } from './login/login.component';

import { AuthenticationRoutes } from './authentication.routing';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomePageInfoComponent } from './home-page-info/home-page-info.component';
import { HomePageHeaderComponent } from './home-page-header/home-page-header.component';
import { HomePageFooterComponent } from './home-page-footer/home-page-footer.component';
import { HomePagePrincipalComponent } from './home-page-principal/home-page-principal.component';
import { HomePageHorariosComponent } from './home-page-horarios/home-page-horarios.component';
import { HomePageConsultasComponent } from './home-page-consultas/home-page-consultas.component';
import { ConsultasAportesMutlasServices } from './servicios/consultas.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HomePageTotalCobroComponent } from './home-page-total-cobro/home-page-total-cobro.component';
import { HomePageTotalMultaComponent } from './home-page-total-multa/home-page-total-multa.component';
import { HomePageTotalPagoComponent } from './home-page-total-pago/home-page-total-pago.component';


@NgModule({
  imports: [CommonModule,
    RouterModule.forChild(AuthenticationRoutes),
    NgbModule,
    FormsModule,
    NgxDatatableModule,
    ReactiveFormsModule],
  exports: [RouterModule],
  providers:[ConsultasAportesMutlasServices],
  declarations: [NotFoundComponent, 
    LoginComponent, 
    LockComponent, 
    HomePageInfoComponent, 
    HomePageHeaderComponent, 
    HomePageFooterComponent, 
    HomePagePrincipalComponent, 
    HomePageHorariosComponent, 
    HomePageConsultasComponent,
    HomePageTotalCobroComponent,
    HomePageTotalMultaComponent,
    HomePageTotalPagoComponent]
})
export class AuthenticationModule { }
