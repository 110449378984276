import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cuotas } from '../../cuota/cuotas.model';
import { CuotasServices } from '../../cuota/cuotas.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { EditPagoMotivoCuotaComponent } from '../change/changemotivo.component';

@Component({
	selector: 'app-edit-cuotas-pagadas',
	templateUrl: './listcuotaspagadas.component.html',
	styleUrls: ['./listcuotaspagadas.component.scss']
})

export class ListaCuotaPagadasComponent implements OnInit {

	@Input() Pago:any;
	public imgUrlSocios:string;
    userdata:any;
    temp = [];
    rows = [];
    dataPago:Pagos;
    totalAbono:number=0;
    totalPendiente:number=0;

    configMotivo: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
    }
    
    constructor(private fb: FormBuilder,
        public dialog: NgbModal,
		private activeModal:NgbActiveModal,
        private servicioCuota:CuotasServices,
        public localdata:localStorageServices,
		private servicioAdministrativo:AdministrativosServices,
		private servicioPago:PagosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
        this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
        this.listaCuotas();
        
	}
	
    listaCuotas(){
		this.servicioPago.viewPagos(this.Pago.id).subscribe((data:any)=>{
            this.totalAbono=0;
            this.totalAbono=0;
            this.dataPago=data.pago;
            this.rows=data.pago.cuotas;
            this.temp = [...this.rows];
            this.rows.forEach((obj:Cuotas,index)=>{
                if(obj.estado===1)
                    this.totalAbono+=(obj.abono);
            });
            this.totalPendiente=this.dataPago.montototal-this.totalAbono;
        });
        
        
	}

    editarMotivoCuota(data:any){
        let dialogRef = this.dialog.open(EditPagoMotivoCuotaComponent, this.configMotivo);
		dialogRef.componentInstance.Cuota = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaCuotas();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
    }

    cerrarModal(){
        this.activeModal.close(1);
    }
	

}

