import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Actividades} from './actividades.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class ActividadesServices {
	private formData: Actividades = new Actividades();
	constructor(private http: HttpClient) { }

	getDataActividades():Observable<Actividades[]>{
		return this.http.get<Actividades[]>(Globals.BASE_URL_API_REST+'actividades.json');
	}

	saveActividades(data:Actividades){
		return this.http.post(Globals.BASE_URL_API_REST+'actividades.json',data);
	}

	deleteActividades(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'actividades/'+id+'.json');
	}

	updateActividades(data:Actividades){
		 return this.http.patch(Globals.BASE_URL_API_REST+'actividades/'+data.id+'.json',data);
	}

	viewActividades(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'actividades/'+id+'.json');
	}

	viewActividadesMulta(data:Actividades){
		return this.http.patch(Globals.BASE_URL_API_REST+'listamultasactividad.json',data);
	}
	
	setDataActividad(data:Actividades){
		this.formData=data;
	}

	getDataActividad(){
		var proveedor: Actividades = this.formData;
		let promise = new Promise((resolve, reject) => {
            resolve(proveedor);
		});
        return promise;
	}
}