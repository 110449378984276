import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Multasperiodos } from '../../multasperiodo/multasperiodos.model';
import { MultasperiodosServices } from '../../multasperiodo/multasperiodos.services';
import { Multas } from '../../../multas/multa/multas.model';
import { MultasServices } from '../../../multas/multa/multas.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-addmultasperiodo',
	templateUrl: './addmultasperiodo.component.html',
	styleUrls: ['./addmultasperiodo.component.scss']
})

export class AddMultasperiodoComponent implements OnInit {
	public dataMultas:any;
	public dataSubperiodos:any;
	public formAddMultasperiodo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioMultasperiodo:MultasperiodosServices,
		private servicioMulta:MultasServices,
		private servicioSubperiodo:SubperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMulta.getDataMultas(1).subscribe((data:any)=>{
			this.dataMultas=data.multas;
		});
		this.servicioSubperiodo.getDataSubperiodos().subscribe((data:any)=>{
			this.dataSubperiodos=data.subperiodos;
		});
		this.formAddMultasperiodo = this.fb.group({
			multa_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			subperiodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let multasperiodoDataModel:Multasperiodos=this.formAddMultasperiodo.value;
		this.servicioMultasperiodo.saveMultasperiodos(multasperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}