import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Pagos} from './pagos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class PagosServices {

	constructor(private http: HttpClient) { }

	getDataPagos():Observable<Pagos[]>{
		return this.http.get<Pagos[]>(Globals.BASE_URL_API_REST+'pagos.json');
	}

	savePagos(data:Pagos){
		return this.http.post(Globals.BASE_URL_API_REST+'pagos.json',data);
	}

	deletePagos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'pagos/'+id+'.json');
	}

	updatePagos(data:Pagos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'pagos/'+data.id+'.json',data);
	}

	viewPagos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'pagos/'+id+'.json');
	}

	printerAporte(data:any){
        let headers = new HttpHeaders().set('Authorization','LocalPrinter ');
        return this.http.post('https://'+data.ip+':6633/pagos',data.data,{ headers: headers });
	}

	printerAporteCuota(data:any){
        let headers = new HttpHeaders().set('Authorization','LocalPrinter ');
        return this.http.post('https://'+data.ip+':6633/cuotas',data.data,{ headers: headers });
	}
}