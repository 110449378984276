import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Eventos} from './eventos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class EventosServices {

	constructor(private http: HttpClient) { }

	getDataEventos():Observable<Eventos[]>{
		return this.http.get<Eventos[]>(Globals.BASE_URL_API_REST+'eventos.json');
	}

	saveEventos(data:Eventos){
		return this.http.post(Globals.BASE_URL_API_REST+'eventos.json',data);
	}

	deleteEventos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'eventos/'+id+'.json');
	}

	updateEventos(data:Eventos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'eventos/'+data.id+'.json',data);
	}

	viewEventos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'eventos/'+id+'.json');
	}

	viewEventoAporte(data:Eventos){
		return this.http.patch(Globals.BASE_URL_API_REST+'listaaporteseventos.json',data);
   	}
}