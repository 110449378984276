import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SeleccionarResumenComponent } from './seleccionar/seleccionar.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgbModule,
		NgxUiLoaderModule,
	],
	declarations:[SeleccionarResumenComponent],
	entryComponents:[SeleccionarResumenComponent],
})
export class ModalResumenModule { }
