import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Prestamos} from './prestamos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class PrestamosServices {

	constructor(private http: HttpClient) { }

	getDataPrestamos():Observable<Prestamos[]>{
		return this.http.get<Prestamos[]>(Globals.BASE_URL_API_REST+'prestamos.json');
	}

	savePrestamos(data:Prestamos){
		return this.http.post(Globals.BASE_URL_API_REST+'prestamos.json',data);
	}

	deletePrestamos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'prestamos/'+id+'.json');
	}

	updatePrestamos(data:Prestamos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'prestamos/'+data.id+'.json',data);
	}

	viewPrestamos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'prestamos/'+id+'.json');
	}

	viewPrestamosSubperio():Observable<any[]>{
		return this.http.put<any[]>(Globals.BASE_URL_API_REST+'solicitud.json',[]);
	}

	viewPrestamosDayHoy():Observable<any[]>{
		return this.http.get<any[]>(Globals.BASE_URL_API_REST+'prestamosdata.json');
	}

	getPrestamoeSocio(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'prestamosocios.json',data);
	}
	
}