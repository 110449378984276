import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Multasperiodos } from '../../multasperiodo/multasperiodos.model';
import { MultasperiodosServices } from '../../multasperiodo/multasperiodos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Multas } from '../../../multas/multa/multas.model';
import { MultasServices } from '../../../multas/multa/multas.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-editmultasperiodo',
	templateUrl: './editmultasperiodo.component.html',
	styleUrls: ['./editmultasperiodo.component.scss']
})

export class EditMultasperiodoComponent implements OnInit {

	@Input() Multasperiodo:Multasperiodos;
	public dataMultas={'motivo':'vacio'} as Multas;
	public multasData:any;
	public dataSubperiodos={'nombre':'vacio'} as Subperiodos;
	public subperiodosData:any;

	public formMultasperiodoMontomulta:FormGroup;
	public editMontomulta:boolean=false;

	public formMultasperiodoMulta:FormGroup;
	public editMulta:boolean=false;

	public formMultasperiodoSubperiodo:FormGroup;
	public editSubperiodo:boolean=false;

	public formMultasperiodoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioMultasperiodo:MultasperiodosServices,
		private servicioMulta:MultasServices,
		private servicioSubperiodo:SubperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMulta.getDataMultas(1).subscribe((data:any)=>{
			this.multasData=data.multas;
			this.dataMultas=this.multasData.find(obj => obj.id == this.Multasperiodo.multa_id);
		});
		this.servicioSubperiodo.getDataSubperiodos().subscribe((data:any)=>{
			this.subperiodosData=data.subperiodos;
			this.dataSubperiodos=this.subperiodosData.find(obj => obj.id == this.Multasperiodo.subperiodo_id);
		});


		this.formMultasperiodoMulta=this.fb.group({
			multa_id:[this.Multasperiodo.multa_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formMultasperiodoSubperiodo=this.fb.group({
			subperiodo_id:[this.Multasperiodo.subperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editMultasperiodoMultaEnable(){
		this.editMulta=true;
		this.formMultasperiodoMulta=this.fb.group({
			multa_id:[this.Multasperiodo.multa_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMultasperiodoMultaDisable(){
		this.editMulta=false;
	}

	saveMultasperiodoMulta(){
		let MultasperiodoDataModel:Multasperiodos=this.formMultasperiodoMulta.value;
		MultasperiodoDataModel.id=this.Multasperiodo.id;
		this.Multasperiodo.multa_id=MultasperiodoDataModel.multa_id;
		this.servicioMultasperiodo.updateMultasperiodos(MultasperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMulta=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editMultasperiodoSubperiodoEnable(){
		this.editSubperiodo=true;
		this.formMultasperiodoSubperiodo=this.fb.group({
			subperiodo_id:[this.Multasperiodo.subperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editMultasperiodoSubperiodoDisable(){
		this.editSubperiodo=false;
	}

	saveMultasperiodoSubperiodo(){
		let MultasperiodoDataModel:Multasperiodos=this.formMultasperiodoSubperiodo.value;
		MultasperiodoDataModel.id=this.Multasperiodo.id;
		this.Multasperiodo.subperiodo_id=MultasperiodoDataModel.subperiodo_id;
		this.servicioMultasperiodo.updateMultasperiodos(MultasperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSubperiodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

