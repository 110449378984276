import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Eventos } from '../../evento/eventos.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';


@Component({
	selector: 'app-addevento-recaudacion',
	templateUrl: './generarreaudacion.component.html',
	styleUrls: ['./generarreaudacion.component.scss']
})

export class AddRecaudacionEventoComponent implements OnInit {
	rows = [];
	temp = [];
	public imgUrlSocios: string;
	userdata: any;

	@Input() Evento = { 'tipo': 3 } as Eventos;
	@Input() Aporteperiodo = { 'montoaporte': 0 } as Aportesperiodos;
	inconoLoder: string = SPINNER.wanderingCubes;
	public dataSectores: any;
	public dataSector: Sectores;

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		public localdata: localStorageServices,
		private servicioSocios: SociosServices,
		private servicioPagos: PagosServices,
		private ngxService: NgxUiLoaderService,
		private servicioSectore: SectoresServices,
		private servicioadministrativo: AdministrativosServices,
		private Noti: NotificationServices, ) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios = Globals.BASE_URL_API_REST;
		console.log(this.Aporteperiodo.montoaporte);
		this.ngxService.startLoader('loaderAportesAgregarSosio');
		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			const temp = data.sectores.filter(function (d) {
				return d.id != 0;
			});
			this.dataSectores = temp;
		});
		this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
			this.rows = data.socios;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('loaderAportesAgregarSosio');
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}


	select(data: any) {

		let datasms = { 'val': data.cedula + ' ' + data.nombres + ' ' + data.apellidos, 'msg': 'Asignar aporte a todos los sectores del socio' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				if (this.userdata.datos.socio) {
					this.ngxService.startLoader('loaderAportesAgregarSosio');
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

						data.registros.forEach((obj: any, index) => {
							setTimeout(() => {
								let dataPagos = {} as Pagos;
								dataPagos.administrativo_id = datadmin.administrativo.id;
								dataPagos.evento_id = this.Evento.id;
								dataPagos.socio_id = data.id;
								dataPagos.montototal = this.Aporteperiodo.montoaporte;
								dataPagos.estado = 0;
								dataPagos.sectore_id = obj.sectore_id;

								this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
									if (datasave.estado) {
										this.Noti.setNotification(datasave).then((datas) => {
											if (datas) {
												this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
													this.rows = data.socios;
													this.temp = [...this.rows];
													if (this.dataSector != undefined) {
														let idSector = this.dataSector.id;
														const temp1 = this.temp.filter(function (d) {
															let existe: boolean = false;
															for (let index = 0; index < d.registros.length; index++) {
																if (d.registros[index].sectore_id == idSector) {
																	existe = true;
																}
															}
															return existe == true;
														});
														this.rows = temp1;
													}
													this.ngxService.stopLoader('loaderAportesAgregarSosio');
												});
											}
										});
									} else {
										this.ngxService.stopLoader('loaderAportesAgregarSosio');
										this.Noti.setNotification(datasave);
									}
								});

							}, 1500 * index);
						});
					});
				}
			}
		});
	}

	selectgeneral(data: any) {
		let datasms;
		if (this.dataSector != undefined) {
			datasms = { 'val': this.dataSector.nombre + ', ' + data.cedula + ' ' + data.nombres + ' ' + data.apellidos, 'msg': 'Asignar aporte al sector y socio' };
		}else{
			datasms = { 'val': data.cedula + ' ' + data.nombres + ' ' + data.apellidos, 'msg': 'Asignar aporte al socio' };
		}
		
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				let dataPagos = {} as Pagos;
				if (this.userdata.datos.socio) {
					this.ngxService.startLoader('loaderAportesAgregarSosio');
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

						dataPagos.administrativo_id = datadmin.administrativo.id;
						dataPagos.evento_id = this.Evento.id;
						dataPagos.socio_id = data.id;
						dataPagos.montototal = this.Aporteperiodo.montoaporte;
						dataPagos.estado = 0;
						dataPagos.sectore_id = 0;

						this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
							if (datasave.estado) {
								this.Noti.setNotification(datasave).then((datas) => {
									if (datas) {
										this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
											this.rows = data.socios;
											this.temp = [...this.rows];
											if (this.dataSector != undefined) {
												let idSector = this.dataSector.id;
												const temp1 = this.temp.filter(function (d) {
													let existe: boolean = false;
													for (let index = 0; index < d.registros.length; index++) {
														if (d.registros[index].sectore_id == idSector) {
															existe = true;
														}
													}
													return existe == true;
												});
												this.rows = temp1;
											}
											this.ngxService.stopLoader('loaderAportesAgregarSosio');
										});
									}
								});
							} else {
								this.ngxService.stopLoader('loaderAportesAgregarSosio');
								this.Noti.setNotification(datasave);
							}
						});
					});
				}
			}
		});
	}

	saveall() {
		let datasms = { 'val': this.Evento.detalle, 'msg': 'Asignar aporte a todos los sectores de los socios' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.rows.forEach((obj: any, index) => {
					//console.log(obj);

					setTimeout(() => {

						if (this.userdata.datos.socio) {
							this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

								obj.registros.forEach((obj1: any, index1) => {
									setTimeout(() => {
										//console.log(obj1);
										let dataPagos = {} as Pagos;
										dataPagos.administrativo_id = datadmin.administrativo.id;
										dataPagos.evento_id = this.Evento.id;
										dataPagos.socio_id = obj.id;
										dataPagos.montototal = this.Aporteperiodo.montoaporte;
										dataPagos.estado = 0;
										dataPagos.sectore_id = obj1.sectore_id;
										//console.log(dataPagos);
										this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
										});

									}, 1500 * index1);
								});
							});
						}
						this.rows.splice(0, 1);
					}, 2500 * index);
				});
			}
		});

	}

	saveallGeneral() {
		let datasms = { 'val': this.Evento.detalle, 'msg': 'Asignar aporte a todos los socios' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.rows.forEach((obj: Socios, index) => {
					setTimeout(() => {
						let dataPagos = {} as Pagos;
						if (this.userdata.datos.socio) {
							this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

								dataPagos.administrativo_id = datadmin.administrativo.id;
								dataPagos.evento_id = this.Evento.id;
								dataPagos.socio_id = obj.id;
								dataPagos.montototal = this.Aporteperiodo.montoaporte;
								dataPagos.estado = 0;
								dataPagos.sectore_id = 0;

								this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {

								});

								//console.log(dataPagos);

							});
						}
						this.rows.splice(0, 1);
					}, 1500 * index);
				});
			}
		});
	}

	onChange(data: any) {
		if (data != undefined) {
			this.dataSector = data;
			const temp = this.temp.filter(function (d) {
				let existe: boolean = false;
				for (let index = 0; index < d.registros.length; index++) {
					if (d.registros[index].sectore_id == data.id) {
						existe = true;
					}
				}
				return existe == true;
			});
			this.rows = temp;
		} else {
			this.dataSector = undefined;
			const temp = this.temp.filter(function (d) {
				return d.id != undefined;
			});
			this.rows = temp;
		}


	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}