import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditMultasperiodoComponent } from './edit/editmultasperiodo.component';
import { AddMultasperiodoComponent } from './add/addmultasperiodo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditMultasperiodoComponent,AddMultasperiodoComponent],
	entryComponents:[EditMultasperiodoComponent,AddMultasperiodoComponent],
})
export class ModalMultasperiodoModule { }
