import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Registros } from '../../registro/registros.model';
import { RegistrosServices } from '../../registro/registros.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';

@Component({
	selector: 'app-editregistro',
	templateUrl: './editregistro.component.html',
	styleUrls: ['./editregistro.component.scss']
})

export class EditRegistroComponent implements OnInit {

	@Input() Registro:Registros;
	public dataSocios={'cedula':'vacio'} as Socios;
	public sociosData:any;
	public dataSectores={'nombre':'vacio'} as Sectores;
	public sectoresData:any;

	public formRegistroMotivo:FormGroup;
	public editMotivo:boolean=false;

	public formRegistroEstado:FormGroup;
	public editEstado:boolean=false;

	public formRegistroSocio:FormGroup;
	public editSocio:boolean=false;

	public formRegistroSectore:FormGroup;
	public editSectore:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioRegistro:RegistrosServices,
		private servicioSocio:SociosServices,
		private servicioSectore:SectoresServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.sociosData=data.socios;
			this.dataSocios=this.sociosData.find(obj => obj.id == this.Registro.socio_id);
		});
		this.servicioSectore.getDataSectores().subscribe((data:any)=>{
			this.sectoresData=data.sectores;
			this.dataSectores=this.sectoresData.find(obj => obj.id == this.Registro.sectore_id);
		});

		this.formRegistroMotivo=this.fb.group({
			motivo:[this.Registro.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});

		this.formRegistroSocio=this.fb.group({
			socio_id:[this.Registro.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formRegistroSectore=this.fb.group({
			sectore_id:[this.Registro.sectore_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editRegistroMotivoEnable(){
		this.editMotivo=true;
		this.formRegistroMotivo=this.fb.group({
			motivo:[this.Registro.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editRegistroMotivoDisable(){
		this.editMotivo=false;
	}

	saveRegistroMotivo(){
		let RegistroDataModel:Registros=this.formRegistroMotivo.value;
		RegistroDataModel.id=this.Registro.id;
		this.Registro.motivo=RegistroDataModel.motivo;
		this.servicioRegistro.updateRegistros(RegistroDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMotivo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editRegistroSocioEnable(){
		this.editSocio=true;
		this.formRegistroSocio=this.fb.group({
			socio_id:[this.Registro.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRegistroSocioDisable(){
		this.editSocio=false;
	}

	saveRegistroSocio(){
		let RegistroDataModel:Registros=this.formRegistroSocio.value;
		RegistroDataModel.id=this.Registro.id;
		this.Registro.socio_id=RegistroDataModel.socio_id;
		this.servicioRegistro.updateRegistros(RegistroDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSocio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editRegistroSectoreEnable(){
		this.editSectore=true;
		this.formRegistroSectore=this.fb.group({
			sectore_id:[this.Registro.sectore_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editRegistroSectoreDisable(){
		this.editSectore=false;
	}

	saveRegistroSectore(){
		let RegistroDataModel:Registros=this.formRegistroSectore.value;
		RegistroDataModel.id=this.Registro.id;
		this.Registro.sectore_id=RegistroDataModel.sectore_id;
		this.servicioRegistro.updateRegistros(RegistroDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSectore=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

