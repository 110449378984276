import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Prestamos } from '../../prestamo/prestamos.model';
import { PrestamosServices } from '../../prestamo/prestamos.services';
import { Solicitudprestamos } from '../../../solicitudprestamos/solicitudprestamo/solicitudprestamos.model';
import { SolicitudprestamosServices } from '../../../solicitudprestamos/solicitudprestamo/solicitudprestamos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-addprestamo',
	templateUrl: './addprestamo.component.html',
	styleUrls: ['./addprestamo.component.scss']
})

export class AddPrestamoComponent implements OnInit {
	public dataSolicitudprestamos:any;
	public dataAdministrativos:any;
	public dataSubperiodos:any;
	public formAddPrestamo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPrestamo:PrestamosServices,
		private servicioSolicitudprestamo:SolicitudprestamosServices,
		private servicioAdministrativo:AdministrativosServices,
		private servicioSubperiodo:SubperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSolicitudprestamo.getDataSolicitudprestamos().subscribe((data:any)=>{
			this.dataSolicitudprestamos=data.solicitudprestamos;
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.dataAdministrativos=data.administrativos;
		});
		this.servicioSubperiodo.getDataSubperiodos().subscribe((data:any)=>{
			this.dataSubperiodos=data.subperiodos;
		});
		this.formAddPrestamo = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			monto:[null,Validators.compose([Validators.required,CustomValidators.number])],
			plazo:[null,Validators.compose([Validators.required,CustomValidators.number])],
			solicitudprestamo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			administrativo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			subperiodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let prestamoDataModel:Prestamos=this.formAddPrestamo.value;
		this.servicioPrestamo.savePrestamos(prestamoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}