import { Component, OnInit, Input } from '@angular/core';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../../../global/global.model';
import * as moment from 'moment';
import { Time } from '@angular/common';
import { Horarios } from '../../horario/horarios.model';
import { HorariosServices } from '../../horario/horarios.servicies';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';

@Component({
  selector: 'app-editarhorario',
  templateUrl: './editarhorario.component.html',
  styleUrls: ['./editarhorario.component.scss']
})
export class EditarhorarioComponent implements OnInit {
  @Input() horario: any;
  inicio: any = null;
  fin: any = null;
  rows = [];
  temp = [];
  public imgUrlSocios: string;
  @Input() Buscar: any;
  dataPaging: any;
  total: number = 0;
  enableAdd: boolean = true;
  dataInfo:any;
  dataSocioNuevo:any;

  constructor(private serviciosSocios: SociosServices,
    private servicioHorarios: HorariosServices,
    private notificationServices: NotificationServices,
    private activeModal: NgbActiveModal,) { }

  ngOnInit() {
    console.log(this.horario);

    this.dataInfo=this.horario.registro;
    this.inicio = moment(this.horario.inicio).format("HH:mm");
    this.fin = moment(this.horario.fin).format("HH:mm");
    if (this.inicio == null || this.fin == null)
      this.enableAdd = true;
    else
      this.enableAdd = false;



    let dataSend = { 'id': this.Buscar.sectore_id } as Sectores;
    this.imgUrlSocios = Globals.BASE_URL_API_REST;
    this.serviciosSocios.buscarSociosPorSector(dataSend).subscribe((data: any) => {
      this.dataPaging = data.paging;
      this.rows = data.socios;
      this.temp = [...this.rows];
      this.total = data.cantidad;
      console.log(this.rows);
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.temp.filter(function (d) {
      return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
    });
    this.rows = temp;
  }

  seleccionar(data: any) {
    this.dataSocioNuevo=data;
    this.dataInfo=data.registros[0];
  }

  agregar() {
   
    let fecha1 = moment(this.inicio, 'HH:mm');
    let fecha2 = moment(this.fin, 'HH:mm');
    let diferecia: any = moment.duration(fecha2.diff(fecha1));
    let horas = diferecia._data.hours;
    let minutes = diferecia._data.minutes;

    let dataSendInfo = {} as Horarios;
    dataSendInfo.dia = Number(this.Buscar.dia);
    dataSendInfo.tipo = Number(this.Buscar.tipo);
    dataSendInfo.inicio = this.inicio;
    dataSendInfo.fin = this.fin;
    dataSendInfo.minutos = (horas * 60) + minutes;
    let varHora = '';
    let varMinu = '';
    varHora = '' + horas;
    if (horas < 10)
      varHora = '0' + horas;

    varMinu = '' + minutes;
    if (minutes < 10)
      varMinu = '0' + minutes;

    dataSendInfo.tiempo = varHora + ':' + varMinu;
    dataSendInfo.id = this.horario.id;
    dataSendInfo.registro_id = this.dataInfo.id;
    dataSendInfo.estado = 1;
    console.log(dataSendInfo);

    this.servicioHorarios.updateHorarios(dataSendInfo).subscribe((smsdata: any) => {
      if (smsdata.estado) {
        this.notificationServices.setNotification(smsdata).then((datas) => {
          if (datas) {
            this.activeModal.close({'data':smsdata,'op':1});
          }
        });
      } else {
        this.notificationServices.setNotification(smsdata);
      }
    });

  }

  onChangeInicio(searchValue: string): void {
    if (searchValue) {

      if (this.inicio == null || this.fin == null)
        this.enableAdd = true;
      else
        this.enableAdd = false;
    } else {
      this.inicio = null;
      this.enableAdd = true;
    }
  }

  onChangeFin(searchValue: string): void {

    if (searchValue) {
      if (this.inicio == null || this.fin == null)
        this.enableAdd = true;
      else
        this.enableAdd = false;
    } else {
      this.fin = null;
      this.enableAdd = true;
    }
  }

  eliminar(){
    this.dataInfo=this.horario.registro;
    this.dataSocioNuevo=undefined;
  }

}
