import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Gastos } from '../../gasto/gastos.model';
import { GastosServices } from '../../gasto/gastos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editgasto',
	templateUrl: './editgasto.component.html',
	styleUrls: ['./editgasto.component.scss']
})

export class EditGastoComponent implements OnInit {

	@Input() Gasto:Gastos;

	public formGastoMotivo:FormGroup;
	public editMotivo:boolean=false;

	
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioGasto:GastosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formGastoMotivo=this.fb.group({
			motivo:[this.Gasto.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
		});

		
	}
	

	editGastoMotivoEnable(){
		this.editMotivo=true;
		this.formGastoMotivo=this.fb.group({
			motivo:[this.Gasto.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
		});
	}

	editGastoMotivoDisable(){
		this.editMotivo=false;
	}

	saveGastoMotivo(){
		let GastoDataModel:Gastos=this.formGastoMotivo.value;
		GastoDataModel.id=this.Gasto.id;
		this.Gasto.motivo=GastoDataModel.motivo;
		this.servicioGasto.updateGastos(GastoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMotivo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	

}

