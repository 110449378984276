import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Solicitudprestamos } from '../../solicitudprestamo/solicitudprestamos.model';
import { SolicitudprestamosServices } from '../../solicitudprestamo/solicitudprestamos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { SolicitudPrestamoServices } from '../../../solicitudcreditos/solicitudes/solicitudes.services';
import { NgxUiLoaderService,SPINNER } from 'ngx-ui-loader';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-addsolicitudprestamo',
	templateUrl: './addsolicitudprestamo.component.html',
	styleUrls: ['./addsolicitudprestamo.component.scss']
})

export class AddSolicitudprestamoComponent implements OnInit {
	@Input() Subperiodo:any;
	@Input() Socio:Socios;
	inconoLoder:string=SPINNER.wanderingCubes;

	public imgUrlSocios:string;
	userdata:any;
	montoDisponible:number=0;
	montoSolicitado:number=0;
	public formAddSolicitudprestamo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioSolicitudprestamo:SolicitudprestamosServices,
		private servicioAdministrativo:AdministrativosServices,
		private solicitudServices:SolicitudPrestamoServices,
		private validatciones:customValidatorMondogo,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
		this.ngxService.startLoader('loaderAddSolicitud');
		this.seleccionarCapitalMotoSolicitado().then(
			result=>{
				console.log(result);
				this.ngxService.stopLoader('loaderAddSolicitud');
			},
			reason=>{
				console.log(reason);
			}
		);
		this.formAddSolicitudprestamo = this.fb.group({
			administrativo_id:[0,Validators.compose([Validators.required,CustomValidators.number])],
			socio_id:[this.Socio.id,Validators.compose([Validators.required,CustomValidators.number])],
			subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
			//motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
			//fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			plazomeses:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(2,0)])],
			monto:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,0)])],
			estado:[0,null],
		});
	}

	seleccionarCapitalMotoSolicitado(){
		let promise = new Promise((resolve, reject) => {
			this.montoDisponible=0;
			this.montoSolicitado=0;
			this.solicitudServices.getDataSociosSuma(this.Subperiodo).subscribe((data:any)=>{
				this.Subperiodo=data.suma;
				this.montoDisponible=this.Subperiodo.capital-this.montoSolicitado;
				this.montoDisponible=Math.round(this.montoDisponible * 100) / 100; 
				this.solicitudServices.getDataSociosSumaPrestamosPendientes(this.Subperiodo).subscribe((dataSumaPrestamo:any)=>{
					var precio:number=0;
					if(dataSumaPrestamo.suma.prestamos.length>0){
						precio=dataSumaPrestamo.suma.prestamos[0].total;
					}
					var sol:number=0;
					if(this.Subperiodo.solicitudprestamos.length>0){
					
						sol=this.Subperiodo.solicitudprestamos[0].total;
						this.montoSolicitado=this.Subperiodo.solicitudprestamos[0].total+precio;
						this.montoDisponible=this.Subperiodo.capital-this.montoSolicitado;
						this.montoDisponible=Math.round(this.montoDisponible * 100) / 100; 
						if(this.Subperiodo.montomin<this.montoDisponible && this.Subperiodo.montomax>this.montoDisponible){
							this.Subperiodo.montomax=this.montoDisponible;
							resolve(true);
						}else{
							resolve(true);
						}
						
					}else{
						this.montoSolicitado=sol+precio;
						this.montoDisponible=this.Subperiodo.capital-this.montoSolicitado;
						this.montoDisponible=Math.round(this.montoDisponible * 100) / 100; 
						if(this.Subperiodo.montomin<this.montoDisponible && this.Subperiodo.montomax>this.montoDisponible){
							this.Subperiodo.montomax=this.montoDisponible;
							resolve(true);
						}else{
							resolve(true);
						}
					}

				});
			});
	
		});
		return promise;
	}


	save(){
		this.ngxService.startLoader('loaderAddSolicitud');
		this.seleccionarCapitalMotoSolicitado().then(
			result=>{
				if(result){
					this.ngxService.startLoader('loaderAddSolicitud');
					if(this.userdata.datos.socio){
						this.servicioAdministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
							this.formAddSolicitudprestamo.value.administrativo_id=datadmin.administrativo.id;
							if(this.Subperiodo.montomin>this.montoDisponible){
								let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El saldo disponible debe ser mayor que <code>'+this.Subperiodo.montomin+'</code>'}};
								this.Noti.setNotification(smsFail);
								this.ngxService.stopLoader('loaderAddSolicitud');
							}else{
								if(this.formAddSolicitudprestamo.value.monto<this.Subperiodo.montomin){
									let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto de ser mayor que <code>'+this.Subperiodo.montomin+'</code>'}};
									this.Noti.setNotification(smsFail);
									this.ngxService.stopLoader('loaderAddSolicitud');
								}else if (this.formAddSolicitudprestamo.value.monto>this.Subperiodo.montomax) {
									let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto de ser menor que <code>'+this.Subperiodo.montomax+'</code>'}};
									this.Noti.setNotification(smsFail);
									this.ngxService.stopLoader('loaderAddSolicitud');
								} else {
									let solicitudprestamoDataModel:Solicitudprestamos=this.formAddSolicitudprestamo.value;
									this.servicioSolicitudprestamo.saveSolicitudprestamos(solicitudprestamoDataModel).subscribe((data:any)=>{
										if(data.estado){
											this.ngxService.stopLoader('loaderAddSolicitud');
											this.Noti.setNotification(data).then((datas)=>{
												if(datas){
													this.activeModal.close(1);
												}
											});
										}else{
											this.Noti.setNotification(data);
										}
									});
								}
							}
						});
					}
				}
				console.log(result);
			},
			reason=>{
				console.log(reason);
			}
		);
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}