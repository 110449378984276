import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Administrativos } from '../../administrativo/administrativos.model';
import { AdministrativosServices } from '../../administrativo/administrativos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Periodos } from '../../../periodos/periodo/periodos.model';
import { PeriodosServices } from '../../../periodos/periodo/periodos.services';

@Component({
	selector: 'app-editadministrativo',
	templateUrl: './editadministrativo.component.html',
	styleUrls: ['./editadministrativo.component.scss']
})

export class EditAdministrativoComponent implements OnInit {

	@Input() Administrativo:Administrativos;
	public dataSocios={'cedula':'vacio'} as Socios;
	public sociosData:any;
	public dataPeriodos={'nombre':'vacio'} as Periodos;
	public periodosData:any;

	public formAdministrativoSocio:FormGroup;
	public editSocio:boolean=false;

	public formAdministrativoPeriodo:FormGroup;
	public editPeriodo:boolean=false;

	public formAdministrativoCargo:FormGroup;
	public editCargo:boolean=false;

	public formAdministrativoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioAdministrativo:AdministrativosServices,
		private servicioSocio:SociosServices,
		private servicioPeriodo:PeriodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.sociosData=data.socios;
			this.dataSocios=this.sociosData.find(obj => obj.id == this.Administrativo.socio_id);
		});
		this.servicioPeriodo.getDataPeriodos().subscribe((data:any)=>{
			this.periodosData=data.periodos;
			this.dataPeriodos=this.periodosData.find(obj => obj.id == this.Administrativo.periodo_id);
		});

		this.formAdministrativoSocio=this.fb.group({
			socio_id:[this.Administrativo.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formAdministrativoPeriodo=this.fb.group({
			periodo_id:[this.Administrativo.periodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formAdministrativoCargo=this.fb.group({
			cargo:[this.Administrativo.cargo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});
	}
	
	editAdministrativoSocioEnable(){
		this.editSocio=true;
		this.formAdministrativoSocio=this.fb.group({
			socio_id:[this.Administrativo.socio_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editAdministrativoSocioDisable(){
		this.editSocio=false;
	}

	saveAdministrativoSocio(){
		let AdministrativoDataModel:Administrativos=this.formAdministrativoSocio.value;
		AdministrativoDataModel.id=this.Administrativo.id;
		this.Administrativo.socio_id=AdministrativoDataModel.socio_id;
		this.servicioAdministrativo.updateAdministrativos(AdministrativoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSocio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editAdministrativoPeriodoEnable(){
		this.editPeriodo=true;
		this.formAdministrativoPeriodo=this.fb.group({
			periodo_id:[this.Administrativo.periodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editAdministrativoPeriodoDisable(){
		this.editPeriodo=false;
	}

	saveAdministrativoPeriodo(){
		let AdministrativoDataModel:Administrativos=this.formAdministrativoPeriodo.value;
		AdministrativoDataModel.id=this.Administrativo.id;
		this.Administrativo.periodo_id=AdministrativoDataModel.periodo_id;
		this.servicioAdministrativo.updateAdministrativos(AdministrativoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPeriodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editAdministrativoCargoEnable(){
		this.editCargo=true;
		this.formAdministrativoCargo=this.fb.group({
			cargo:[this.Administrativo.cargo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
		});
	}

	editAdministrativoCargoDisable(){
		this.editCargo=false;
	}

	saveAdministrativoCargo(){
		let AdministrativoDataModel:Administrativos=this.formAdministrativoCargo.value;
		AdministrativoDataModel.id=this.Administrativo.id;
		this.Administrativo.cargo=AdministrativoDataModel.cargo;
		this.servicioAdministrativo.updateAdministrativos(AdministrativoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCargo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

