import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../global/global.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable()
export class ConsultasAportesMutlasServices {

	constructor(private http: HttpClient) { }

	getDataConsultaEstadoSocio(page: string): Observable<any[]> {
		return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'buscar_estado_cuenta_socios.json?nombres=' + page);
	}

	getDataDetalleCobros(data: any): Observable<any[]> {
		return this.http.post<any[]>(Globals.BASE_URL_API_REST + 'buscar_estado_cuenta_socios.json', data);
	}

	getDataDetalleHorarios(data: any): Observable<any[]> {
		return this.http.put<any[]>(Globals.BASE_URL_API_REST + 'buscar_estado_cuenta_socios.json', data);
	}

}
