import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Prestamos } from '../../prestamo/prestamos.model';
import { PrestamosServices } from '../../prestamo/prestamos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Solicitudprestamos } from '../../../solicitudprestamos/solicitudprestamo/solicitudprestamos.model';
import { SolicitudprestamosServices } from '../../../solicitudprestamos/solicitudprestamo/solicitudprestamos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import * as moment from 'moment';

@Component({
	selector: 'app-editprestamo',
	templateUrl: './editprestamo.component.html',
	styleUrls: ['./editprestamo.component.scss']
})

export class EditPrestamoComponent implements OnInit {

	@Input() Prestamo:any;
	dataDate = {'mon':null,'mes':null,'dia':null,'anio':null} as any;
	public formPrestamoFecha:FormGroup;
	public editFecha:boolean=false;
	dateData:any;
	public imgUrlSocios:string;
  	userdata:any;
	
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPrestamo:PrestamosServices,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		console.log(this.Prestamo);
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
		
		this.servicioPrestamo.viewPrestamosDayHoy().subscribe((data:any)=>{
			if(data){
				this.dateData=data.fecha;
			}
			console.log(this.dateData);
			
		});

		var datePipeFecha = new DatePipe("en-US");
		this.formPrestamoFecha=this.fb.group({
			fecha:[datePipeFecha.transform(this.Prestamo.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		
	}
	

	editPrestamoFechaEnable(){
		this.editFecha=true;
		var datePipeFecha = new DatePipe("en-US");
		this.formPrestamoFecha=this.fb.group({
			fecha:[datePipeFecha.transform(this.Prestamo.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editPrestamoFechaDisable(){
		this.editFecha=false;
	}

	savePrestamoFecha(){
		let data={'val':this.dataDate.dia+' de cada mes','msg':'Se confirmar la entrega del monto de <code>$'+this.Prestamo.monto+'</code> con fecha del primero pago <code>'+this.dataDate.dia+' de '+this.dataDate.mes+' de '+this.dataDate.anio+' </code> y con fecha de pagos siguientes'};
		this.Noti.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				if(this.userdata.datos.socio){
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
						
						let PrestamoDataModel:any=this.formPrestamoFecha.value;
						PrestamoDataModel.id=this.Prestamo.id;
						PrestamoDataModel.administrativo_id=datadmin.administrativo.id;
						PrestamoDataModel.date=this.dataDate;
						PrestamoDataModel.estado=1;
						this.servicioPrestamo.updatePrestamos(PrestamoDataModel).subscribe((data:any)=>{
							if(data.estado){
								this.Noti.setNotification(data).then((datas)=>{
									if(datas){
										this.activeModal.close(1);
									}
								});
							}else{
								this.Noti.setNotification(data);
							}
						});
					});
				}
			}
		});
	}

	setDayPay(data:any){
		this.dataDate.mes=this.getMon(data.mes);
		this.dataDate.dia=data.dia;
		this.dataDate.mon=data.mes;
		this.dataDate.anio=data.anio;
	}

	getMon(val:number):string{		
		let mes:string="";
		switch (val) {
			case 1:
				return mes="Enero";
				break;
			case 2:
				return mes="Febrero";
				break;
			case 3:
					return mes="Marzo";
				break;
			case 4:
				return mes="Abril";
				break;
			case 5:
				return mes="Mayo";
				break;
			case 6:
					return mes="Junio";
				break;
			case 7:
				return mes="Julio";
				break;
			case 8:
				return mes="Agosto";
				break;
			case 9:
					return mes="Septiembre";
				break;
			case 10:
				return mes="Octubre";
				break;
			case 11:
				return mes="Noviembre";
				break;
			case 12:
					return mes="Diciembre";
				break;
			default:
				break;
		}
	}

	cambiarFecha(){
		this.dataDate.dia=null;
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	
}

