import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../global/global.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class FotoBeneficiariosServices {

	constructor(private http: HttpClient) { }

	saveFotoBeneficiario(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'fotobeneficiarios.json',data);
	}

}