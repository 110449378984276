import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Globals } from '../../../global/global.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SociosServices } from '../socio/socios.services';
import { DomSanitizer } from '@angular/platform-browser';
import { SectoresServices } from '../../sectores/sectore/sectores.services';
import { ReportesServices } from '../../reporte/reportes.services';
import { AddRegistroComponent } from '../../registros/modal/add/addregistro.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Sectores } from '../../sectores/sectore/sectores.model';

@Component({
	selector: 'app-simulador',
	templateUrl: './simulador.component.html',
	styleUrls: ['./simulador.component.scss']
})
export class SimuladorComponent implements OnInit {

	rows = [];
	temp = [];
	public imgUrlSocios: string;
	pdfpath: any = "";
	public dataSectores: any;
	public dataSector: Sectores;
	total: number = 0;
	precio: number = 0;
	minutos: number = 0;
	tiempoacumulado: number = 0;
	montoacumulado: number = 0;
	resulh: number = 0;
	resulm: number = 0;
	filter: boolean = false;
	buscar: string = '';
	dataPaging: any;
	numberPage: number = 1;
	@ViewChild('tableSocio') table;
	private isLoading: boolean = false;
	tipoCalculo:number=1;

	iconItems = [{
		text: 'Retornar',
		icon: 'ti-reload'
	}]

	configMotivo: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if (text == 'Retornar') {
			this.router.navigateByUrl('/aportes/index', { relativeTo: this.route.parent, skipLocationChange: true });
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosSocios: SociosServices,
		public sanitizer: DomSanitizer,
		private servicioSectore: SectoresServices,
		private changeDetector: ChangeDetectorRef,
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit() {
		this.imgUrlSocios = Globals.BASE_URL_API_REST;

		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			this.dataSectores = data.sectores;
		});
		this.listaSocios();
	}

	onChange(data: any) {
		this.filter = false;
		this.rows = [];
		this.montoacumulado = 0;
		this.tiempoacumulado = 0;
		if (data != undefined) {
			this.dataSector = data;
			this.serviciosSocios.buscarSociosPorSector(data).subscribe((data: any) => {

				this.rows = data.socios;
				this.temp = [...this.rows];
				this.total = data.cantidad;
			});
		} else {
			this.dataSector = undefined;
			this.listaSocios();
		}

	}

	listaSocios() {
		this.rows = [];
		this.serviciosSocios.getDataSociosVista().subscribe((data: any) => {

			this.rows = data.socios;
			this.temp = [...this.rows];
			this.total = data.cantidad;
		});
	}

	updateFilter(event) {
		this.filter = true;
		this.buscar = event.target.value.toLowerCase();

	}

	findFilter() {
		this.rows = [];
		setTimeout(() => {
			const val = this.buscar;
			const temp = this.temp.filter(function (d) {
				return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
			});
			this.rows = temp;
		}, 2000);
	}



	setPage(pageInfo) {
		this.filter = false;
		this.isLoading = true;
		this.numberPage = pageInfo.offset + 1;
		this.serviciosSocios.getDataSociosVista().subscribe((data: any) => {

			this.rows = data.socios;
			this.temp = [...this.rows];
			this.isLoading = false;
		});

	}

	sector(data: any) {
		this.filter = false;
		let dialogRef = this.dialog.open(AddRegistroComponent, this.config);
		dialogRef.componentInstance.Socio = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSocios();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	calcular() {
		this.filter = false;
		this.montoacumulado = 0;
		this.tiempoacumulado = 0;
		this.rows = [];
		if (this.dataSector != undefined) {
			this.serviciosSocios.buscarSociosPorSector(this.dataSector).subscribe((data: any) => {

				this.rows = data.socios;
				this.temp = [...this.rows];
				this.total = data.cantidad;
			});
		} else {
			this.listaSocios();
		}
	}

	acumular(val: any) {
		if (this.minutos > 0 && this.precio > 0 && this.filter == false) {
			this.montoacumulado += this.round(val.monto);
			this.montoacumulado = this.round(this.montoacumulado);
			this.tiempoacumulado += val.tiempo;
			this.resulh = (this.tiempoacumulado / 60);
			this.resulh = Math.floor(this.resulh);
			this.resulm = (this.tiempoacumulado - (this.resulh * 60));
		}
	}

	acumularsector(val: any) {
		for (let j = 0; j < val.length; j++) {
			const element = val[j];
			if (this.minutos > 0 && this.precio > 0 && this.filter == false) {
				this.montoacumulado += this.round(element.monto);
				this.montoacumulado = this.round(this.montoacumulado);
				this.tiempoacumulado += element.tiempo;
				this.resulh = (this.tiempoacumulado / 60);
				this.resulh = Math.floor(this.resulh);
				this.resulm = (this.tiempoacumulado - (this.resulh * 60));
			}	
		}
	}

	round(num: any, decimales = 2) {
		let signo: any = (num >= 0 ? 1 : -1);
		num = num * signo;
		if (decimales === 0) //con 0 decimales
			return signo * Math.round(num);
		// round(x * 10 ^ decimales)
		num = num.toString().split('e');
		num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
		// x * 10 ^ (-decimales)
		num = num.toString().split('e');
		let val: any = num[0] + 'e';
		return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
	}

	tipo(data:any){
		console.log(data.target.value);
		this.tipoCalculo=Number(data.target.value);
		this.calcular();
		
	}

	ngAfterViewChecked() { this.changeDetector.detectChanges(); }
}

