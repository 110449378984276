import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Subperiodos } from '../../subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../subperiodo/subperiodos.services';
import { Periodos } from '../../../periodos/periodo/periodos.model';
import { PeriodosServices } from '../../../periodos/periodo/periodos.services';

@Component({
	selector: 'app-addsubperiodo',
	templateUrl: './addsubperiodo.component.html',
	styleUrls: ['./addsubperiodo.component.scss']
})

export class AddSubperiodoComponent implements OnInit {
	@Input() Periodo:Periodos;
	
	public dataPeriodos:any;
	public formAddSubperiodo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioSubperiodo:SubperiodosServices,
		private servicioPeriodo:PeriodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPeriodo.getDataPeriodos().subscribe((data:any)=>{
			this.dataPeriodos=data.periodos;
		});
		this.formAddSubperiodo = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			fechainicio:[null,Validators.compose([Validators.required,CustomValidators.date])],
			fechafin:[null,Validators.compose([Validators.required,CustomValidators.date])],
			periodo_id:[this.Periodo.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let subperiodoDataModel:Subperiodos=this.formAddSubperiodo.value;
		this.servicioSubperiodo.saveSubperiodos(subperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}