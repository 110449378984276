import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Pagoprestamos } from '../../pagoprestamo/pagoprestamos.model';
import { PagoprestamosServices } from '../../pagoprestamo/pagoprestamos.services';

@Component({
	selector: 'app-edit-motivo-cuota',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditCuotaPagoMotivoCuotaComponent implements OnInit {

	@Input() Cuota:Pagoprestamos;
	public formCuotaMotivo:FormGroup;
	userdata:any;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private servicesPagoprestamos:PagoprestamosServices,
		private Noti:NotificationServices,) { }

	ngOnInit(){
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.formCuotaMotivo=this.fb.group({
            motivo:[this.Cuota.motivo,Validators.compose([Validators.required,Validators.minLength(15), Validators.maxLength(50)])],
            estado:[2,Validators.compose([Validators.required])],
		});
	}

	saveCuotaMotivo(){
		let CuotaDataModel:Pagoprestamos=this.formCuotaMotivo.value;
		CuotaDataModel.id=this.Cuota.id;
        this.Cuota.motivo=CuotaDataModel.motivo;
		this.Cuota.estado=CuotaDataModel.estado;
		
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				this.Cuota.administrativo_id=datadmin.administrativo.id;
				CuotaDataModel.administrativo_id=datadmin.administrativo.id;
				this.servicesPagoprestamos.updatePagoprestamos(CuotaDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.activeModal.close(1);
							}
						});
					}else{
						this.Noti.setNotification(data);
					}
				});
			});
		}
	}

}