import { Injectable } from '@angular/core';
import { Http,Headers } from '@angular/http';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Users} from './users.model';
import {localStorageServices} from '../../auth/localStorageServices';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class UserServices {
    
    constructor(private http: HttpClient,private localdata:localStorageServices) { }
    
    getDataUsers():Observable<Users[]>{
        return this.http.get<Users[]>(Globals.BASE_URL_API_REST+'users.json');
    }
    
    updatePassword(data:Users){
        return this.http.patch(Globals.BASE_URL_API_REST+'editpass.json/'+this.localdata.getValueFromLocalStorage().datos.id,data);
    }

    update(data:Users){
        return this.http.patch(Globals.BASE_URL_API_REST+'users/'+this.localdata.getValueFromLocalStorage().datos.id+'.json',data);
    }

    updatePhoto(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'fotousers.json',data);
    }

    saveUser(data:Users){
        return this.http.post(Globals.BASE_URL_API_REST+'users.json',data);
    }

    deleteUser(id:number){
        return this.http.delete(Globals.BASE_URL_API_REST+'users/'+id+'.json');
    }

    updateUser(data:Users){
        return this.http.patch(Globals.BASE_URL_API_REST+'users/'+data.id+'.json',data);
    }

    buscarSociosUser(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'administrativoslista.json',data);
	}

}
