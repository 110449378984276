import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Eventos } from '../../evento/eventos.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';


@Component({
	selector: 'app-addevento-minutosector',
	templateUrl: './minutosector.component.html',
	styleUrls: ['./minutosector.component.scss']
})

export class AddMinutosSectorEventoComponent implements OnInit {
	rows = [];
	temp = [];
	public imgUrlSocios: string;
	userdata: any;
	ver: boolean = true;
	@Input() Evento = { 'tipo': 3 } as Eventos;
	@Input() Aporteperiodo = { 'montoaporte': 0 } as Aportesperiodos;
	inconoLoder: string = SPINNER.wanderingCubes;
	public dataSectores: any;
	public dataSector: Sectores;
	tiempoacumulado: number = 0;
	montoacumulado: number = 0;
	resulh: number = 0;
	resulm: number = 0;
	filter: boolean = false;
	dataBuscar: string = "";
	tipoCalculo: number = 1;

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		public localdata: localStorageServices,
		private changeDetector: ChangeDetectorRef,
		private servicioSocios: SociosServices,
		private servicioPagos: PagosServices,
		private ngxService: NgxUiLoaderService,
		private servicioSectore: SectoresServices,
		private servicioadministrativo: AdministrativosServices,
		private Noti: NotificationServices,) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios = Globals.BASE_URL_API_REST;

		this.ngxService.startLoader('loaderAportesAgregarSosio');
		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			const temp = data.sectores.filter(function (d) {
				return d.id != 0;
			});
			this.dataSectores = temp;
		});
		this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
			this.rows = data.socios;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('loaderAportesAgregarSosio');
		});
	}

	updateFilter(event) {
		this.filter = true;
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}

	saveallGeneral() {
		let datasms = { 'val': this.Evento.detalle + ', ' + this.dataSector.nombre, 'msg': 'Asignar aporte por sector a todos los socios por minutos' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.ver = false;
				console.log(this.temp);
				this.rows.forEach((obj: any, index) => {
					setTimeout(() => {
						console.log(obj);

						if (this.userdata.datos.socio && obj.montotiempo > 0) {
							this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

								if (this.tipoCalculo == 1) {
									let dataPagos = {} as Pagos;
									dataPagos.administrativo_id = datadmin.administrativo.id;
									dataPagos.evento_id = this.Evento.id;
									dataPagos.socio_id = obj.id;
									dataPagos.estado = 0;
									dataPagos.montototal = obj.montotiempo;
									dataPagos.sectore_id = 0;
									
									this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
									});

								} else if (this.tipoCalculo == 2) {
									for (let i = 0; i < obj.agregar.length; i++) {
										const element = obj.agregar[i];
										let dataPagos = {} as Pagos;
										dataPagos.administrativo_id = datadmin.administrativo.id;
										dataPagos.evento_id = this.Evento.id;
										dataPagos.socio_id = obj.id;
										dataPagos.estado = 0;
										dataPagos.montototal = element.monto;
										dataPagos.sectore_id = element.id;
										this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
										});
									}
								}

							});
						}
						this.rows.splice(0, 1);
					}, 1500 * index);
				});
			}
		});
	}

	onChange(data: any) {
		this.filter = false;
		this.rows = [];
		this.montoacumulado = 0;
		this.tiempoacumulado = 0;
		if (data != undefined) {
			this.dataSector = data;
			let dataSend = { 'sectore_id': this.dataSector.id, 'id': this.Evento.id } as any;
			this.servicioSocios.buscarSociosPorSectorActividad(dataSend).subscribe((data: any) => {
				this.rows = data.socios;
				this.temp = [...this.rows];
			});
		} else {
			this.dataSector = undefined;
			this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
				this.rows = data.socios;
				this.temp = [...this.rows];
				this.ngxService.stopLoader('loaderAportesAgregarSosio');
			});
		}
	}

	acumular(val: any, data: Socios) {
		if (this.Aporteperiodo.montoaporte > 0 && this.Aporteperiodo.tiempo > 0 && this.filter == false) {
			this.montoacumulado += this.round(val.monto);
			this.montoacumulado = this.round(this.montoacumulado);
			this.tiempoacumulado += val.tiempo;
			this.resulh = (this.tiempoacumulado / 60);
			this.resulh = Math.floor(this.resulh);
			this.resulm = (this.tiempoacumulado - (this.resulh * 60));
			console.log(val);

			for (let index = 0; index < this.temp.length; index++) {
				const element: any = this.temp[index];
				if (element.id === data.id) {
					this.temp[index].montotiempo = val.monto;
					break;
				}
			}
		}
	}

	acumularsector(val: any, data: Socios) {
		for (let j = 0; j < val.length; j++) {
			const element = val[j];
			console.log(element);
			if (this.Aporteperiodo.montoaporte > 0 && this.Aporteperiodo.tiempo > 0 && this.filter == false) {
				this.montoacumulado += this.round(element.monto);
				this.montoacumulado = this.round(this.montoacumulado);
				this.tiempoacumulado += element.tiempo;
				this.resulh = (this.tiempoacumulado / 60);
				this.resulh = Math.floor(this.resulh);
				this.resulm = (this.tiempoacumulado - (this.resulh * 60));

			}
		}

		for (let index = 0; index < this.temp.length; index++) {
			const element: any = this.temp[index];
			if (element.id === data.id) {
				this.temp[index].agregar = val;
				break;
			}
		}

	}

	ngAfterViewChecked() { this.changeDetector.detectChanges(); }

	saveallTodo() {
		let datasms = { 'val': this.Evento.detalle, 'msg': 'Asignar aporte general a todos los socios por minutos' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.ver = false;
				console.log(this.temp);
				this.temp.forEach((obj: any, index) => {
					setTimeout(() => {
						console.log(obj);

						if (this.userdata.datos.socio && obj.montotiempo > 0) {
							this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {



								if (this.tipoCalculo == 1) {
									let dataPagos = {} as Pagos;
									dataPagos.administrativo_id = datadmin.administrativo.id;
									dataPagos.evento_id = this.Evento.id;
									dataPagos.socio_id = obj.id;
									dataPagos.estado = 0;
									dataPagos.montototal = obj.montotiempo;
									dataPagos.sectore_id = 0;

									this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
									});

								} else if (this.tipoCalculo == 2) {
									for (let i = 0; i < obj.agregar.length; i++) {
										const element = obj.agregar[i];
										let dataPagos = {} as Pagos;
										dataPagos.administrativo_id = datadmin.administrativo.id;
										dataPagos.evento_id = this.Evento.id;
										dataPagos.socio_id = obj.id;
										dataPagos.estado = 0;
										dataPagos.montototal = element.monto;
										dataPagos.sectore_id = element.id;
										this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
										});
									}
								}

							});
						}
						this.temp.splice(0, 1);
						this.rows.splice(0, 1);
					}, 1500 * index);
				});
			}
		});
	}

	cerrarModal() {
		this.activeModal.close(2);
	}

	round(num: any, decimales = 2) {
		let signo: any = (num >= 0 ? 1 : -1);
		num = num * signo;
		if (decimales === 0) //con 0 decimales
			return signo * Math.round(num);
		// round(x * 10 ^ decimales)
		num = num.toString().split('e');
		num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
		// x * 10 ^ (-decimales)
		num = num.toString().split('e');
		let val: any = num[0] + 'e';
		return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
	}

	tipo(data: any) {
		this.tiempoacumulado = 0;
		this.montoacumulado = 0;
		console.log(data.target.value);
		this.tipoCalculo = Number(data.target.value);

	}

}