import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Aportesperiodos} from './aportesperiodos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class AportesperiodosServices {

	constructor(private http: HttpClient) { }

	getDataAportesperiodos():Observable<Aportesperiodos[]>{
		return this.http.get<Aportesperiodos[]>(Globals.BASE_URL_API_REST+'aportesperiodos.json');
	}

	saveAportesperiodos(data:Aportesperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'aportesperiodos.json',data);
	}

	deleteAportesperiodos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'aportesperiodos/'+id+'.json');
	}

	updateAportesperiodos(data:Aportesperiodos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'aportesperiodos/'+data.id+'.json',data);
	}

	viewAportesperiodos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'aportesperiodos/'+id+'.json');
	}

	viewAportesPeriodosAsignados(data:Subperiodos){
		return this.http.put(Globals.BASE_URL_API_REST+'aportesasignadas.json',data);
	}

	viewAportesPeriodosAsignadosConValores(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'aportesasignadas.json',data);
	}

	viewAportesPeriodosAsignadosConValoresTemporales(data:Subperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'aportesasignadas.json',data);
	}

	viewAportesPeriodosPorSubperiodo(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'periodos_subperiodo_aportes_lista/'+id+'.json');
    }
	   
}