import { Component, OnInit, Input } from '@angular/core';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../../../global/global.model';

@Component({
  selector: 'app-agregarhorario',
  templateUrl: './agregarhorario.component.html',
  styleUrls: ['./agregarhorario.component.scss']
})
export class AgregarhorarioComponent implements OnInit {

  rows = [];
  temp = [];
  public imgUrlSocios: string;
  @Input() Buscar:any;
  dataPaging: any;
  total: number = 0;

  constructor(private serviciosSocios: SociosServices,
              private activeModal:NgbActiveModal,) { }

  ngOnInit() {
    let dataSend= {'id':this.Buscar.sectore_id} as Sectores;
    this.imgUrlSocios=Globals.BASE_URL_API_REST;
    this.serviciosSocios.buscarSociosPorSector(dataSend).subscribe((data: any) => {
      this.dataPaging = data.paging;
      this.rows = data.socios;
      this.temp = [...this.rows];
      this.total = data.cantidad;
      console.log(this.rows);
    });
  }

  updateFilter(event){
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function(d){
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
  }
  
  seleccionar(data:any){
    this.activeModal.close({'data':data,'op':1});
  }

}
