import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditMultaComponent } from './edit/editmulta.component';
import { AddMultaComponent } from './add/addmulta.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddMultaReguistoComponent } from './reguistro/reguistro.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgbModule,
		NgxUiLoaderModule,
	],
	declarations:[EditMultaComponent,AddMultaComponent,AddMultaReguistoComponent],
	entryComponents:[EditMultaComponent,AddMultaComponent,AddMultaReguistoComponent],
})
export class ModalMultaModule { }
