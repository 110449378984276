import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Cuotas } from '../../cuota/cuotas.model';
import { CuotasServices } from '../../cuota/cuotas.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import * as moment from 'moment';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-editcuota',
	templateUrl: './editcuota.component.html',
	styleUrls: ['./editcuota.component.scss']
})

export class EditCuotaComponent implements OnInit {

	@Input() Cuota:Cuotas;
	public dataAdministrativos={'cargo':'vacio'} as Administrativos;
	public administrativosData:any;
	public dataPagos={'id':0} as Pagos;
	public pagosData:any;

	public formCuotaFecha:FormGroup;
	public editFecha:boolean=false;

	public formCuotaAbono:FormGroup;
	public editAbono:boolean=false;

	

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioCuota:CuotasServices,
		private servicioAdministrativo:AdministrativosServices,
		private validatciones:customValidatorMondogo,
		private servicioPago:PagosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.administrativosData=data.administrativos;
			this.dataAdministrativos=this.administrativosData.find(obj => obj.id == this.Cuota.administrativo_id);
		});
		this.servicioPago.getDataPagos().subscribe((data:any)=>{
			this.pagosData=data.pagos;
			this.dataPagos=this.pagosData.find(obj => obj.id == this.Cuota.pago_id);
		});

		this.formCuotaFecha=this.fb.group({
			fecha:[moment(this.Cuota.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		this.formCuotaAbono=this.fb.group({
			abono:[this.Cuota.abono,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
		});	


		
	}
	

	editCuotaFechaEnable(){
		this.editFecha=true;
		this.formCuotaFecha=this.fb.group({
			fecha:[moment(this.Cuota.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editCuotaFechaDisable(){
		this.editFecha=false;
	}

	saveCuotaFecha(){
		let CuotaDataModel:Cuotas=this.formCuotaFecha.value;
		CuotaDataModel.id=this.Cuota.id;
		this.Cuota.fecha=CuotaDataModel.fecha;
		this.servicioCuota.updateCuotas(CuotaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editCuotaAbonoEnable(){
		this.editAbono=true;
		this.formCuotaAbono=this.fb.group({
			abono:[this.Cuota.abono,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
		});		
	}

	editCuotaAbonoDisable(){
		this.editAbono=false;
	}

	saveCuotaAbono(){
		let CuotaDataModel:Cuotas=this.formCuotaAbono.value;
		CuotaDataModel.id=this.Cuota.id;
		this.Cuota.abono=CuotaDataModel.abono;
		this.servicioCuota.updateCuotas(CuotaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editAbono=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

}

