import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Eventos } from '../../evento/eventos.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';


@Component({
	selector: 'app-addevento-sectorreaudacion',
	templateUrl: './sectorreaudacion.component.html',
	styleUrls: ['./sectorreaudacion.component.scss']
})

export class AddRecaudacionSectorEventoComponent implements OnInit {
	rows = [];
	temp = [];
	public imgUrlSocios: string;
	userdata: any;
	ver: boolean = true;
	@Input() Evento = { 'tipo': 3 } as Eventos;
	@Input() Aporteperiodo = { 'montoaporte': 0 } as Aportesperiodos;
	inconoLoder: string = SPINNER.wanderingCubes;
	public dataSectores: any;
	public dataSector: Sectores;

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		public localdata: localStorageServices,
		private servicioSocios: SociosServices,
		private servicioPagos: PagosServices,
		private ngxService: NgxUiLoaderService,
		private servicioSectore: SectoresServices,
		private servicioadministrativo: AdministrativosServices,
		private Noti: NotificationServices, ) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios = Globals.BASE_URL_API_REST;
		console.log(this.Aporteperiodo.montoaporte);
		this.ngxService.startLoader('loaderAportesAgregarSosio');
		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			const temp = data.sectores.filter(function (d) {
				return d.id != 0;
			});
			this.dataSectores = temp;
		});
		this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
			this.rows = data.socios;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('loaderAportesAgregarSosio');
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}

	saveallGeneral() {
		let datasms = { 'val': this.Evento.detalle+', '+this.dataSector.nombre, 'msg': 'Asignar aporte a todos los socios' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.ver = false;
				this.rows.forEach((obj: Socios, index) => {
					setTimeout(() => {
						let dataPagos = {} as Pagos;
						if (this.userdata.datos.socio) {
							this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

								dataPagos.administrativo_id = datadmin.administrativo.id;
								dataPagos.evento_id = this.Evento.id;
								dataPagos.socio_id = obj.id;
								dataPagos.montototal = this.Aporteperiodo.montoaporte;
								dataPagos.estado = 0;
								dataPagos.sectore_id = this.dataSector.id;

								this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {

								});

								//console.log(dataPagos);

							});
						}
						this.rows.splice(0, 1);
					}, 1500 * index);
				});
			}
		});
	}

	onChange(data: any) {
		if (data != undefined) {
			this.dataSector = data;
			const temp = this.temp.filter(function (d) {
				let existe: boolean = false;
				for (let index = 0; index < d.registros.length; index++) {
					if (d.registros[index].sectore_id == data.id) {
						existe = true;
					}
				}
				return existe == true;
			});
			this.rows = temp;
		} else {
			this.dataSector = undefined;
			const temp = this.temp.filter(function (d) {
				return d.id != undefined;
			});
			this.rows = temp;
		}


	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}