import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSubperiodoComponent } from './edit/editsubperiodo.component';
import { AddSubperiodoComponent } from './add/addsubperiodo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditSubperiodoComponent,AddSubperiodoComponent],
	entryComponents:[EditSubperiodoComponent,AddSubperiodoComponent],
})
export class ModalSubperiodoModule { }
