import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Aportes} from './aportes.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class AportesServices {

	constructor(private http: HttpClient) { }

	getDataAportes(page:number):Observable<Aportes[]>{
		return this.http.get<Aportes[]>(Globals.BASE_URL_API_REST+'aportes.json?page='+page);
	}

	saveAportes(data:Aportes){
		return this.http.post(Globals.BASE_URL_API_REST+'aportes.json',data);
	}

	deleteAportes(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'aportes/'+id+'.json');
	}

	updateAportes(data:Aportes){
		 return this.http.patch(Globals.BASE_URL_API_REST+'aportes/'+data.id+'.json',data);
	}

	viewAportes(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'aportes/'+id+'.json');
	}

	viewAportesSubperiodo(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'listaaportes.json',data);
	}

	saveAportesYPeriodo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listaaportes.json',data);
	}
	getAporteSocio(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'aportesocios.json',data);
	}
}