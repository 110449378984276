import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-page-total-cobro',
  templateUrl: './home-page-total-cobro.component.html',
  styleUrls: ['./home-page-total-cobro.component.scss']
})
export class HomePageTotalCobroComponent implements OnInit {
  @Input() cobros:any;
  total:number=0;
  cuotas:number=0;
  totalm:number=0;
  cuotasm:number=0;
  totalcobro:number=0;
  constructor() { }

  ngOnInit() {
   
    this.cobros.pagos.forEach(element => {
      let val=this.round(element.montototal);
      if(element.cuotas.length>0){
        val=this.round(val-element.cuotas[0].total);
      }
      this.total+=this.round(val);
    });
    this.total=this.round(this.total-this.cuotas);

    this.cobros.cobrosmultas.forEach(element => {
      this.total+=this.round(element.abono);
    });
    this.totalm=this.round(this.totalm-this.cuotasm);

    this.totalcobro=this.round(this.total+this.totalm);
  }

  round(num: any, decimales = 2) {
		let signo: any = (num >= 0 ? 1 : -1);
		num = num * signo;
		if (decimales === 0) //con 0 decimales
			return signo * Math.round(num);
		// round(x * 10 ^ decimales)
		num = num.toString().split('e');
		num = Math.round(+(num[0] + 'e' + (num[1] ? (+num[1] + decimales) : decimales)));
		// x * 10 ^ (-decimales)
		num = num.toString().split('e');
		let val: any = num[0] + 'e';
		return signo * (val + (num[1] ? (+num[1] - decimales) : -decimales));
	}

}
