import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppauthModule } from '../appauth/appauth.module';
import { AdministrativosServices } from './administrativos/administrativo/administrativos.services';
import { ModalAdministrativoModule } from './administrativos/modal/modaladministrativo.module';
import { AportesServices } from './aportes/aporte/aportes.services';
import { ModalAporteModule } from './aportes/modal/modalaporte.module';
import { CobrosmultasServices } from './cobrosmultas/cobrosmulta/cobrosmultas.services';
import { ModalCobrosmultaModule } from './cobrosmultas/modal/modalcobrosmulta.module';
import { CuotasServices } from './cuotas/cuota/cuotas.services';
import { ModalCuotaModule } from './cuotas/modal/modalcuota.module';
import { MultasServices } from './multas/multa/multas.services';
import { ModalMultaModule } from './multas/modal/modalmulta.module';
import { MultasperiodosServices } from './multasperiodos/multasperiodo/multasperiodos.services';
import { ModalMultasperiodoModule } from './multasperiodos/modal/modalmultasperiodo.module';
import { PagosServices } from './pagos/pago/pagos.services';
import { ModalPagoModule } from './pagos/modal/modalpago.module';
import { PeriodosServices } from './periodos/periodo/periodos.services';
import { ModalPeriodoModule } from './periodos/modal/modalperiodo.module';
import { SociosServices } from './socios/socio/socios.services';
import { ModalSocioModule } from './socios/modal/modalsocio.module';
import { SubperiodosServices } from './subperiodos/subperiodo/subperiodos.services';
import { ModalSubperiodoModule } from './subperiodos/modal/modalsubperiodo.module';
import { ModalFotoSociosModule } from './fotosocios/modalfotosocio.module';
import { FotoSociosServices } from './fotosocios/fotosocio/fotosocios.services';
import { AportesperiodosServices } from './aportesperiodos/aportesperiodo/aportesperiodos.services';
import { ModalAportesperiodoModule } from './aportesperiodos/modal/modalaportesperiodo.module';
import { EventosServices } from './eventos/evento/eventos.services';
import { ModalEventoModule } from './eventos/modal/modalevento.module';
import { ActividadesServices } from './actividades/actividade/actividades.services';
import { ModalActividadeModule } from './actividades/modal/modalactividade.module';
import { SolicitudprestamosServices } from './solicitudprestamos/solicitudprestamo/solicitudprestamos.services';
import { ModalSolicitudprestamoModule } from './solicitudprestamos/modal/modalsolicitudprestamo.module';
import { SolicitudPrestamoServices } from './solicitudcreditos/solicitudes/solicitudes.services';
import { SolicitudCreditoModalModule } from './solicitudcreditos/modal/solicitudcredito.module';
import { PrestamosServices } from './prestamos/prestamo/prestamos.services';
import { PagoprestamosServices } from './pagoprestamos/pagoprestamo/pagoprestamos.services';
import { ModalPrestamoModule } from './prestamos/modal/modalprestamo.module';
import { ModalPagoprestamoModule } from './pagoprestamos/modal/modalpagoprestamo.module';
import { ReportesServices } from './reporte/reportes.services';
import { customValidatorMondogo } from './custom-validator/customValidator.services';
import { ModalSectoreModule } from './sectores/modal/modalsectore.module';
import { SectoresServices } from './sectores/sectore/sectores.services';
import { ModalRegistroModule } from './registros/modal/modalregistro.module';
import { RegistrosServices } from './registros/registro/registros.services';
import { ModalGastoModule } from './gastos/modal/modalmulta.module';
import { ModalGastosperiodoModule } from './gastosperiodos/modal/modalgastosperiodo.module';
import { GastosServices } from './gastos/gasto/gastos.services';
import { GastosperiodosServices } from './gastosperiodos/gastosperiodo/gastosperiodos.services';
import { PagogastosServices } from './pagogastos/pagogasto/pagogastos.services';
import { ModalPagogastoModule } from './pagogastos/modal/modalpagogasto.module';
import { ModalBeneficiarioModule } from './beneficiarios/modal/modalbeneficiario.module';
import { BeneficiariosServices } from './beneficiarios/beneficiario/beneficiarios.services';
import { ModalFotoBeneficiariosModule } from './fotobeneficiarios/modalforobeneficiario.module';
import { FotoBeneficiariosServices } from './fotobeneficiarios/fotobeneficiario/fotobeneficiarios.services';
import { ModalEvidenciasModule } from './evidencias/modalevidencia.module';
import { EvidenciasServices } from './evidencias/evidencia/evidencias.services';
import { ModalHorarioModule } from './horarios/modal/modalhorarios.module';
import { HorariosServices } from './horarios/horario/horarios.servicies';
import { ModalResumenModule } from './resumenes/modal/modalresumenes.module';
import { ResumenesServices } from './resumenes/resumen/resumenes.services';
import { ModalAsistenciasModule } from './asistencias/modal/modalasistencia.module';
import { AsistenciasServices } from './asistencias/asistencia/asistencias.services';
import { TesorerosectoresServices } from './sectores/sectore/tesorerosectores.services';

@NgModule({
	imports:[
		CommonModule,
		AppauthModule,
		ModalAdministrativoModule,
		ModalAporteModule,
		ModalCobrosmultaModule,
		ModalCuotaModule,
		ModalMultaModule,
		ModalMultasperiodoModule,
		ModalPagoModule,
		ModalPeriodoModule,
		ModalSocioModule,
		ModalSubperiodoModule,
		ModalFotoSociosModule,
		ModalAportesperiodoModule,
		ModalEventoModule,
		ModalActividadeModule,
		ModalSolicitudprestamoModule,
		SolicitudCreditoModalModule,
		ModalPrestamoModule,
		ModalPagoprestamoModule,
		ModalSectoreModule,
		ModalRegistroModule,
		ModalGastoModule,
		ModalGastosperiodoModule,
		ModalPagogastoModule,
		ModalBeneficiarioModule,
		ModalFotoBeneficiariosModule,
		ModalEvidenciasModule,
		ModalHorarioModule,
		ModalResumenModule,
		ModalAsistenciasModule
	],
	providers:[
		AdministrativosServices,
		AportesServices,
		CobrosmultasServices,
		CuotasServices,
		MultasServices,
		MultasperiodosServices,
		PagosServices,
		PeriodosServices,
		SociosServices,
		SubperiodosServices,
		FotoSociosServices,
		AportesperiodosServices,
		EventosServices,
		ActividadesServices,
		SolicitudprestamosServices,
		SolicitudPrestamoServices,
		PrestamosServices,
		PagoprestamosServices,
		ReportesServices,
		SectoresServices,
		RegistrosServices,
		GastosServices,
		GastosperiodosServices,
		PagogastosServices,
		BeneficiariosServices,
		FotoBeneficiariosServices,
		EvidenciasServices,
		customValidatorMondogo,
		HorariosServices,
		ResumenesServices,
		AsistenciasServices,
		TesorerosectoresServices
	],
})
export class AppAppccamgModule {}
