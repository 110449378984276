import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Horarios} from './horarios.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';

@Injectable()
export class HorariosServices {

	constructor(private http: HttpClient) { }

	getDataHorarios(page:number):Observable<Horarios[]>{
		return this.http.get<Horarios[]>(Globals.BASE_URL_API_REST+'horarios.json?page='+page);
	}

	saveHorarios(data:Horarios){
		return this.http.post(Globals.BASE_URL_API_REST+'horarios.json',data);
	}

	deleteHorarios(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'horarios/'+id+'.json');
	}

	updateHorarios(data:Horarios){
		 return this.http.patch(Globals.BASE_URL_API_REST+'horarios/'+data.id+'.json',data);
	}

	viewHorarios(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'horarios/'+id+'.json');
    }

    viewHorariosPorDiaSector(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'ophorarios.json',data);
    }
    
}