import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Cobrosmultas } from '../../cobrosmulta/cobrosmultas.model';
import { CobrosmultasServices } from '../../cobrosmulta/cobrosmultas.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-edit-motivo-cobros-multas',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditCobrosMultasMotivoComponent implements OnInit {

	@Input() Cobrosmulta:Cobrosmultas;
	public formCobroMultaMotivo:FormGroup;
	userdata:any;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private servicesCobrosmultas:CobrosmultasServices,
		private Noti:NotificationServices,) { }

	ngOnInit(){
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.formCobroMultaMotivo=this.fb.group({
            motivo:[this.Cobrosmulta.motivo,Validators.compose([Validators.required,Validators.minLength(15), Validators.maxLength(50)])],
            estado:[2,Validators.compose([Validators.required])],
		});
	}

	saveCobroMultaMotivo(){
		let CobrosMultasDataModel:Cobrosmultas=this.formCobroMultaMotivo.value;
		CobrosMultasDataModel.id=this.Cobrosmulta.id;
        this.Cobrosmulta.motivo=CobrosMultasDataModel.motivo;
		this.Cobrosmulta.estado=CobrosMultasDataModel.estado;
		
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				this.Cobrosmulta.administrativo_id=datadmin.administrativo.id;
				CobrosMultasDataModel.administrativo_id=datadmin.administrativo.id;
				this.servicesCobrosmultas.updateCobrosmultas(CobrosMultasDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.activeModal.close(1);
							}
						});
					}else{
						this.Noti.setNotification(data);
					}
				});
			});
		}
	}

}