import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-home-page-header',
  templateUrl: './home-page-header.component.html',
  styleUrls: ['./home-page-header.component.scss']
})
export class HomePageHeaderComponent implements AfterViewInit, OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){   
    var URL = window.URL;
    var image = $('#btnMenu');

    image.click(function(){
      $('#navbarSupportedContent').toggle();
    });
  }

}
