import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPrestamoComponent } from './edit/editprestamo.component';
import { AddPrestamoComponent } from './add/addprestamo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule
	],
	declarations:[EditPrestamoComponent,AddPrestamoComponent],
	entryComponents:[EditPrestamoComponent,AddPrestamoComponent],
})
export class ModalPrestamoModule { }
