import { Component } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import {Users} from '../../appauth/user-pages/user/users.model';
import {AuthServices} from '../../appauth/auth/auth.services';
import {localStorageServices} from '../../appauth/auth/localStorageServices';
import {TokenParams} from '../../global/tokenParams';
import { Router } from '@angular/router';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';

const password = new FormControl('', Validators.required);

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {

  smslogin:string="";
  email: string;
  password: string;
  public form: FormGroup;
  public formRecover: FormGroup;
  tokenParam:TokenParams;
  status:boolean=false;
  loginform = true;
  recoverform = false;

  

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private servicio:AuthServices,
    private localdata:localStorageServices,
    config: NgbProgressbarConfig
  ) { 
    config.striped = true;
    config.animated = true;
  }

  ngOnInit(){

    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      password: password,
    });

    this.formRecover = this.fb.group({
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
    });
    
  }

  login() {

    if (this.form.invalid) {
      return;
    }
    
    this.smslogin="";
    this.status=true;
    let dataUser:Users={password:this.form.value.password,username:this.form.value.email};
   
    this.servicio.login(dataUser).subscribe(
      (auth:any) => {
          this.tokenParam=auth; 
          this.smslogin=this.tokenParam.msg.error;
          if(this.tokenParam.datos){
            this.localdata.setAuthorizationData(auth);
            this.router.navigate(['home']);
          }
          else {
            this.servicio.AccessToken="";
            this.status=false;
          }
      }    
    );

  }

  forgotpassword(){

    if (this.formRecover.invalid) {
      return;
    }

    this.smslogin="";
    this.status=true;
    let dataUser:Users={username:this.formRecover.value.email};
    this.servicio.recoverPassword(dataUser).subscribe((data:any)=>{
      this.smslogin=data.msg.error;
      if(data.estado){
        this.loginform = !this.loginform;
        this.recoverform = !this.recoverform;
      }
      else {
        this.status=false;
      }
      
    });

    

  }

  showRecoverForm() {
    this.loginform = !this.loginform;
    this.recoverform = !this.recoverform;
  }
	
}
