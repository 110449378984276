import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actividades } from '../../actividade/actividades.model';
import { ActividadesServices } from '../../actividade/actividades.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { MultasperiodosServices } from '../../../multasperiodos/multasperiodo/multasperiodos.services';
import * as moment from 'moment';

@Component({
	selector: 'app-editactividade',
	templateUrl: './editactividade.component.html',
	styleUrls: ['./editactividade.component.scss']
})

export class EditActividadeComponent implements OnInit {

	@Input() Actividade:Actividades;
	public dataMultasperiodos={'id':0} as Multasperiodos;
	public multasperiodosData:any;

	public formActividadeFecha:FormGroup;
	public editFecha:boolean=false;

	public formActividadeDetalle:FormGroup;
	public editDetalle:boolean=false;

	public formActividadeTotal:FormGroup;
	public editTotal:boolean=false;

	public formActividadeMultasperiodo:FormGroup;
	public editMultasperiodo:boolean=false;

	public formActividadeEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioActividade:ActividadesServices,
		private servicioMultasperiodo:MultasperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMultasperiodo.getDataMultasperiodos().subscribe((data:any)=>{
			this.multasperiodosData=data.multasperiodos;
			this.dataMultasperiodos=this.multasperiodosData.find(obj => obj.id == this.Actividade.multasperiodo_id);
		});

		
		this.formActividadeFecha=this.fb.group({
			fecha:[moment(this.Actividade.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		this.formActividadeDetalle=this.fb.group({
			detalle:[this.Actividade.detalle,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});


		this.formActividadeMultasperiodo=this.fb.group({
			multasperiodo_id:[this.Actividade.multasperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editActividadeFechaEnable(){
		this.editFecha=true;
		this.formActividadeFecha=this.fb.group({
			fecha:[moment(this.Actividade.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editActividadeFechaDisable(){
		this.editFecha=false;
	}

	saveActividadeFecha(){
		let ActividadeDataModel:Actividades=this.formActividadeFecha.value;
		ActividadeDataModel.id=this.Actividade.id;
		this.Actividade.fecha=ActividadeDataModel.fecha;
		this.servicioActividade.updateActividades(ActividadeDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editActividadeDetalleEnable(){
		this.editDetalle=true;
		this.formActividadeDetalle=this.fb.group({
			detalle:[this.Actividade.detalle,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editActividadeDetalleDisable(){
		this.editDetalle=false;
	}

	saveActividadeDetalle(){
		let ActividadeDataModel:Actividades=this.formActividadeDetalle.value;
		ActividadeDataModel.id=this.Actividade.id;
		this.Actividade.detalle=ActividadeDataModel.detalle;
		this.servicioActividade.updateActividades(ActividadeDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editDetalle=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editActividadeMultasperiodoEnable(){
		this.editMultasperiodo=true;
		this.formActividadeMultasperiodo=this.fb.group({
			multasperiodo_id:[this.Actividade.multasperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editActividadeMultasperiodoDisable(){
		this.editMultasperiodo=false;
	}

	saveActividadeMultasperiodo(){
		let ActividadeDataModel:Actividades=this.formActividadeMultasperiodo.value;
		ActividadeDataModel.id=this.Actividade.id;
		this.Actividade.multasperiodo_id=ActividadeDataModel.multasperiodo_id;
		this.servicioActividade.updateActividades(ActividadeDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMultasperiodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}
	
	cerrarModal(){
		this.activeModal.close(2);
	}

}

