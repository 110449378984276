import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Pagoprestamos} from './pagoprestamos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class PagoprestamosServices {

	constructor(private http: HttpClient) { }

	getDataPagoprestamos():Observable<Pagoprestamos[]>{
		return this.http.get<Pagoprestamos[]>(Globals.BASE_URL_API_REST+'pagoprestamos.json');
	}

	savePagoprestamos(data:Pagoprestamos){
		return this.http.post(Globals.BASE_URL_API_REST+'pagoprestamos.json',data);
	}

	deletePagoprestamos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'pagoprestamos/'+id+'.json');
	}

	updatePagoprestamos(data:Pagoprestamos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'pagoprestamos/'+data.id+'.json',data);
	}

	viewPagoprestamos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'pagoprestamos/'+id+'.json');
	}
}