import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subperiodos } from '../../subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../subperiodo/subperiodos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Periodos } from '../../../periodos/periodo/periodos.model';
import { PeriodosServices } from '../../../periodos/periodo/periodos.services';
import * as moment from 'moment';

@Component({
	selector: 'app-editsubperiodo',
	templateUrl: './editsubperiodo.component.html',
	styleUrls: ['./editsubperiodo.component.scss']
})

export class EditSubperiodoComponent implements OnInit {

	@Input() Subperiodo:Subperiodos;
	public dataPeriodos={'nombre':'vacio'} as Periodos;
	public periodosData:any;

	public formSubperiodoNombre:FormGroup;
	public editNombre:boolean=false;

	public formSubperiodoFechainicio:FormGroup;
	public editFechainicio:boolean=false;

	public formSubperiodoFechafin:FormGroup;
	public editFechafin:boolean=false;

	public formSubperiodoCapital:FormGroup;
	public editCapital:boolean=false;

	public formSubperiodoMontomax:FormGroup;
	public editMontomax:boolean=false;

	public formSubperiodoMontomin:FormGroup;
	public editMontomin:boolean=false;

	public formSubperiodoPeriodo:FormGroup;
	public editPeriodo:boolean=false;

	public formSubperiodoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioSubperiodo:SubperiodosServices,
		private servicioPeriodo:PeriodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPeriodo.getDataPeriodos().subscribe((data:any)=>{
			this.periodosData=data.periodos;
			this.dataPeriodos=this.periodosData.find(obj => obj.id == this.Subperiodo.periodo_id);
		});

		this.formSubperiodoNombre=this.fb.group({
			nombre:[this.Subperiodo.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});

		
		this.formSubperiodoFechainicio=this.fb.group({
			fechainicio:[moment(this.Subperiodo.fechainicio).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
		
		
		this.formSubperiodoFechafin=this.fb.group({
			fechafin:[moment(this.Subperiodo.fechafin).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});


		this.formSubperiodoPeriodo=this.fb.group({
			periodo_id:[this.Subperiodo.periodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editSubperiodoNombreEnable(){
		this.editNombre=true;
		this.formSubperiodoNombre=this.fb.group({
			nombre:[this.Subperiodo.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editSubperiodoNombreDisable(){
		this.editNombre=false;
	}

	saveSubperiodoNombre(){
		let SubperiodoDataModel:Subperiodos=this.formSubperiodoNombre.value;
		SubperiodoDataModel.id=this.Subperiodo.id;
		this.Subperiodo.nombre=SubperiodoDataModel.nombre;
		this.servicioSubperiodo.updateSubperiodos(SubperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSubperiodoFechainicioEnable(){
		this.editFechainicio=true;
		
		this.formSubperiodoFechainicio=this.fb.group({
			fechainicio:[moment(this.Subperiodo.fechainicio).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editSubperiodoFechainicioDisable(){
		this.editFechainicio=false;
	}

	saveSubperiodoFechainicio(){
		let SubperiodoDataModel:Subperiodos=this.formSubperiodoFechainicio.value;
		SubperiodoDataModel.id=this.Subperiodo.id;
		this.Subperiodo.fechainicio=SubperiodoDataModel.fechainicio;
		this.servicioSubperiodo.updateSubperiodos(SubperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFechainicio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSubperiodoFechafinEnable(){
		this.editFechafin=true;
		
		this.formSubperiodoFechafin=this.fb.group({
			fechafin:[moment(this.Subperiodo.fechafin).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editSubperiodoFechafinDisable(){
		this.editFechafin=false;
	}

	saveSubperiodoFechafin(){
		let SubperiodoDataModel:Subperiodos=this.formSubperiodoFechafin.value;
		SubperiodoDataModel.id=this.Subperiodo.id;
		this.Subperiodo.fechafin=SubperiodoDataModel.fechafin;
		this.servicioSubperiodo.updateSubperiodos(SubperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFechafin=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}




	editSubperiodoPeriodoEnable(){
		this.editPeriodo=true;
		this.formSubperiodoPeriodo=this.fb.group({
			periodo_id:[this.Subperiodo.periodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editSubperiodoPeriodoDisable(){
		this.editPeriodo=false;
	}

	saveSubperiodoPeriodo(){
		let SubperiodoDataModel:Subperiodos=this.formSubperiodoPeriodo.value;
		SubperiodoDataModel.id=this.Subperiodo.id;
		this.Subperiodo.periodo_id=SubperiodoDataModel.periodo_id;
		this.servicioSubperiodo.updateSubperiodos(SubperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPeriodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

