import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Reportes } from './reporte.model';
import { Globals } from '../../global/global.model';
import { Actividades } from '../actividades/actividade/actividades.model';
import { Eventos } from '../eventos/evento/eventos.model';
import { Solicitudprestamos } from '../solicitudprestamos/solicitudprestamo/solicitudprestamos.model';
import { Subperiodos } from '../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class ReportesServices {

    constructor(private http: HttpClient) {}

    generatePdfSocios(){
        return this.http.post(Globals.BASE_URL_API_REST+'reportes.json',{});
    }

    generatePdfMultas(data:Actividades){
        return this.http.put(Globals.BASE_URL_API_REST+'reportes.json',data);
    }
   
    generatePdfAportes(data:Eventos){
        return this.http.patch(Globals.BASE_URL_API_REST+'reportes.json',data);
    }

    generatePdfSolicitudes(){
        return this.http.post(Globals.BASE_URL_API_REST+'reportesprestamos.json',{});
    }

    generatePdfPrestamos(){
        return this.http.put(Globals.BASE_URL_API_REST+'reportesprestamos.json',{});
    }

    generateReciboMultasPdf(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'recibos.json',data);
    }
    
    generateReciboCuotasPdf(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'recibos.json',data);
    }

    generateReciboPagosPdf(data:any){
        return this.http.patch(Globals.BASE_URL_API_REST+'recibos.json',data);
    }

    generateReciboPagosCuotasPrestamosPdf(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'recibosprestamos.json',data);
    }

    generateInformeMultasPDF(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'recibosprestamos.json',data);
    }

    generateInformeAportesPDF(data:any){
        return this.http.patch(Globals.BASE_URL_API_REST+'recibosprestamos.json',data);
    }

    generateInformeCuotasPDF(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'informesfechas.json',data);
    }
    generateInformeCuotasPrestamoPDF(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'informesfechas.json',data);
    }

    generateInformeAportesGeneralesPDF(data:any){
        return this.http.patch(Globals.BASE_URL_API_REST+'listado_aportes_general.json',data);
    }
    
    //consultas

    generateInformeMultas(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'reportemultasvista.json',data);
    }

    generateInformeAportes(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'reporteaportesvista.json',data);
    }

    generateInformeAportesGeneral(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'reporteaportesvista.json',data);
    }

    generateInformeCuotas(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'reportecuotasvista.json',data);
    }

    generateInformePrestamos(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'reporteprestamosvista.json',data);
    }

    //consultas

    generateConsultaPrestamosPdf(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'consultas.json',data);
    }

    generateConsultaSolicitudesPDF(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'consultas.json',data);
    }

    generateConsultaSociosPDF(data:any){
        return this.http.patch(Globals.BASE_URL_API_REST+'consultas.json',data);
    }

    generateConsultaEstadoCuentaSociosPDF(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'estadocuentas.json',data);
    }

    generateConsultaPeriodosAportesPDF(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'estadocuentas.json',data);
    }

    generateConsultaPeriodosMultasPDF(data:any){
        return this.http.patch(Globals.BASE_URL_API_REST+'estadocuentas.json',data);
    }

    //horarios

    generateHorariosSociosPDF(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'oprhorarios.json',data);
    }

    generateHorariosSociosEsquelaPDF(data:any){
        return this.http.put(Globals.BASE_URL_API_REST+'oprhorarios.json',data);
    }

    generateAsistenciaPDF(data:any){
        return this.http.post(Globals.BASE_URL_API_REST+'opasistencias.json',data);
    }

    // Gastos
    viewGastosPorSubPeriodo(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_gastos/'+id+'.json');
    }
    
    generatePdfBeneficiarios(){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_beneficiarios.json');
    }

    viewGastosPagosGenerales(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_gastos_generales/'+id+'.json');
    }

    viewGastosResumenGeneral(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_resumenes/'+id+'.json');
    }

    viewGastosResumenGeneralAportes(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_resumenes_aportes/'+id+'.json');
    }

    viewGastosResumenGeneralMultas(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_resumenes_multas/'+id+'.json');
    }

    viewGastosResumenGeneralGastos(id:number){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_resumenes_gastos/'+id+'.json');
    }

    generatePdfSociosGenerales(){
		return this.http.get(Globals.BASE_URL_API_REST+'listado_general_deudas.json');
    }

    viewMorososresmunes(){
        return this.http.get(Globals.BASE_URL_API_REST+'listado_general_ticket_deuda_resumen.json');
    }

    viewMorososResmuneSector(id:number){
        return this.http.get(Globals.BASE_URL_API_REST+'listado_general_sectores_deudas/'+id+'.json');
    }

}