export class Actividades{
	id?:number;
	fecha?:Date;
	detalle?:string;
	total?:any;
	tipo?:number;
	multasperiodo_id?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
}