import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPagoComponent } from './edit/editpago.component';
import { AddPagoComponent } from './add/addpago.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EditPagoMotivoComponent } from './change/changemotivo.component';
import { PagosPagarComponent } from './pagar/pagar.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from  'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[EditPagoComponent,AddPagoComponent,EditPagoMotivoComponent,PagosPagarComponent],
	entryComponents:[EditPagoComponent,AddPagoComponent,EditPagoMotivoComponent,PagosPagarComponent],
})
export class ModalPagoModule { }
