import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditCobrosmultaComponent } from './edit/editcobrosmulta.component';
import { AddCobrosmultaComponent } from './add/addcobrosmulta.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EditCobrosMultasMotivoComponent } from './change/changemotivo.component';
import { CobrosmultaPagarComponent } from './pagar/pagar.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ImprimirMultasPagosComponent } from './imprimir/imprimir.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from  'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		NgxDatatableModule,
		ReactiveFormsModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[EditCobrosmultaComponent,AddCobrosmultaComponent,EditCobrosMultasMotivoComponent,CobrosmultaPagarComponent,ImprimirMultasPagosComponent],
	entryComponents:[EditCobrosmultaComponent,AddCobrosmultaComponent,EditCobrosMultasMotivoComponent,CobrosmultaPagarComponent,ImprimirMultasPagosComponent],
})
export class ModalCobrosmultaModule { }
