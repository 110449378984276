import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotoSociosComponent } from './foto/fotosocio.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[FotoSociosComponent],
	entryComponents:[FotoSociosComponent],
})
export class ModalFotoSociosModule { }
