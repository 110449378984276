import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Eventos } from '../../evento/eventos.model';
import { EventosServices } from '../../evento/eventos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { AportesperiodosServices } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.services';
import * as moment from 'moment';

@Component({
	selector: 'app-editevento',
	templateUrl: './editevento.component.html',
	styleUrls: ['./editevento.component.scss']
})

export class EditEventoComponent implements OnInit {

	@Input() Evento:Eventos;
	public dataAportesperiodos={'id':0} as Aportesperiodos;
	public aportesperiodosData:any;
	public tipoData:number;

	public formEventoFecha:FormGroup;
	public editFecha:boolean=false;

	public formEventoDetalle:FormGroup;
	public editDetalle:boolean=false;

	public formEventoTotal:FormGroup;
	public editTotal:boolean=false;

	public formEventoTipo:FormGroup;
	public editTipo:boolean=false;

	public formEventoAportesperiodo:FormGroup;
	public editAportesperiodo:boolean=false;

	public formEventoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEvento:EventosServices,
		private servicioAportesperiodo:AportesperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioAportesperiodo.getDataAportesperiodos().subscribe((data:any)=>{
			this.aportesperiodosData=data.aportesperiodos;
			this.dataAportesperiodos=this.aportesperiodosData.find(obj => obj.id == this.Evento.aportesperiodo_id);
		});

		this.formEventoFecha=this.fb.group({
			fecha:[moment(this.Evento.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		this.formEventoDetalle=this.fb.group({
			detalle:[this.Evento.detalle,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});


		this.formEventoTipo=this.fb.group({
			tipo:[this.Evento.tipo,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formEventoAportesperiodo=this.fb.group({
			aportesperiodo_id:[this.Evento.aportesperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editEventoFechaEnable(){
		this.editFecha=true;
		this.formEventoFecha=this.fb.group({
			fecha:[moment(this.Evento.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editEventoFechaDisable(){
		this.editFecha=false;
	}

	saveEventoFecha(){
		let EventoDataModel:Eventos=this.formEventoFecha.value;
		EventoDataModel.id=this.Evento.id;
		this.Evento.fecha=EventoDataModel.fecha;
		this.servicioEvento.updateEventos(EventoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editEventoDetalleEnable(){
		this.editDetalle=true;
		this.formEventoDetalle=this.fb.group({
			detalle:[this.Evento.detalle,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editEventoDetalleDisable(){
		this.editDetalle=false;
	}

	saveEventoDetalle(){
		let EventoDataModel:Eventos=this.formEventoDetalle.value;
		EventoDataModel.id=this.Evento.id;
		this.Evento.detalle=EventoDataModel.detalle;
		this.servicioEvento.updateEventos(EventoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editDetalle=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}



	editEventoTipoEnable(){
		this.editTipo=true;
		this.formEventoTipo=this.fb.group({
			tipo:[this.Evento.tipo,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEventoTipoDisable(){
		this.editTipo=false;
	}

	saveEventoTipo(){
		let EventoDataModel:Eventos=this.formEventoTipo.value;
		EventoDataModel.tipo=this.tipoData;
		EventoDataModel.id=this.Evento.id;
		this.Evento.tipo=EventoDataModel.tipo;
		this.servicioEvento.updateEventos(EventoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTipo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editEventoAportesperiodoEnable(){
		this.editAportesperiodo=true;
		this.formEventoAportesperiodo=this.fb.group({
			aportesperiodo_id:[this.Evento.aportesperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editEventoAportesperiodoDisable(){
		this.editAportesperiodo=false;
	}

	saveEventoAportesperiodo(){
		let EventoDataModel:Eventos=this.formEventoAportesperiodo.value;
		EventoDataModel.id=this.Evento.id;
		this.Evento.aportesperiodo_id=EventoDataModel.aportesperiodo_id;
		this.servicioEvento.updateEventos(EventoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editAportesperiodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	saveEventoTipoValue(val:number){
		this.tipoData=val;
		console.log(this.tipoData);
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

