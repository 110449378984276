import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Gastosperiodos } from '../../gastosperiodo/gastosperiodos.model';
import { GastosperiodosServices } from '../../gastosperiodo/gastosperiodos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Gastos } from '../../../gastos/gasto/gastos.model';
import { GastosServices } from '../../../gastos/gasto/gastos.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-editgastosperiodo',
	templateUrl: './editgastosperiodo.component.html',
	styleUrls: ['./editgastosperiodo.component.scss']
})

export class EditGastosperiodoComponent implements OnInit {

	@Input() Gastosperiodo:Gastosperiodos;
	public dataGastos={'motivo':'vacio'} as Gastos;
	public gastosData:any;
	public dataSubperiodos={'nombre':'vacio'} as Subperiodos;
	public subperiodosData:any;

	public formGastosperiodoMontogasto:FormGroup;
	public editMontogasto:boolean=false;

	public formGastosperiodoGasto:FormGroup;
	public editGasto:boolean=false;

	public formGastosperiodoSubperiodo:FormGroup;
	public editSubperiodo:boolean=false;

	public formGastosperiodoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioGastosperiodo:GastosperiodosServices,
		private servicioGasto:GastosServices,
		private servicioSubperiodo:SubperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioGasto.getDataGastos(1).subscribe((data:any)=>{
			this.gastosData=data.gastos;
			this.dataGastos=this.gastosData.find(obj => obj.id == this.Gastosperiodo.gasto_id);
		});
		this.servicioSubperiodo.getDataSubperiodos().subscribe((data:any)=>{
			this.subperiodosData=data.subperiodos;
			this.dataSubperiodos=this.subperiodosData.find(obj => obj.id == this.Gastosperiodo.subperiodo_id);
		});


		this.formGastosperiodoGasto=this.fb.group({
			gasto_id:[this.Gastosperiodo.gasto_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formGastosperiodoSubperiodo=this.fb.group({
			subperiodo_id:[this.Gastosperiodo.subperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editGastosperiodoGastoEnable(){
		this.editGasto=true;
		this.formGastosperiodoGasto=this.fb.group({
			gasto_id:[this.Gastosperiodo.gasto_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editGastosperiodoGastoDisable(){
		this.editGasto=false;
	}

	saveGastosperiodoGasto(){
		let GastosperiodoDataModel:Gastosperiodos=this.formGastosperiodoGasto.value;
		GastosperiodoDataModel.id=this.Gastosperiodo.id;
		this.Gastosperiodo.gasto_id=GastosperiodoDataModel.gasto_id;
		this.servicioGastosperiodo.updateGastosperiodos(GastosperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editGasto=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editGastosperiodoSubperiodoEnable(){
		this.editSubperiodo=true;
		this.formGastosperiodoSubperiodo=this.fb.group({
			subperiodo_id:[this.Gastosperiodo.subperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editGastosperiodoSubperiodoDisable(){
		this.editSubperiodo=false;
	}

	saveGastosperiodoSubperiodo(){
		let GastosperiodoDataModel:Gastosperiodos=this.formGastosperiodoSubperiodo.value;
		GastosperiodoDataModel.id=this.Gastosperiodo.id;
		this.Gastosperiodo.subperiodo_id=GastosperiodoDataModel.subperiodo_id;
		this.servicioGastosperiodo.updateGastosperiodos(GastosperiodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editSubperiodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

