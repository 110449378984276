import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Cobrosmultas} from './cobrosmultas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class CobrosmultasServices {

	constructor(private http: HttpClient) { }

	getDataCobrosmultas():Observable<Cobrosmultas[]>{
		return this.http.get<Cobrosmultas[]>(Globals.BASE_URL_API_REST+'cobrosmultas.json');
	}

	saveCobrosmultas(data:Cobrosmultas){
		return this.http.post(Globals.BASE_URL_API_REST+'cobrosmultas.json',data);
	}

	deleteCobrosmultas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'cobrosmultas/'+id+'.json');
	}

	updateCobrosmultas(data:Cobrosmultas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'cobrosmultas/'+data.id+'.json',data);
	}

	viewCobrosmultas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'cobrosmultas/'+id+'.json');
	}

	printerMulta(data:any){
		let headers = new HttpHeaders().set('Authorization','LocalPrinter ');
		return this.http.post('https://'+data.ip+':6633/multas',data.data,{ headers: headers });
	}
}