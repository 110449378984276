import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPagogastoComponent } from './edit/editpagogasto.component';
import { AddPagogastoComponent } from './add/addpagogasto.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AddSancionPagogastoEventoComponent } from './sancion/sancion.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { EditPagoGastosMotivoComponent } from './change/changemotivo.component';
import { GastoPagosPagarComponent } from './pagar/pagar.component';
@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxUiLoaderModule
	],
	declarations:[EditPagogastoComponent,AddPagogastoComponent,AddSancionPagogastoEventoComponent,EditPagoGastosMotivoComponent,GastoPagosPagarComponent],
	entryComponents:[EditPagogastoComponent,AddPagogastoComponent,AddSancionPagogastoEventoComponent,EditPagoGastosMotivoComponent,GastoPagosPagarComponent],
})
export class ModalPagogastoModule { }
