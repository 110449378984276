import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Resumenes, Tiporesumenes} from './resumenes.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';

@Injectable()
export class ResumenesServices {

	constructor(private http: HttpClient) { }

	getDataResumenes(page:number):Observable<Resumenes[]>{
		return this.http.get<Resumenes[]>(Globals.BASE_URL_API_REST+'resumenes.json?page='+page);
	}

	saveResumenes(data:Resumenes){
		return this.http.post(Globals.BASE_URL_API_REST+'resumenes.json',data);
	}

	deleteResumenes(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'resumenes/'+id+'.json');
	}

	updateResumenes(data:Resumenes){
		 return this.http.patch(Globals.BASE_URL_API_REST+'resumenes/'+data.id+'.json',data);
	}

	viewResumenes(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'resumenes/'+id+'.json');
	}

	viewResumenesSubperiodo(data:Subperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'opresumenes.json',data);
	}

	viewResumenesTiporesumen(data:Tiporesumenes){
		return this.http.post(Globals.BASE_URL_API_REST+'optiporesumenes.json',data);
	}

}