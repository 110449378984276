import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Solicitudprestamos } from '../../solicitudprestamo/solicitudprestamos.model';
import { SolicitudprestamosServices } from '../../solicitudprestamo/solicitudprestamos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { SolicitudPrestamoServices } from '../../../solicitudcreditos/solicitudes/solicitudes.services';
import { NgxUiLoaderService,SPINNER } from 'ngx-ui-loader';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-editsolicitudprestamo',
	templateUrl: './editsolicitudprestamo.component.html',
	styleUrls: ['./editsolicitudprestamo.component.scss']
})

export class EditSolicitudprestamoComponent implements OnInit {

	@Input() Solicitudprestamo:Solicitudprestamos;
	@Input() Subperiodo:any;
	inconoLoder:string=SPINNER.wanderingCubes;
	
	public formSolicitudprestamoPlazomeses:FormGroup;
	public editPlazomeses:boolean=false;

	public formSolicitudprestamoMonto:FormGroup;
	public editMonto:boolean=false;

	public formSolicitudprestamoEstado:FormGroup;
	public editEstado:boolean=false;

	public imgUrlSocios:string;
	userdata:any;
	
	montoDisponible:number=0;
	montoSolicitado:number=0;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioSolicitudprestamo:SolicitudprestamosServices,
		private validatciones:customValidatorMondogo,
		private servicioAdministrativo:AdministrativosServices,
		private solicitudServices:SolicitudPrestamoServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
		this.ngxService.startLoader('loaderEditSolicitud');
		this.seleccionarCapitalMotoSolicitado().then(
			result=>{
				console.log(result);
				this.ngxService.stopLoader('loaderEditSolicitud');
			},
			reason=>{
				console.log(reason);
			}
		);
		
		
		this.formSolicitudprestamoPlazomeses=this.fb.group({
			plazomeses:[this.Solicitudprestamo.plazomeses,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(2,0)])],
		});

		this.formSolicitudprestamoMonto=this.fb.group({
			monto:[this.Solicitudprestamo.monto,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,0)])],
		});
	}
	

	seleccionarCapitalMotoSolicitado(){
		let promise = new Promise((resolve, reject) => {
			this.montoDisponible=0;
			this.montoSolicitado=0;
			this.solicitudServices.getDataSociosSuma(this.Subperiodo).subscribe((data:any)=>{
				this.Subperiodo=data.suma;
				if(this.Subperiodo.solicitudprestamos.length>0){
					//monto prestamos
					this.solicitudServices.getDataSociosSumaPrestamosPendientes(this.Subperiodo).subscribe((dataSumaPrestamo:any)=>{
						if(dataSumaPrestamo.suma.prestamos.length>0){
							this.montoSolicitado=(this.Subperiodo.solicitudprestamos[0].total+dataSumaPrestamo.suma.prestamos[0].total)-this.Solicitudprestamo.monto;
							this.montoDisponible=this.Subperiodo.capital-this.montoSolicitado;
							
							if(this.Subperiodo.montomin<this.montoDisponible && this.Subperiodo.montomax>this.montoDisponible){
								this.Subperiodo.montomax=this.montoDisponible;
								resolve(true);
							}else{
								resolve(true);
							}
							this.ngxService.stopLoader('loaderEditSolicitud');
						}else{

							this.montoSolicitado=(this.Subperiodo.solicitudprestamos[0].total)-this.Solicitudprestamo.monto;
							this.montoDisponible=this.Subperiodo.capital-this.montoSolicitado;

							if(this.Subperiodo.montomin<this.montoDisponible && this.Subperiodo.montomax>this.montoDisponible){
								this.Subperiodo.montomax=this.montoDisponible;
								resolve(true);
							}else{
								resolve(true);
							}
							this.ngxService.stopLoader('loaderEditSolicitud');
						}
						
					});
				}else{
					resolve(true);
				}
				
			});
	
		});
		return promise;
	}

	editSolicitudprestamoPlazomesesEnable(){
		this.editPlazomeses=true;
		this.formSolicitudprestamoPlazomeses=this.fb.group({
			plazomeses:[this.Solicitudprestamo.plazomeses,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(2,0)])],
		});
	}

	editSolicitudprestamoPlazomesesDisable(){
		this.editPlazomeses=false;
	}

	saveSolicitudprestamoPlazomeses(){
		if(this.formSolicitudprestamoPlazomeses.value.plazomeses<=0){
			let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'Los meses deben ser mayor que <code>0</code>'}};
			this.Noti.setNotification(smsFail);
		}else {
			this.ngxService.startLoader('loaderEditSolicitud');
			this.servicioAdministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				let SolicitudprestamoDataModel:Solicitudprestamos=this.formSolicitudprestamoPlazomeses.value;
				SolicitudprestamoDataModel.id=this.Solicitudprestamo.id;
				SolicitudprestamoDataModel.administrativo_id=datadmin.administrativo.id;
				this.Solicitudprestamo.plazomeses=SolicitudprestamoDataModel.plazomeses;
				this.Solicitudprestamo.administrativo_id=SolicitudprestamoDataModel.administrativo_id;
				this.servicioSolicitudprestamo.updateSolicitudprestamos(SolicitudprestamoDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.editPlazomeses=false;
								this.ngxService.stopLoader('loaderEditSolicitud');
							}
						});
					}else{
						this.Noti.setNotification(data);
					}
				});
			});
		}
	}


	editSolicitudprestamoMontoEnable(){
		this.editMonto=true;
		this.formSolicitudprestamoMonto=this.fb.group({
			monto:[this.Solicitudprestamo.monto,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,0)])],
		});
	}

	editSolicitudprestamoMontoDisable(){
		this.editMonto=false;
	}

	saveSolicitudprestamoMonto(){
		this.ngxService.startLoader('loaderEditSolicitud');
		this.seleccionarCapitalMotoSolicitado().then(
			result=>{
				if(result){
					
					if(this.formSolicitudprestamoMonto.value.monto>(this.montoDisponible)){
						let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto del prestamo no debe ser mayor que <code>'+(this.montoDisponible)+'</code>'}};
						this.Noti.setNotification(smsFail);
						this.ngxService.stopLoader('loaderEditSolicitud');
						//this.montoDisponible=this.montoDisponible-this.Solicitudprestamo.monto;
					}else{
						if(this.formSolicitudprestamoMonto.value.monto<this.Subperiodo.montomin){
							let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto de ser mayor que <code>'+this.Subperiodo.montomin+'</code>'}};
							this.Noti.setNotification(smsFail);
							this.ngxService.stopLoader('loaderEditSolicitud');
						}else if (this.formSolicitudprestamoMonto.value.monto>this.Subperiodo.montomax) {
							let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto de ser menor que <code>'+this.Subperiodo.montomax+'</code>'}};
							this.Noti.setNotification(smsFail);
							this.ngxService.stopLoader('loaderEditSolicitud');
						} else {
							
							this.servicioAdministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
								let SolicitudprestamoDataModel:Solicitudprestamos=this.formSolicitudprestamoMonto.value;
								SolicitudprestamoDataModel.id=this.Solicitudprestamo.id;
								SolicitudprestamoDataModel.administrativo_id=datadmin.administrativo.id;
								this.Solicitudprestamo.monto=SolicitudprestamoDataModel.monto;
								this.Solicitudprestamo.administrativo_id=SolicitudprestamoDataModel.administrativo_id;
								this.servicioSolicitudprestamo.updateSolicitudprestamos(SolicitudprestamoDataModel).subscribe((data:any)=>{
									if(data.estado){
										this.Noti.setNotification(data).then((datas)=>{
											if(datas){
												this.editMonto=false;
												this.montoDisponible=this.montoDisponible-this.formSolicitudprestamoMonto.value.monto;
												this.Subperiodo.montomax=this.montoDisponible;
												this.ngxService.stopLoader('loaderEditSolicitud');
											}
										});
									}else{
										this.Noti.setNotification(data);
									}
								});
							});
						}
					}
				}
				
				this.ngxService.stopLoader('loaderEditSolicitud');
			},
			reason=>{
				console.log(reason);
			}
		);
	}

}

