import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Aportes } from '../../aporte/aportes.model';
import { AportesServices } from '../../aporte/aportes.services';

@Component({
	selector: 'app-addaporte',
	templateUrl: './addstaticaporte.component.html',
	styleUrls: ['./addstaticaporte.component.scss']
})

export class AddAporteStaticComponent implements OnInit {
	public formAddAporte: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioAporte:AportesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddAporte = this.fb.group({
			motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			tipo:[1,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let aporteDataModel:Aportes=this.formAddAporte.value;
		this.servicioAporte.saveAportes(aporteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}