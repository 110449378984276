import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditGastoComponent } from './edit/editgasto.component';
import { AddGastoComponent } from './add/addgasto.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddGastoReguistoComponent } from './reguistro/reguistro.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgbModule,
		NgxUiLoaderModule,
	],
	declarations:[EditGastoComponent,AddGastoComponent,AddGastoReguistoComponent],
	entryComponents:[EditGastoComponent,AddGastoComponent,AddGastoReguistoComponent],
})
export class ModalGastoModule { }
