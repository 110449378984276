import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportesServices } from '../../../reporte/reportes.services';
import { Pagogastos } from '../../pagogasto/pagogastos.model';
import { BeneficiariosServices } from '../../../beneficiarios/beneficiario/beneficiarios.services';
import { PagogastosServices } from '../../pagogasto/pagogastos.services';

@Component({
	selector: 'app-pagos-pagar',
	templateUrl: './pagar.component.html',
	styleUrls: ['./pagar.component.scss']
})

export class GastoPagosPagarComponent implements OnInit {

    @Input() Pagogasto:Pagogastos;
    @Input() General:any;
    rows = [];
    temp = [];
    estados=[];
    public imgUrlBeneficiarios:string;
    inconoLoder:string=SPINNER.wanderingCubes;

    userdata:any;
    
    config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}
    
    constructor(private activeModal:NgbActiveModal,
        public dialog: NgbModal,
        public localdata:localStorageServices,
        private ngxService: NgxUiLoaderService,
        private servicesReportes:ReportesServices,
        private servicioadministrativo:AdministrativosServices,
        private notificationServices:NotificationServices,
        private beneficiariosServices:BeneficiariosServices,
        private servicioPagoGastos:PagogastosServices
    ){}
    
    ngOnInit(){
        this.userdata=this.localdata.getValueFromLocalStorage();
        this.imgUrlBeneficiarios=Globals.BASE_URL_API_REST;   
        this.listaBeneficiarios();
    }

    listaBeneficiarios(){
		
		this.beneficiariosServices.buscarGastosBeneficiarios().subscribe((data:any)=>{
            this.rows=data.beneficiarios;
			this.temp = [...this.rows];
		});
	}

    updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return d.cedula.toLowerCase().indexOf(val) !== -1
				|| (d.nombres && d.nombres.toLowerCase().indexOf(val) !== -1)
				|| (d.apellidos && d.apellidos.toLowerCase().indexOf(val) !== -1)
				|| !val;
		});
		this.rows = temp;
	}
    
    pagar(dataMulta:Pagogastos){
		let dataPagogastos= {} as Pagogastos;
		let dataconfirmar={'val':'$ '+this.General.montogasto,'msg':'Pagar gasto de '+this.Pagogasto.detalle};
        let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'Inicie sesión como <code>Tesorero</code>'}};
        this.ngxService.startLoader('loaderPdfPagos');
        this.servicioPagoGastos.viewPagogastos(this.Pagogasto.id).subscribe((dataPagogasto:any)=>{
            if(dataPagogasto.pagogasto.estado==0){
                if(this.userdata.datos.socio == undefined){
                    this.notificationServices.setNotification(smsFail);
                }else{
                    this.notificationServices.msgEliminarConfir(dataconfirmar).then((datas)=>{
                        if(datas){
        
                            if(this.userdata.datos.socio){
                                this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
                                    
                                    dataPagogastos.administrativo_id=datadmin.administrativo.id;
                                    dataPagogastos.estado=1;
                                    dataPagogastos.beneficiario_id=dataMulta.id;
                                    dataPagogastos.id=this.Pagogasto.id;
                                    console.log(dataPagogastos);
                                    
        
                                    this.servicioPagoGastos.updatePagogastos(dataPagogastos).subscribe((datasave:any)=>{
                                        if(datasave.estado){
                                            this.notificationServices.setNotification(datasave).then((datas)=>{
                                                if(datas){
                                                    this.ngxService.stopLoader('loaderPdfPagos');
                                                    this.activeModal.close(1);

                                                }
                                            });
                                        }else{
                                            this.ngxService.stopLoader('loaderPdfPagos');
                                            this.notificationServices.setNotification(datasave);
                                        }
                                    });
                    
                                    
                    
                                });
                            }
                        
                        }
                    });
                }
            }else{
                let estadoPagogasto="";
                if(dataPagogasto.pagogasto.estado===1){
                    estadoPagogasto="Pagado";
                }else if(dataPagogasto.pagogasto.estado===2){
                    estadoPagogasto="Anulado";
                }
                this.ngxService.stopLoader('loaderPdfPagos');
                let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El pago ya esta <code>'+estadoPagogasto+'</code>'}};
                this.notificationServices.setNotification(smsFail);
            }
        });
    }

    
}