import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Solicitudprestamos} from './solicitudprestamos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class SolicitudprestamosServices {

	constructor(private http: HttpClient) { }

	getDataSolicitudprestamos():Observable<Solicitudprestamos[]>{
		return this.http.get<Solicitudprestamos[]>(Globals.BASE_URL_API_REST+'solicitudprestamos.json');
	}

	saveSolicitudprestamos(data:Solicitudprestamos){
		return this.http.post(Globals.BASE_URL_API_REST+'solicitudprestamos.json',data);
	}

	deleteSolicitudprestamos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'solicitudprestamos/'+id+'.json');
	}

	updateSolicitudprestamos(data:any){
		 return this.http.patch(Globals.BASE_URL_API_REST+'solicitudprestamos/'+data.id+'.json',data);
	}

	viewSolicitudprestamos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'solicitudprestamos/'+id+'.json');
	}
}