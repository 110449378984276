import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { Eventos } from '../../../eventos/evento/eventos.model';
import { Pagos } from '../../pago/pagos.model';
import { PagosServices } from '../../pago/pagos.services';
import { EventosServices } from '../../../eventos/evento/eventos.services';
import { ListaCuotaPagarPagoComponent } from '../../../cuotas/modal/listapagar/listcuotaspagadas.component';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportesServices } from '../../../reporte/reportes.services';
import { ImprimirMultasPagosComponent } from '../../../cobrosmultas/modal/imprimir/imprimir.component';
import * as moment from 'moment';

@Component({
    selector: 'app-pagos-pagar',
    templateUrl: './pagar.component.html',
    styleUrls: ['./pagar.component.scss']
})

export class PagosPagarComponent implements OnInit {

    @Input() Evento: Eventos;
    rows = [];
    temp = [];
    estados = [];
    public imgUrlSocios: string;
    inconoLoder: string = SPINNER.wanderingCubes;
    ip: string = '127.0.0.1'
    userdata: any;

    config: any = {
        size: 'lg',
        centered: true,
        resolve: { datasend: null }
    }

    constructor(private activeModal: NgbActiveModal,
        private serviciosEvento: EventosServices,
        public dialog: NgbModal,
        public localdata: localStorageServices,
        private ngxService: NgxUiLoaderService,
        private servicioCobrosAportes: PagosServices,
        private servicesReportes: ReportesServices,
        private servicioadministrativo: AdministrativosServices,
        private notificationServices: NotificationServices,
    ) { }

    ngOnInit() {
        this.userdata = this.localdata.getValueFromLocalStorage();
        this.imgUrlSocios = Globals.BASE_URL_API_REST;
        this.serviciosEvento.viewEventoAporte(this.Evento).subscribe((dataactividad: any) => {
            //console.log(dataactividad);
            this.rows = dataactividad.evento.pagos;
            this.temp = [...this.rows];
            //console.log(this.rows);

        });

    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();

        const temp = this.temp.filter(d => {
            let ex: boolean = false;
            if (this.estados.length == 0)
                ex = true;

            this.estados.forEach(data => {
                if (d.estado === data) {
                    ex = true;
                }
            });

            return (d.socio.nombres.toLowerCase().indexOf(val) !== -1 && ex);
        });
        this.rows = temp;
    }

    setEstado(event) {
        let existe: boolean = false;
        let indice: number = 0;
        this.estados.forEach(function (data, index) {
            if (event.target.value == data) {
                existe = true;
                indice = index;
            }
        })

        if (existe)
            this.estados.splice(indice, 1);
        else
            this.estados.push(Number(event.target.value));


        const temp = this.temp.filter(item => {
            let ex: boolean = false;

            if (this.estados.length == 0)
                ex = true;

            this.estados.forEach(data => {
                if (item.estado === data) {
                    ex = true;
                }
            });

            return ex;

        });
        this.rows = temp;

    }

    pagar(dataMulta: any) {
        let dataPagos = {} as Pagos;
        let dataconfirmar = { 'val': '$' + dataMulta.montototal + ' ' + dataMulta.sectore.nombre + ', ' + dataMulta.socio.cedula + ' ' + dataMulta.socio.nombres + ' ' + dataMulta.socio.apellidos + ' [' + this.Evento.detalle + ']', 'msg': 'Pagar monto de ' };
        let smsFail = { 'estado': true, 'tipomsg': 'warning', 'msg': { 'ok': 'Inicie sesión como <code>Tesorero</code>' } };

        this.servicioCobrosAportes.viewPagos(dataMulta.id).subscribe((dataPago: any) => {
            if (dataPago.pago.estado == 0) {
                if (this.userdata.datos.socio == undefined) {
                    this.notificationServices.setNotification(smsFail);
                } else {
                    this.notificationServices.msgEliminarConfir(dataconfirmar).then((datas) => {
                        if (datas) {

                            if (this.userdata.datos.socio) {
                                this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {
                                    if(datadmin.administrativo.impresoras[0]!=undefined){
                                        this.ip=datadmin.administrativo.impresoras[0].ip;
                                    }

                                    dataPagos.administrativo_id = datadmin.administrativo.id;
                                    dataPagos.estado = 1;
                                    dataPagos.tipo = 1;
                                    dataPagos.id = dataMulta.id;

                                    this.servicioCobrosAportes.updatePagos(dataPagos).subscribe((datasave: any) => {
                                        if (datasave.estado) {
                                            this.notificationServices.setNotification(datasave).then((datas) => {
                                                if (datas) {
                                                let sendCod = { data: { "empresa": "AGUA DE RIEGO QUINDIALO", 
                                                "accion": "RECIBO DE PAGO EVENTO", 
                                                "codigo": "PAG-"+dataMulta.id, 
                                                "fecha": moment(datasave.datos.modified).locale('es').format('LLL'), 
                                                "valor": dataMulta.montototal,"aporte": {"subperiodo": datasave.datos.evento.aportesperiodo.subperiodo.nombre, 
                                                                                    "nombre": datasave.datos.evento.aportesperiodo.aporte.motivo, 
                                                                                    "detalle": datasave.datos.evento.detalle, 
                                                                                    "fechaEvento": moment(datasave.datos.evento.fecha).add(1, 'd').locale('es').format('LL'), 
                                                                                    "formaDePago": dataMulta.tipo }, 
                                                                        "usuario": { "identificador": dataMulta.socio.cedula, 
                                                                                    "nombre": dataMulta.socio.nombres, 
                                                                                    "apellido": dataMulta.socio.apellidos, 
                                                                                    "direccion": dataMulta.socio.direccion, 
                                                                                    "correo": dataMulta.socio.email, 
                                                                                    "telefono": dataMulta.socio.telefono, 
                                                                                    "celular": dataMulta.socio.celular },
                                                                        "sector":{"nombre":dataMulta.sectore.nombre},
                                                                        "tesorero": { "identificador": datadmin.administrativo.socio.cedula, 
                                                                                    "nombre": datadmin.administrativo.socio.nombres, 
                                                                                    "apellido": datadmin.administrativo.socio.apellidos } }, ip: this.ip } as any;
                                                    this.servicioCobrosAportes.printerAporte(sendCod).subscribe();
                                                    this.serviciosEvento.viewEventoAporte(this.Evento).subscribe((dataactividad: any) => {
                                                        this.rows = dataactividad.evento.pagos;
                                                        this.temp = [...this.rows];
                                                    });
                                                }
                                            });
                                        } else {
                                            this.notificationServices.setNotification(datasave);
                                        }
                                    });



                                });
                            }

                        }
                    });
                }
            } else {
                let estadoPago = "";
                if (dataPago.pago.estado === 1) {
                    estadoPago = "Pagado";
                } else if (dataPago.pago.estado === 2) {
                    estadoPago = "Anulado";
                }
                let smsFail = { 'estado': true, 'tipomsg': 'warning', 'msg': { 'ok': 'El pago ya esta <code>' + estadoPago + '</code>' } };
                this.notificationServices.setNotification(smsFail);
            }
        });
    }

    pagarcuotas(dataMulta: Pagos) {
        let dialogRef = this.dialog.open(ListaCuotaPagarPagoComponent, this.config);
        dialogRef.componentInstance.Pago = dataMulta;
        dialogRef.result.then(
            result => {
                if (result === 1) {

                    this.serviciosEvento.viewEventoAporte(this.Evento).subscribe((dataactividad: any) => {
                        console.log(dataactividad);
                        this.rows = dataactividad.evento.pagos;
                        this.temp = [...this.rows];
                    });
                }
            },
            reason => {
                console.log(reason);
            }
        );
    }

    imprimir(data: any) {

        this.ngxService.startLoader('loaderPdfPagos');
        this.servicesReportes.generateReciboPagosPdf(data).subscribe((datapdf: any) => {
            if (datapdf.estado) {
                this.notificationServices.setNotification(datapdf).then((datas) => {
                    if (datas) {
                        this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {
                            
                            if(datadmin){
                                if(datadmin.administrativo.impresoras.length>0){
                                    this.ip=datadmin.administrativo.impresoras[0].ip;
                                }
                                let sendCod = { data: { "empresa": "AGUA DE RIEGO QUINDIALO", 
                                                        "accion": "RECIBO DE PAGO EVENTO", 
                                                        "codigo": "PAG-"+data.id, 
                                                        "fecha": moment(data.modified).locale('es').format('LLL'), 
                                                        "valor": data.montototal,"aporte": {"subperiodo": datapdf.datos.Pagos.info.aportesperiodo.subperiodo.nombre, 
                                                                                            "nombre": datapdf.datos.Pagos.info.aportesperiodo.aporte.motivo, 
                                                                                            "detalle": datapdf.datos.Pagos.info.detalle, 
                                                                                            "fechaEvento": moment(datapdf.datos.Pagos.info.fecha).add(1, 'd').locale('es').format('LL'), 
                                                                                            "formaDePago": data.tipo }, 
                                                                                "usuario": { "identificador": data.socio.cedula, 
                                                                                            "nombre": data.socio.nombres, 
                                                                                            "apellido": data.socio.apellidos, 
                                                                                            "direccion": data.socio.direccion, 
                                                                                            "correo": data.socio.email, 
                                                                                            "telefono": data.socio.telefono, 
                                                                                            "celular": data.socio.celular },
                                                                                "sector":{"nombre":data.sectore.nombre},
                                                                                "tesorero": { "identificador": data.administrativo.socio.cedula, 
                                                                                            "nombre": data.administrativo.socio.nombres, 
                                                                                            "apellido": data.administrativo.socio.apellidos } }, ip: this.ip } as any;
                                this.servicioCobrosAportes.printerAporte(sendCod).subscribe();
                            }
                            
                        });
                        let dialogRef = this.dialog.open(ImprimirMultasPagosComponent, this.config);
                        dialogRef.componentInstance.dataPdf = datapdf.datos.Pagos.ruta;
                        dialogRef.result.then(
                            result => {

                            },
                            reason => {
                                console.log(reason);
                            }
                        );
                        this.ngxService.stopLoader('loaderPdfPagos');
                    }
                });
            } else {
                this.notificationServices.setNotification(datapdf);
            }
        });
    }

    cerrarModal(){
        this.activeModal.close(2);
    }
}