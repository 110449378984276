import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Aportes } from '../../aporte/aportes.model';
import { AportesServices } from '../../aporte/aportes.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { AportesperiodosServices } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.services';
import { Eventos } from '../../../eventos/evento/eventos.model';
import { EventosServices } from '../../../eventos/evento/eventos.services';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-addaporte',
	templateUrl: './addaporte.component.html',
	styleUrls: ['./addaporte.component.scss']
})

export class AddAporteComponent implements OnInit {

	@Input() Subperiodo:Subperiodos;
	userdata:any;
	dataAdministrativo:any;
	public formAddAporte: FormGroup;
	public formAddEvento: FormGroup;
	public dataAportesPeriodos:any;
	public dataAportesPeriodosTemporales:any;
	public dataAportePeriodoSelect:any;
	public dataEvento= {'aportesperiodo_id':0} as Eventos;
	inconoLoder:string=SPINNER.wanderingCubes;
	dataAporte:Aportes;

	timeout: any;
	@ViewChild('tableAportesTempolales') table: any;
	@ViewChild('tablePeriodoFijos') tableFijo: any;
	@ViewChild('ctdTabset') ctdTabset:any;
	
	constructor(private fb: FormBuilder,
		public localdata:localStorageServices,
		private activeModal:NgbActiveModal,
		private ngxService: NgxUiLoaderService,
		private servicioadministrativo:AdministrativosServices,
		private validatciones:customValidatorMondogo,
		private aportePeriodoServices:AportesperiodosServices,
		private servicioAporte:AportesServices,
		private servicioEvento:EventosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata=this.localdata.getValueFromLocalStorage();
		
		this.aportePeriodoServices.viewAportesPeriodosAsignadosConValores(this.Subperiodo).subscribe((data:any)=>{
			this.dataAportesPeriodos=data.aportes;
		});

		this.aportePeriodoServices.viewAportesPeriodosAsignadosConValoresTemporales(this.Subperiodo).subscribe((data:any)=>{
			this.dataAportesPeriodosTemporales=data.aportes;
			
		});

		this.formAddEvento = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			tipo:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});

		if(this.userdata.datos.socio){
			this.ngxService.startLoader('loaderAportesAdd');
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
				this.formAddAporte = this.fb.group({
					motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
					tiempo:[0,Validators.compose([Validators.required,CustomValidators.number])],
					montoaporte:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
					subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[1,null],
				});
				this.ngxService.stopLoader('loaderAportesAdd');
	
			});
		}
		
	}


	save(){
		this.ngxService.startLoader('loaderAportesAdd');
		this.dataEvento.detalle=this.formAddEvento.value.detalle;
		this.dataEvento.fecha=this.formAddEvento.value.fecha;
		this.dataEvento.tipo=this.formAddEvento.value.tipo;
		this.dataEvento.estado=1;

		this.servicioEvento.saveEventos(this.dataEvento).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('loaderAportesAdd');
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
				this.ngxService.stopLoader('loaderAportesAdd');
			}
		});
	}

	saveAporte(){
		let aporteDataModel:any=this.formAddAporte.value;
		this.ngxService.startLoader('loaderAportesAdd');
		this.servicioAporte.saveAportesYPeriodo(aporteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.formAddAporte.reset();
						this.formAddAporte = this.fb.group({
							motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
							tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
							tiempo:[0,Validators.compose([Validators.required,CustomValidators.number])],
							montoaporte:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
							subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
							estado:[1,null],
						});
						this.dataAporte=undefined;
						this.aportePeriodoServices.viewAportesPeriodosAsignadosConValoresTemporales(this.Subperiodo).subscribe((data:any)=>{
							this.dataAportesPeriodosTemporales=data.aportes;
						});
						this.ngxService.stopLoader('loaderAportesAdd');
					}
					this.ngxService.stopLoader('loaderAportesAdd');
				});
			}else{
				this.Noti.setNotification(data);
				this.ngxService.stopLoader('loaderAportesAdd');
			}
		});
		
		
	}

	selectAporteSubPeriodo(data:any){
		this.dataAportePeriodoSelect=data;
		this.dataEvento.aportesperiodo_id=this.dataAportePeriodoSelect.id;
		console.log(this.dataEvento);
		
	}

	cancelarMotivo(){
		this.dataAportePeriodoSelect=undefined;
	}


	onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRowTemporales(row) {
		//console.log('Toggled Expand Row!', row);
		this.table.rowDetail.collapseAllRows();
		this.table.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggleTemporales(event) {
		//console.log('Detail Toggled', event);
	}
	
	agregarAportePeriodo(data:any){
		this.dataAporte={'id':data.id,'motivo':data.motivo,'tipo':data.tipo} as Aportes;
		this.formAddAporte = this.fb.group({
			tiempo:[0,Validators.compose([Validators.required,CustomValidators.number])],
			montoaporte:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
			subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
			aporte_id:[this.dataAporte.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
		this.ctdTabset.select('tab3');
	}

	cancelarAportePeriodo(){
		
		if(this.userdata.datos.socio){
			this.ngxService.startLoader('loaderAportesAdd');
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
				this.formAddAporte = this.fb.group({
					motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
					tiempo:[0,Validators.compose([Validators.required,CustomValidators.number])],
					montoaporte:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
					subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[1,null],
				});
				this.ngxService.stopLoader('loaderAportesAdd');
				this.dataAporte=undefined;
	
			});
		}
	}

	onPageFijo(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRowFijo(row) {
		//console.log('Toggled Expand Row!', row);
		this.tableFijo.rowDetail.collapseAllRows();
		this.tableFijo.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggleFijo(event) {
		//console.log('Detail Toggled', event);
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}