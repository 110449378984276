import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Actividades } from '../../actividade/actividades.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { Cobrosmultas } from '../../../cobrosmultas/cobrosmulta/cobrosmultas.model';
import { CobrosmultasServices } from '../../../cobrosmultas/cobrosmulta/cobrosmultas.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Asistencias } from '../../../asistencias/asistencia/asistencias.model';
import { AsistenciasServices } from '../../../asistencias/asistencia/asistencias.services';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';


@Component({
	selector: 'app-addActividade-sancion',
	templateUrl: './sancion.component.html',
	styleUrls: ['./sancion.component.scss']
})

export class AddSancionEventoComponent implements OnInit {
	rows = [];
	temp = [];
	rowsa = [];
	tempa = [];
	public imgUrlSocios: string;
	userdata: any;
	inconoLoder: string = SPINNER.wanderingCubes;

	@Input() Actividade = { 'tipo': 3 } as Actividades;
	@Input() Multasperiodo = { 'montomulta': 0 } as Multasperiodos;
	public dataSectores: any;
	public dataSector: Sectores;

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		private servicioSectore: SectoresServices,
		private asistenciasServices: AsistenciasServices,
		public localdata: localStorageServices,
		private servicioSocios: SociosServices,
		private servicioCobrosMultas: CobrosmultasServices,
		private ngxService: NgxUiLoaderService,
		private servicioadministrativo: AdministrativosServices,
		private Noti: NotificationServices, ) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios = Globals.BASE_URL_API_REST;
		this.ngxService.startLoader('loaderMultasAgregarSosio');
		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			const temp = data.sectores.filter(function (d) {
				return d.id != 0;
			});
			this.dataSectores = temp;
		});
		this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
			this.rows = data.socios;
			this.temp = [...this.rows];
			this.asistenciasServices.getAsistenciasPorActividadConformar(this.Actividade).subscribe((data: any) => {
				this.rowsa = data.asistencias;
				this.tempa = [...this.rows];
			});
			this.ngxService.stopLoader('loaderMultasAgregarSosio');
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}

	selectgeneral(data: any) {

		let dataPagos = {} as Cobrosmultas;
		this.ngxService.startLoader('loaderMultasAgregarSosio');
		if (this.userdata.datos.socio) {
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

				dataPagos.administrativo_id = datadmin.administrativo.id;
				dataPagos.actividade_id = this.Actividade.id;
				dataPagos.socio_id = data.id;
				dataPagos.abono = this.Multasperiodo.montomulta;
				dataPagos.estado = 0;
				dataPagos.sectore_id = 0;
				this.servicioCobrosMultas.saveCobrosmultas(dataPagos).subscribe((datasave: any) => {
					if (datasave.estado) {
						this.Noti.setNotification(datasave).then((datas) => {
							if (datas) {
								this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
									this.rows = data.socios;
									this.temp = [...this.rows];
								});
								this.ngxService.stopLoader('loaderMultasAgregarSosio');
							}
						});
					} else {
						this.ngxService.stopLoader('loaderMultasAgregarSosio');
						this.Noti.setNotification(datasave);
					}
				});



			});
		}
	}

	select(data: any) {


		this.ngxService.startLoader('loaderMultasAgregarSosio');
		if (this.userdata.datos.socio) {
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {
				data.registros.forEach((obj: any, index) => {
					setTimeout(() => {
						let dataPagos = {} as Cobrosmultas;
						dataPagos.administrativo_id = datadmin.administrativo.id;
						dataPagos.actividade_id = this.Actividade.id;
						dataPagos.socio_id = data.id;
						dataPagos.abono = this.Multasperiodo.montomulta;
						dataPagos.estado = 0;
						dataPagos.sectore_id = obj.sectore_id;
						this.servicioCobrosMultas.saveCobrosmultas(dataPagos).subscribe((datasave: any) => {
							if (datasave.estado) {
								this.Noti.setNotification(datasave).then((datas) => {
									if (datas) {
										this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
											this.rows = data.socios;
											this.temp = [...this.rows];
										});
										this.ngxService.stopLoader('loaderMultasAgregarSosio');
									}
								});
							} else {
								this.ngxService.stopLoader('loaderMultasAgregarSosio');
								this.Noti.setNotification(datasave);
							}
						});
					}, 1500 * index);
				});
			});
		}
	}

	//Para asigna socio a comisiones

	selectgeneralcomicion(data: any) {
		let datasms = { 'val': data.cedula + ' ' + data.nombres + ' ' + data.apellidos, 'msg': 'Asignar comisión al socio' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				let dataPagos = {} as Asistencias;
				this.ngxService.startLoader('loaderMultasAgregarSosio');
				if (this.userdata.datos.socio) {
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

						dataPagos.administrativo_id = datadmin.administrativo.id;
						dataPagos.actividade_id = this.Actividade.id;
						dataPagos.socio_id = data.id;
						dataPagos.estado = 2;
						dataPagos.sectore_id = 0;
						this.asistenciasServices.saveAsistencias(dataPagos).subscribe((datasave: any) => {
							if (datasave.estado) {
								this.Noti.setNotification(datasave).then((datas) => {
									if (datas) {
										this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
											this.rows = data.socios;
											this.temp = [...this.rows];
											this.asistenciasServices.getAsistenciasPorActividadConformar(this.Actividade).subscribe((data: any) => {
												this.rowsa = data.asistencias;
												this.tempa = [...this.rows];
											});
										});
										this.ngxService.stopLoader('loaderMultasAgregarSosio');
									}
								});
							} else {
								this.ngxService.stopLoader('loaderMultasAgregarSosio');
								this.Noti.setNotification(datasave);
							}
						});
					});
				}
			}
		});
	}

	selectcomision(data: any) {
		let datasms = { 'val': data.cedula + ' ' + data.nombres + ' ' + data.apellidos, 'msg': 'Asignar comisión a todos los sectores que pertenece el socio' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.ngxService.startLoader('loaderMultasAgregarSosio');
				if (this.userdata.datos.socio) {
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {
						data.registros.forEach((obj: any, index) => {
							setTimeout(() => {
								let dataPagos = {} as Asistencias;
								dataPagos.administrativo_id = datadmin.administrativo.id;
								dataPagos.actividade_id = this.Actividade.id;
								dataPagos.socio_id = data.id;
								dataPagos.estado = 2;
								dataPagos.sectore_id = obj.sectore_id;
								this.asistenciasServices.saveAsistencias(dataPagos).subscribe((datasave: any) => {
									if (datasave.estado) {
										this.Noti.setNotification(datasave).then((datas) => {
											if (datas) {
												this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
													this.rows = data.socios;
													this.temp = [...this.rows];
													this.asistenciasServices.getAsistenciasPorActividadConformar(this.Actividade).subscribe((data: any) => {
														this.rowsa = data.asistencias;
														this.tempa = [...this.rows];
													});
												});
												this.ngxService.stopLoader('loaderMultasAgregarSosio');
											}
										});
									} else {
										this.ngxService.stopLoader('loaderMultasAgregarSosio');
										this.Noti.setNotification(datasave);
									}
								});
							}, 1500 * index);
						});
					});
				}
			}
		});


	}

	onChange(data: any) {
		if (data != undefined) {
			this.dataSector = data;
			const temp = this.temp.filter(function (d) {
				let existe: boolean = false;
				for (let index = 0; index < d.registros.length; index++) {
					if (d.registros[index].sectore_id == data.id) {
						existe = true;
					}
				}
				return existe == true;
			});
			this.rows = temp;
		} else {
			this.dataSector = undefined;
			const temp = this.temp.filter(function (d) {
				return d.id != undefined;
			});
			this.rows = temp;
		}


	}

	seleccionarSector(dataSectore: Sectores, dataSelect: any) {
		let datasms = { 'val': dataSelect.cedula + ' ' + dataSelect.nombres + ' ' + dataSelect.apellidos + ' ' + dataSectore.nombre, 'msg': 'Asignar comisión al sector' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				let dataPagos = {} as Asistencias;
				this.ngxService.startLoader('loaderMultasAgregarSosio');
				if (this.userdata.datos.socio) {
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

						dataPagos.administrativo_id = datadmin.administrativo.id;
						dataPagos.actividade_id = this.Actividade.id;
						dataPagos.socio_id = dataSelect.id;
						dataPagos.estado = 2;
						dataPagos.sectore_id = dataSectore.id;
						this.asistenciasServices.saveAsistencias(dataPagos).subscribe((datasave: any) => {
							if (datasave.estado) {
								this.Noti.setNotification(datasave).then((datas) => {
									if (datas) {
										this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
											this.rows = data.socios;
											this.temp = [...this.rows];
											this.asistenciasServices.getAsistenciasPorActividadConformar(this.Actividade).subscribe((data: any) => {
												this.rowsa = data.asistencias;
												this.tempa = [...this.rows];
											});
										});
										this.ngxService.stopLoader('loaderMultasAgregarSosio');
									}
								});
							} else {
								this.ngxService.stopLoader('loaderMultasAgregarSosio');
								this.Noti.setNotification(datasave);
							}
						});
					});
				}
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}