import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Asistencias} from './asistencias.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class AsistenciasServices {

	constructor(private http: HttpClient) { }

	getDataAsistencias():Observable<Asistencias[]>{
		return this.http.get<Asistencias[]>(Globals.BASE_URL_API_REST+'asistencias.json');
	}

	saveAsistencias(data:Asistencias){
		return this.http.post(Globals.BASE_URL_API_REST+'asistencias.json',data);
	}

	deleteAsistencias(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'asistencias/'+id+'.json');
	}

	updateAsistencias(data:Asistencias){
		 return this.http.patch(Globals.BASE_URL_API_REST+'asistencias/'+data.id+'.json',data);
	}

	viewAsistencias(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'asistencias/'+id+'.json');
	}

	getAsistenciasPorActividad(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'opopasistencias.json',data);
	}

	getAsistenciasPorActividadConformar(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'opopasistencias.json',data);
    }
	
}