import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditGastosperiodoComponent } from './edit/editgastosperiodo.component';
import { AddGastosperiodoComponent } from './add/addgastosperiodo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditGastosperiodoComponent,AddGastosperiodoComponent],
	entryComponents:[EditGastosperiodoComponent,AddGastosperiodoComponent],
})
export class ModalGastosperiodoModule { }
