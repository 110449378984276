import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../global/global.model';
import {localStorageServices} from '../auth/localStorageServices';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class SideBarMenuServices {
    
    constructor(private http: HttpClient,
                private local:localStorageServices)
                { }   
    getmenu(){
        console.log(this.local.getValueFromLocalStorage());
        
        return this.http.post(Globals.BASE_URL_API_REST+'menusidebar.json',this.local.getValueFromLocalStorage().datos.group);   
    }
}
