import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Pagogastos} from './pagogastos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class PagogastosServices {

	constructor(private http: HttpClient) { }

	getDataPagogastos(page:number):Observable<Pagogastos[]>{
		return this.http.get<Pagogastos[]>(Globals.BASE_URL_API_REST+'pagogastos.json?page='+page);
	}

	savePagogastos(data:Pagogastos){
		return this.http.post(Globals.BASE_URL_API_REST+'pagogastos.json',data);
	}

	deletePagogastos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'pagogastos/'+id+'.json');
	}

	updatePagogastos(data:Pagogastos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'pagogastos/'+data.id+'.json',data);
	}

	viewPagogastos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'pagogastos/'+id+'.json');
	}

	viewPagogastosSubperiodo(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'listapagogastos.json',data);
	}

	savePagogastosYPeriodo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listapagogastos.json',data);
	}

	getPagogastosSocio(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'pagogastosocios.json',data);
	}

}