import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagoprestamos } from '../../pagoprestamo/pagoprestamos.model';
import { PagoprestamosServices } from '../../pagoprestamo/pagoprestamos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Prestamos } from '../../../prestamos/prestamo/prestamos.model';
import { PrestamosServices } from '../../../prestamos/prestamo/prestamos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-editpagoprestamo',
	templateUrl: './editpagoprestamo.component.html',
	styleUrls: ['./editpagoprestamo.component.scss']
})

export class EditPagoprestamoComponent implements OnInit {

	@Input() Pagoprestamo:Pagoprestamos;
	public dataPrestamos={'id':0} as Prestamos;
	public prestamosData:any;
	public dataAdministrativos={'cargo':'vacio'} as Administrativos;
	public administrativosData:any;

	public formPagoprestamoFecha:FormGroup;
	public editFecha:boolean=false;

	public formPagoprestamoMonto:FormGroup;
	public editMonto:boolean=false;

	public formPagoprestamoPrestamo:FormGroup;
	public editPrestamo:boolean=false;

	public formPagoprestamoAdministrativo:FormGroup;
	public editAdministrativo:boolean=false;

	public formPagoprestamoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPagoprestamo:PagoprestamosServices,
		private servicioPrestamo:PrestamosServices,
		private servicioAdministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioPrestamo.getDataPrestamos().subscribe((data:any)=>{
			this.prestamosData=data.prestamos;
			this.dataPrestamos=this.prestamosData.find(obj => obj.id == this.Pagoprestamo.prestamo_id);
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.administrativosData=data.administrativos;
			this.dataAdministrativos=this.administrativosData.find(obj => obj.id == this.Pagoprestamo.administrativo_id);
		});

		var datePipeFecha = new DatePipe("en-US");
		this.formPagoprestamoFecha=this.fb.group({
			fecha:[datePipeFecha.transform(this.Pagoprestamo.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		this.formPagoprestamoMonto=this.fb.group({
			monto:[this.Pagoprestamo.monto,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPagoprestamoPrestamo=this.fb.group({
			prestamo_id:[this.Pagoprestamo.prestamo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});

		this.formPagoprestamoAdministrativo=this.fb.group({
			administrativo_id:[this.Pagoprestamo.administrativo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editPagoprestamoFechaEnable(){
		this.editFecha=true;
		var datePipeFecha = new DatePipe("en-US");
		this.formPagoprestamoFecha=this.fb.group({
			fecha:[datePipeFecha.transform(this.Pagoprestamo.fecha, 'yyyy-MM-dd'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editPagoprestamoFechaDisable(){
		this.editFecha=false;
	}

	savePagoprestamoFecha(){
		let PagoprestamoDataModel:Pagoprestamos=this.formPagoprestamoFecha.value;
		PagoprestamoDataModel.id=this.Pagoprestamo.id;
		this.Pagoprestamo.fecha=PagoprestamoDataModel.fecha;
		this.servicioPagoprestamo.updatePagoprestamos(PagoprestamoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPagoprestamoMontoEnable(){
		this.editMonto=true;
		this.formPagoprestamoMonto=this.fb.group({
			monto:[this.Pagoprestamo.monto,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoprestamoMontoDisable(){
		this.editMonto=false;
	}

	savePagoprestamoMonto(){
		let PagoprestamoDataModel:Pagoprestamos=this.formPagoprestamoMonto.value;
		PagoprestamoDataModel.id=this.Pagoprestamo.id;
		this.Pagoprestamo.monto=PagoprestamoDataModel.monto;
		this.servicioPagoprestamo.updatePagoprestamos(PagoprestamoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMonto=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPagoprestamoPrestamoEnable(){
		this.editPrestamo=true;
		this.formPagoprestamoPrestamo=this.fb.group({
			prestamo_id:[this.Pagoprestamo.prestamo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoprestamoPrestamoDisable(){
		this.editPrestamo=false;
	}

	savePagoprestamoPrestamo(){
		let PagoprestamoDataModel:Pagoprestamos=this.formPagoprestamoPrestamo.value;
		PagoprestamoDataModel.id=this.Pagoprestamo.id;
		this.Pagoprestamo.prestamo_id=PagoprestamoDataModel.prestamo_id;
		this.servicioPagoprestamo.updatePagoprestamos(PagoprestamoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editPrestamo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	editPagoprestamoAdministrativoEnable(){
		this.editAdministrativo=true;
		this.formPagoprestamoAdministrativo=this.fb.group({
			administrativo_id:[this.Pagoprestamo.administrativo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagoprestamoAdministrativoDisable(){
		this.editAdministrativo=false;
	}

	savePagoprestamoAdministrativo(){
		let PagoprestamoDataModel:Pagoprestamos=this.formPagoprestamoAdministrativo.value;
		PagoprestamoDataModel.id=this.Pagoprestamo.id;
		this.Pagoprestamo.administrativo_id=PagoprestamoDataModel.administrativo_id;
		this.servicioPagoprestamo.updatePagoprestamos(PagoprestamoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editAdministrativo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

