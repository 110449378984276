import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { ListaImpresorasServices } from '../../global/global.services';
import { Impresora } from '../../global/global.model';
import { NotificationServices } from '../../appauth/notification/notificatio.services';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  color = 'blue';
  showSettings = false;
  showMinisidebar = false; 
  showDarktheme = false;
  userdata:any;
  public innerWidth: any;
  insertar:boolean=false;
  dataImpresora={} as Impresora;
  dataAdministrativo ={} as any;
  actulizar:boolean=false;

  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router,
              public localdata:localStorageServices,
              private Noti:NotificationServices,
              private servicioImpresora: ListaImpresorasServices,) {}

  ngOnInit() {
    this.userdata=this.localdata.getValueFromLocalStorage();
    if(this.userdata.datos.socio!=undefined){
      this.servicioImpresora.viewAdministradoresImpresoras(this.userdata.datos.socio).subscribe((datadmin: any) => {
        this.dataAdministrativo=datadmin.administrativo;
        if(datadmin.administrativo.impresoras[0]!=undefined){
          this.dataImpresora=datadmin.administrativo.impresoras[0];
          this.insertar=true;
        }else{
          this.insertar=false;
        }
      });
    }
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.handleLayout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }

  guardar(data:any){
    this.dataImpresora=data.value;
    this.dataImpresora.administrativo_id=this.dataAdministrativo.id;
    this.dataImpresora.estado=1;
    this.servicioImpresora.saveImpresoras(this.dataImpresora).subscribe((dataImpr:any)=>{
      if(dataImpr.estado){
				this.Noti.setNotification(dataImpr).then((datas)=>{
					if(datas){
					this.insertar=true;
					}
				});
			}else{
				this.Noti.setNotification(dataImpr);
			}
    });
    
  }

  habiltar(){
    this.actulizar=true;
  }

  actulizarInfo(){  
    this.servicioImpresora.updateImpresoras(this.dataImpresora).subscribe((dataImpr:any)=>{
      if(dataImpr.estado){
				this.Noti.setNotification(dataImpr).then((datas)=>{
					if(datas){
            this.actulizar=false;
					}
				});
			}else{
				this.Noti.setNotification(dataImpr);
			}
    });
  }

  actulizarCancel(){
    this.actulizar=false;
  }
}
