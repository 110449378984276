import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Multas } from '../../multa/multas.model';
import { MultasServices } from '../../multa/multas.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editmulta',
	templateUrl: './editmulta.component.html',
	styleUrls: ['./editmulta.component.scss']
})

export class EditMultaComponent implements OnInit {

	@Input() Multa:Multas;

	public formMultaMotivo:FormGroup;
	public editMotivo:boolean=false;

	
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioMulta:MultasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formMultaMotivo=this.fb.group({
			motivo:[this.Multa.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
		});

		
	}
	

	editMultaMotivoEnable(){
		this.editMotivo=true;
		this.formMultaMotivo=this.fb.group({
			motivo:[this.Multa.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
		});
	}

	editMultaMotivoDisable(){
		this.editMotivo=false;
	}

	saveMultaMotivo(){
		let MultaDataModel:Multas=this.formMultaMotivo.value;
		MultaDataModel.id=this.Multa.id;
		this.Multa.motivo=MultaDataModel.motivo;
		this.servicioMulta.updateMultas(MultaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMotivo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	

}

