import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEventoComponent } from './edit/editevento.component';
import { AddEventoComponent } from './add/addevento.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AddRecaudacionEventoComponent } from './generar/generarreaudacion.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddRecaudacionSectorEventoComponent } from './sector/sectorreaudacion.component';
import { AddMinutosSectorEventoComponent } from './minutosector/minutosector.component';
import { SociosModule } from '../../socios/socios.module';
import { AddCustomSectorEventoComponent } from './customsector/customsector.component';
@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxUiLoaderModule,
		NgbModule,
		NgSelectModule,
		SociosModule
	],
	declarations:[EditEventoComponent,AddEventoComponent,AddRecaudacionEventoComponent,AddRecaudacionSectorEventoComponent,AddMinutosSectorEventoComponent,AddCustomSectorEventoComponent],
	entryComponents:[EditEventoComponent,AddEventoComponent,AddRecaudacionEventoComponent,AddRecaudacionSectorEventoComponent,AddMinutosSectorEventoComponent,AddCustomSectorEventoComponent],
})
export class ModalEventoModule { }
