import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPagoprestamoComponent } from './edit/editpagoprestamo.component';
import { AddPagoprestamoComponent } from './add/addpagoprestamo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EditCuotaPagoMotivoCuotaComponent } from './change/changemotivo.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditPagoprestamoComponent,AddPagoprestamoComponent,EditCuotaPagoMotivoCuotaComponent],
	entryComponents:[EditPagoprestamoComponent,AddPagoprestamoComponent,EditCuotaPagoMotivoCuotaComponent],
})
export class ModalPagoprestamoModule { }
