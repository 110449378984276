import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { customValidatorMondogo } from './custom-validator/customValidator.services';

@NgModule({
  imports: [
    CommonModule
  ],
  providers:[
    customValidatorMondogo
  ]
})
export class AppCobrosModule { }
