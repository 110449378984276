import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Cuotas } from '../../cuota/cuotas.model';
import { CuotasServices } from '../../cuota/cuotas.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';

@Component({
	selector: 'app-addcuota',
	templateUrl: './addcuota.component.html',
	styleUrls: ['./addcuota.component.scss']
})

export class AddCuotaComponent implements OnInit {
	public dataAdministrativos:any;
	public dataPagos:any;
	public formAddCuota: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioCuota:CuotasServices,
		private servicioAdministrativo:AdministrativosServices,
		private servicioPago:PagosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.dataAdministrativos=data.administrativos;
		});
		this.servicioPago.getDataPagos().subscribe((data:any)=>{
			this.dataPagos=data.pagos;
		});
		this.formAddCuota = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			administrativo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			pago_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let cuotaDataModel:Cuotas=this.formAddCuota.value;
		this.servicioCuota.saveCuotas(cuotaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}