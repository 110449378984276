export class Globals {
    public static BASE_URL_API_REST: string = 'https://api-aguaquindialo.mondo-go.com/';
}

export interface Impresora{
	id?:number;
	nombre?:string;
	marca?:string;
	modelo?:string;
	serie?:string;
	ip?:string;
	administrativo_id?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
}