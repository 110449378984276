import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Sectores } from '../../sectore/sectores.model';
import { SectoresServices } from '../../sectore/sectores.services';

@Component({
	selector: 'app-addsectore',
	templateUrl: './addsectore.component.html',
	styleUrls: ['./addsectore.component.scss']
})

export class AddSectoreComponent implements OnInit {
	public formAddSectore: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioSectore:SectoresServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddSectore = this.fb.group({
			nombre:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
			estado:[1,null],
		});
	}


	save(){
		let sectoreDataModel:Sectores=this.formAddSectore.value;
		this.servicioSectore.saveSectores(sectoreDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}