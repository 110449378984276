import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Gastos } from '../../gasto/gastos.model';
import { GastosServices } from '../../gasto/gastos.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { Pagogastos } from '../../../pagogastos/pagogasto/pagogastos.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { GastosperiodosServices } from '../../../gastosperiodos/gastosperiodo/gastosperiodos.services';
import { PagogastosServices } from '../../../pagogastos/pagogasto/pagogastos.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-add-gasto',
	templateUrl: './reguistro.component.html',
	styleUrls: ['./reguistro.component.scss']
})

export class AddGastoReguistoComponent implements OnInit {
	
	@Input() Subperiodo:Subperiodos;
	userdata:any;
	dataAdministrativo:any;
	public formAddGasto: FormGroup;
	public formAddPagogasto: FormGroup;
	public dataGastosPeriodos:any;
	public dataGastosPeriodosTemporales:any;
	public dataGastoPeriodoSelect:any;
	public dataPagogasto= {'gastosperiodo_id':0} as Pagogastos;
	inconoLoder:string=SPINNER.wanderingCubes;
	timeout: any;
	@ViewChild('tableGastosTemporales') table: any;
	@ViewChild('tableGastosFijos') tableFijo: any;
	@ViewChild('ctdTabset') ctdTabset:any;
	dataGasto:Gastos;

	constructor(private fb: FormBuilder,
		public localdata:localStorageServices,
		private activeModal:NgbActiveModal,
		private servicioadministrativo:AdministrativosServices,
		private ngxService: NgxUiLoaderService,
		private gastoPeriodoServices:GastosperiodosServices,
		private validatciones:customValidatorMondogo,
		private servicioGasto:GastosServices,
		private servicioActividad:PagogastosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata=this.localdata.getValueFromLocalStorage();
		
		this.gastoPeriodoServices.viewGastosPeriodosAsignadosConValores(this.Subperiodo).subscribe((data:any)=>{
			this.dataGastosPeriodos=data.gastos;
		});

		this.gastoPeriodoServices.viewGastosPeriodosAsignadosConValoresTemporales(this.Subperiodo).subscribe((data:any)=>{
			this.dataGastosPeriodosTemporales=data.gastos;
		});

		

		if(this.userdata.datos.socio){
			this.ngxService.startLoader('loaderGastossAdd');
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
				this.formAddGasto = this.fb.group({
					motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
					montogasto:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
					subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[1,null],
				});
				this.formAddPagogasto = this.fb.group({
					fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
					detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					administrativo_id:[this.dataAdministrativo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[0,null],
				});
				this.ngxService.stopLoader('loaderGastossAdd');
			});
		}

	}


	save(){
		this.ngxService.startLoader('loaderGastossAdd');
		this.dataPagogasto.detalle=this.formAddPagogasto.value.detalle;
		this.dataPagogasto.fecha=this.formAddPagogasto.value.fecha;
		this.dataPagogasto.administrativo_id=this.formAddPagogasto.value.administrativo_id;

		this.dataPagogasto.estado=0;

		this.servicioActividad.savePagogastos(this.dataPagogasto).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('loaderGastossAdd');
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
				this.ngxService.stopLoader('loaderGastossAdd');
			}
		});
	}

	saveGasto(){
		let gastoDataModel:any=this.formAddGasto.value;
		this.ngxService.startLoader('loaderGastossAdd');
		this.servicioGasto.saveGastosYPeriodo(gastoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.formAddGasto.reset();
						this.formAddGasto = this.fb.group({
							motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
							tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
							montogasto:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
							subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
							estado:[1,null],
						});
						this.gastoPeriodoServices.viewGastosPeriodosAsignadosConValoresTemporales(this.Subperiodo).subscribe((data:any)=>{
							this.dataGastosPeriodosTemporales=data.gastos;
						});
						this.ngxService.stopLoader('loaderGastossAdd');
					}
					this.ngxService.stopLoader('loaderGastossAdd');
				});
			}else{
				this.Noti.setNotification(data);
				this.ngxService.stopLoader('loaderGastossAdd');
			}
		});
		
		
	}

	selectGastoSubPeriodo(data:any){
		this.dataGastoPeriodoSelect=data;
		this.dataPagogasto.gastosperiodo_id=this.dataGastoPeriodoSelect.id;
		console.log(this.dataPagogasto);
		
	}

	cancelarMotivo(){
		this.dataGastoPeriodoSelect=undefined;
	}

	onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRowTemporales(row) {
		//console.log('Toggled Expand Row!', row);
		this.table.rowDetail.collapseAllRows();
		this.table.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggleTemporales(event) {
		//console.log('Detail Toggled', event);
	}

	onPageFijo(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRowFijo(row) {
		//console.log('Toggled Expand Row!', row);
		this.tableFijo.rowDetail.collapseAllRows();
		this.tableFijo.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggleFijo(event) {
		//console.log('Detail Toggled', event);
	}

	agregarGastoPeriodo(data:any){
		console.log(data);
		
		this.dataGasto={'id':data.id,'motivo':data.motivo,'tipo':data.tipo} as Gastos;
		this.formAddGasto = this.fb.group({
			montogasto:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
			subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
			gasto_id:[this.dataGasto.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
		this.ctdTabset.select('tab3');
	}

	cancelarGastoPeriodo(){
		
		if(this.userdata.datos.socio){
			this.ngxService.startLoader('loaderGastossAdd');
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
				this.formAddGasto = this.fb.group({
					motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
					montogasto:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
					subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[1,null],
				});
				this.ngxService.stopLoader('loaderGastossAdd');
				this.dataGasto=undefined;
	
			});
		}
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}