import { Routes } from '@angular/router';

import { NotFoundComponent } from './404/not-found.component';
import { HomePageConsultasComponent } from './home-page-consultas/home-page-consultas.component';
import { HomePageHorariosComponent } from './home-page-horarios/home-page-horarios.component';
import { HomePageInfoComponent } from './home-page-info/home-page-info.component';
import { LockComponent } from './lock/lock.component';
import { LoginComponent } from './login/login.component';

export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '404',
        component: NotFoundComponent,
      },
      {
        path: 'lock',
        component: LockComponent
      },
      {
        path: 'login',
        component: LoginComponent
      }, {
        path: 'inicio',
        component: HomePageInfoComponent
      }, {
        path: 'horarios',
        component: HomePageHorariosComponent
      },{
        path: 'consultas',
        component: HomePageConsultasComponent
      }
    ]
  }
];
