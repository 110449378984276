import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Multas } from '../../multa/multas.model';
import { MultasServices } from '../../multa/multas.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { Actividades } from '../../../actividades/actividade/actividades.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { MultasperiodosServices } from '../../../multasperiodos/multasperiodo/multasperiodos.services';
import { ActividadesServices } from '../../../actividades/actividade/actividades.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-add-multa',
	templateUrl: './reguistro.component.html',
	styleUrls: ['./reguistro.component.scss']
})

export class AddMultaReguistoComponent implements OnInit {
	
	@Input() Subperiodo:Subperiodos;
	userdata:any;
	timeout: any;
	dataAdministrativo:any;
	public formAddMulta: FormGroup;
	public formAddActividade: FormGroup;
	public dataMultasPeriodos:any;
	public dataMultasPeriodosTemporales:any;
	public dataMultaPeriodoSelect:any;
	public dataActividade= {'multasperiodo_id':0} as Actividades;
	inconoLoder:string=SPINNER.wanderingCubes;
	@ViewChild('tablePeriodoTemporales') table: any;
	@ViewChild('tablePeriodoFijos') tableFijo: any;
	@ViewChild('ctdTabset') ctdTabset:any;
	dataMulta:Multas;
	
	constructor(private fb: FormBuilder,
		public localdata:localStorageServices,
		private activeModal:NgbActiveModal,
		private servicioadministrativo:AdministrativosServices,
		private ngxService: NgxUiLoaderService,
		private multaPeriodoServices:MultasperiodosServices,
		private validatciones:customValidatorMondogo,
		private servicioMulta:MultasServices,
		private servicioActividad:ActividadesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.userdata=this.localdata.getValueFromLocalStorage();
		
		this.multaPeriodoServices.viewMultasPeriodosAsignadosConValores(this.Subperiodo).subscribe((data:any)=>{
			this.dataMultasPeriodos=data.multas;
			
		});

		this.multaPeriodoServices.viewMultasPeriodosAsignadosConValoresTemporales(this.Subperiodo).subscribe((data:any)=>{
			this.dataMultasPeriodosTemporales=data.multas;
		});

		this.formAddActividade = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			tipo:[null,Validators.compose([Validators.required,CustomValidators.number])],
			detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			estado:[1,null],
		});

		if(this.userdata.datos.socio){
			this.ngxService.startLoader('loaderMultassAdd');
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
				this.formAddMulta = this.fb.group({
					motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
					montomulta:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
					subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[1,null],
				});
				this.ngxService.stopLoader('loaderMultassAdd');
			});
		}

	}


	save(){
		this.ngxService.startLoader('loaderMultassAdd');
		this.dataActividade.detalle=this.formAddActividade.value.detalle;
		this.dataActividade.fecha=this.formAddActividade.value.fecha;
		this.dataActividade.tipo=this.formAddActividade.value.tipo;
		this.dataActividade.estado=1;

		this.servicioActividad.saveActividades(this.dataActividade).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.ngxService.stopLoader('loaderMultassAdd');
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
				this.ngxService.stopLoader('loaderMultassAdd');
			}
		});
	}

	saveMulta(){
		let multaDataModel:any=this.formAddMulta.value;
		this.ngxService.startLoader('loaderMultassAdd');
		this.servicioMulta.saveMultasYPeriodo(multaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.formAddMulta.reset();
						this.formAddMulta = this.fb.group({
							motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
							tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
							montomulta:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
							subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
							estado:[1,null],
						});
						this.multaPeriodoServices.viewMultasPeriodosAsignadosConValoresTemporales(this.Subperiodo).subscribe((data:any)=>{
							this.dataMultasPeriodosTemporales=data.multas;
						});
						this.ngxService.stopLoader('loaderMultassAdd');
					}
					this.ngxService.stopLoader('loaderMultassAdd');
				});
			}else{
				this.Noti.setNotification(data);
				this.ngxService.stopLoader('loaderMultassAdd');
			}
		});
		
		
	}

	selectMultaSubPeriodo(data:any){
		this.dataMultaPeriodoSelect=data;
		this.dataActividade.multasperiodo_id=this.dataMultaPeriodoSelect.id;
		console.log(this.dataActividade);
		
	}

	cancelarMotivo(){
		this.dataMultaPeriodoSelect=undefined;
	}

	onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRowTemporales(row) {
		//console.log('Toggled Expand Row!', row);
		this.table.rowDetail.collapseAllRows();
		this.table.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggleTemporales(event) {
		//console.log('Detail Toggled', event);
	}

	onPageFijo(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRowFijo(row) {
		//console.log('Toggled Expand Row!', row);
		this.tableFijo.rowDetail.collapseAllRows();
		this.tableFijo.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggleFijo(event) {
		//console.log('Detail Toggled', event);
	}

	agregarMultaPeriodo(data:any){
		console.log(data);
		
		this.dataMulta={'id':data.id,'motivo':data.motivo,'tipo':data.tipo} as Multas;
		this.formAddMulta = this.fb.group({
			montomulta:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
			subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
			multa_id:[this.dataMulta.id,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
		this.ctdTabset.select('tab3');
	}

	cancelarMultaPeriodo(){
		
		if(this.userdata.datos.socio){
			this.ngxService.startLoader('loaderMultassAdd');
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
				this.formAddMulta = this.fb.group({
					motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
					tipo:[2,Validators.compose([Validators.required,CustomValidators.number])],
					montomulta:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
					subperiodo_id:[this.Subperiodo.id,Validators.compose([Validators.required,CustomValidators.number])],
					estado:[1,null],
				});
				this.ngxService.stopLoader('loaderMultassAdd');
				this.dataMulta=undefined;
	
			});
		}
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}