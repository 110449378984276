import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-minutos',
  templateUrl: './minutos.component.html',
  styleUrls: ['./minutos.component.scss']
})
export class MinutosComponent implements OnInit {
  @Input() registro:any;
  datos={'registro':[],'tiempo':0,'hora':0,'min':0} as any;
  vermas:boolean=false;
  constructor() { }

  ngOnInit() {
    let tiempog:number=0;
    for (let index = 0; index < this.registro.length; index++) {
      let minutos=[];
      let tiempop:number=0;
      for (let indexh = 0; indexh < this.registro[index].horarios.length; indexh++) {
        let tipo;
        if(this.registro[index].horarios[indexh].tipo==1){
          tipo='Arriba';
        }
        if(this.registro[index].horarios[indexh].tipo==2){
          tipo='Abajo';
        }
        let dataM={'minutos':this.registro[index].horarios[indexh].minutos,'tipo':tipo} as any;
        minutos.push(dataM);
      }
      let existe:boolean=false;
      let errorIgual:boolean=false;

      if(minutos.length>0){
        existe=true;
        for (let index = 0; index < minutos.length-1; index++) {
          const element = minutos[index];
          if(element.minutos!=minutos[index+1].minutos){
            errorIgual=true;
            break;
          }
        }
        if(errorIgual==false){
          let catidad:number=0;
          let suma:number=0;
          for (let index = 0; index < minutos.length; index++) {
            const element = minutos[index];
            suma+=minutos[index].minutos;
            catidad++;
          }
          suma=suma/catidad;
          tiempop=suma;
        }
      }
      tiempog+=tiempop;
      let resulh:number=(tiempop/60);
      resulh = Math.floor(resulh);
      let resulm=(tiempop-(resulh*60));
      let data = {'sector':this.registro[index].sectore.nombre,'tiene':existe,'error':errorIgual,'horarios':minutos,'hora':resulh,'min':resulm} as any;
      this.datos.registro.push(data)
    }
    let resulh:number=(tiempog/60);
    resulh = Math.floor(resulh);
    let resulm=(tiempog-(resulh*60));
    this.datos.tiempo=tiempog;
    this.datos.hora=resulh;
    this.datos.min=resulm;
    
  }

  ver(val:boolean){
    this.vermas=val;
  }

}
