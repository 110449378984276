import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Sectores} from './sectores.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Socios } from '../../socios/socio/socios.model';


@Injectable()
export class SectoresServices {

	constructor(private http: HttpClient) { }

	getDataSectores():Observable<Sectores[]>{
		return this.http.get<Sectores[]>(Globals.BASE_URL_API_REST+'sectores.json');
	}

	getDataSectoresTesoreros(data:any):Observable<Sectores[]>{
		return this.http.get<Sectores[]>(Globals.BASE_URL_API_REST+'listado_general_tesorero_sectores/'+data.id+'.json');
	}

	getDataRegistrosNoAsigandos(data:Socios):Observable<Sectores[]>{
		return this.http.post<Sectores[]>(Globals.BASE_URL_API_REST+'sectoresresgistro.json',data);
	}

	saveSectores(data:Sectores){
		return this.http.post(Globals.BASE_URL_API_REST+'sectores.json',data);
	}

	deleteSectores(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'sectores/'+id+'.json');
	}

	updateSectores(data:Sectores){
		 return this.http.patch(Globals.BASE_URL_API_REST+'sectores/'+data.id+'.json',data);
	}

	viewSectores(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'sectores/'+id+'.json');
	}
}