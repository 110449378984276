import { Component, OnInit, ViewChild } from '@angular/core';
import { PeriodosServices } from '../../../periodos/periodo/periodos.services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-seleccionar-resumen',
  templateUrl: './seleccionar.component.html',
  styleUrls: ['./seleccionar.component.scss']
})
export class SeleccionarResumenComponent implements OnInit {

  rowps = [];
	tempp = [];
	timeout: any;
  @ViewChild('tablePeriodo') table: any;
  
  constructor(private serviciosPeriodos:PeriodosServices,
    private activeModal:NgbActiveModal,
		private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.listaPeriodos();
  }

  listaPeriodos(){
		
		this.serviciosPeriodos.getDataPeriodos().subscribe((data:any)=>{
			this.rowps=data.periodos;
			this.tempp = [...this.rowps];
		});
  }
  
  onPage(event) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
		  //console.log('paged!', event);
		}, 100);
	}

	toggleExpandRow(row) {
		//console.log('Toggled Expand Row!', row);
		this.table.rowDetail.collapseAllRows();
		this.table.rowDetail.toggleExpandRow(row);
	}
	
	onDetailToggle(event) {
		//console.log('Detail Toggled', event);
  }
  
  seleccionarSubPeriodo(data:any){
    this.activeModal.close(data);
	}

}
