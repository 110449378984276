import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAdministrativoComponent } from './edit/editadministrativo.component';
import { AddAdministrativoComponent } from './add/addadministrativo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EditAdministrativoMotivoComponent } from './change/changemotivo.component';
import { AsignarSectorTesoreroComponent } from './asignar-sector-tesorero/asignar-sector-tesorero.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule
	],
	declarations:[EditAdministrativoComponent,AddAdministrativoComponent,EditAdministrativoMotivoComponent, AsignarSectorTesoreroComponent],
	entryComponents:[EditAdministrativoComponent,AddAdministrativoComponent,EditAdministrativoMotivoComponent, AsignarSectorTesoreroComponent],
})
export class ModalAdministrativoModule { }
