import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SolicitudPrestamoServices } from '../../solicitudes/solicitudes.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { AddSolicitudprestamoComponent } from '../../../solicitudprestamos/modal/add/addsolicitudprestamo.component';

@Component({
	selector: 'app-crear-solicitud',
	templateUrl: './crearsolicitud.component.html',
	styleUrls: ['./crearsolicitud.component.scss']
})

export class CrearSolicitudComponent implements OnInit {

	@Input() Subperiodo:Subperiodos;
    public formSolicitud:FormGroup;
    dataBuscar = {'id':0,'nombres':''} as any;
    rowsocios = [];
	public imgUrlSocios:string;
    userdata:any;

    config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}
    
    constructor(private fb: FormBuilder,
        public dialog: NgbModal,
        private activeModal:NgbActiveModal,
        public localdata:localStorageServices,
		private servicioSubperiodo:SolicitudPrestamoServices,
        private Noti:NotificationServices,) { }
        
    ngOnInit(){
        this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
        if(this.Subperiodo!=undefined){
            this.dataBuscar.id=this.Subperiodo.id;
            this.servicioSubperiodo.getDataSociosSinSolicitudes(this.dataBuscar).subscribe((data:any)=>{
                this.rowsocios=data.socios;
            });
        }
    }

    buscarEvento(event){
		const val = event.target.value.toLowerCase();
		this.dataBuscar.nombres=val;
	}
	
	eviarBusqueda(){
        this.dataBuscar.id=this.Subperiodo.id;
		this.servicioSubperiodo.getDataSociosSinSolicitudes(this.dataBuscar).subscribe((data:any)=>{
            this.rowsocios=data.socios;
        });
    }
    
    open(dataSocio:any){
        let dialogRef = this.dialog.open(AddSolicitudprestamoComponent, this.config);
        dialogRef.componentInstance.Subperiodo = this.Subperiodo;
        dialogRef.componentInstance.Socio = dataSocio;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.dataBuscar.id=this.Subperiodo.id;
                    this.servicioSubperiodo.getDataSociosSinSolicitudes(this.dataBuscar).subscribe((data:any)=>{
                        this.rowsocios=data.socios;
                    });
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}
}