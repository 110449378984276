import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Eventos } from '../../evento/eventos.model';
import { EventosServices } from '../../evento/eventos.services';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { AportesperiodosServices } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.services';

@Component({
	selector: 'app-addevento',
	templateUrl: './addevento.component.html',
	styleUrls: ['./addevento.component.scss']
})

export class AddEventoComponent implements OnInit {
	public dataAportesperiodos:any;
	public formAddEvento: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioEvento:EventosServices,
		private servicioAportesperiodo:AportesperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioAportesperiodo.getDataAportesperiodos().subscribe((data:any)=>{
			this.dataAportesperiodos=data.aportesperiodos;
		});
		this.formAddEvento = this.fb.group({
			fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
			detalle:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
			tipo:[null,Validators.compose([Validators.required,CustomValidators.number])],
			aportesperiodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let eventoDataModel:Eventos=this.formAddEvento.value;
		this.servicioEvento.saveEventos(eventoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}