import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Registros } from '../../registro/registros.model';
import { RegistrosServices } from '../../registro/registros.services';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';

@Component({
	selector: 'app-addregistro',
	templateUrl: './addregistro.component.html',
	styleUrls: ['./addregistro.component.scss']
})

export class AddRegistroComponent implements OnInit {
	@Input() Socio:Socios;
	public dataSectores:any;
	public formAddRegistro: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioRegistro:RegistrosServices,
		private servicioSocio:SociosServices,
		private servicioSectore:SectoresServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		
		this.servicioSectore.getDataRegistrosNoAsigandos(this.Socio).subscribe((data:any)=>{
			this.dataSectores=data.sectores;
		});
		this.formAddRegistro = this.fb.group({
			estado:[1,null],
			socio_id:[this.Socio.id,Validators.compose([Validators.required,CustomValidators.number])],
			sectore_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}


	save(){
		let registroDataModel:Registros=this.formAddRegistro.value;
		this.servicioRegistro.saveRegistros(registroDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}