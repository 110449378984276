import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cobrosmultas } from '../../cobrosmulta/cobrosmultas.model';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { ActividadesServices } from '../../../actividades/actividade/actividades.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { CobrosmultasServices } from '../../cobrosmulta/cobrosmultas.services';
import { ReportesServices } from '../../../reporte/reportes.services';
import { ImprimirMultasPagosComponent } from '../imprimir/imprimir.component';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Actividades } from '../../../actividades/actividade/actividades.model';
import * as moment from 'moment';

@Component({
    selector: 'app-cobrosmulta-pagar',
    templateUrl: './pagar.component.html',
    styleUrls: ['./pagar.component.scss']
})

export class CobrosmultaPagarComponent implements OnInit {

    @Input() Actividade: Actividades;
    @Input() Periodo: any;
    inconoLoder: string = SPINNER.wanderingCubes;
    rows = [];
    temp = [];
    estados = [];
    public imgUrlSocios: string;
    userdata: any;
    config: any = {
        size: 'lg',
        centered: true,
        resolve: { datasend: null }
    }
    ip: string = '127.0.0.1'

    constructor(private activeModal: NgbActiveModal,
        public dialog: NgbModal,
        private serviciosActividade: ActividadesServices,
        public localdata: localStorageServices,
        private ngxService: NgxUiLoaderService,
        private servicesReportes: ReportesServices,
        private servicioCobrosAportes: CobrosmultasServices,
        private servicioadministrativo: AdministrativosServices,
        private notificationServices: NotificationServices,
    ) { }

    ngOnInit() {
        this.userdata = this.localdata.getValueFromLocalStorage();
        this.imgUrlSocios = Globals.BASE_URL_API_REST;
        this.serviciosActividade.viewActividadesMulta(this.Actividade).subscribe((dataactividad: any) => {
            this.rows = dataactividad.actividade.cobrosmultas;
            this.temp = [...this.rows];
            console.log(this.rows);

        });

    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();

        const temp = this.temp.filter(d => {
            let ex: boolean = false;
            if (this.estados.length == 0)
                ex = true;

            this.estados.forEach(data => {
                if (d.estado === data) {
                    ex = true;
                }
            });

            return (d.socio.nombres.toLowerCase().indexOf(val) !== -1 && ex);
        });
        this.rows = temp;
    }

    setEstado(event) {
        let existe: boolean = false;
        let indice: number = 0;
        this.estados.forEach(function (data, index) {
            if (event.target.value == data) {
                existe = true;
                indice = index;
            }
        })

        if (existe)
            this.estados.splice(indice, 1);
        else
            this.estados.push(Number(event.target.value));


        const temp = this.temp.filter(item => {
            let ex: boolean = false;

            if (this.estados.length == 0)
                ex = true;

            this.estados.forEach(data => {
                if (item.estado === data) {
                    ex = true;
                }
            });

            return ex;

        });
        this.rows = temp;

    }

    pagar(dataMulta: any) {
        let dataPagos = {} as Cobrosmultas;
        let dataconfirmar = { 'val': '$ ' + dataMulta.abono + ' ' + dataMulta.socio.cedula + ' ' + dataMulta.socio.nombres + ' ' + dataMulta.socio.apellidos, 'msg': 'Pagar multa' };
        let smsFail = { 'estado': true, 'tipomsg': 'warning', 'msg': { 'ok': 'Inicie sesión como <code>Tesorero</code>' } };

        this.servicioCobrosAportes.viewCobrosmultas(dataMulta.id).subscribe((dataPago: any) => {
            if (dataPago.cobrosmulta.estado == 0) {
                if (this.userdata.datos.socio == undefined) {
                    this.notificationServices.setNotification(smsFail);
                } else {
                    this.notificationServices.msgEliminarConfir(dataconfirmar).then((datas) => {
                        if (datas) {

                            if (this.userdata.datos.socio) {
                                this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

                                    dataPagos.administrativo_id = datadmin.administrativo.id;
                                    dataPagos.estado = 1;
                                    dataPagos.id = dataMulta.id;

                                    this.servicioCobrosAportes.updateCobrosmultas(dataPagos).subscribe((datasave: any) => {
                                        if (datasave.estado) {
                                            this.notificationServices.setNotification(datasave).then((datas) => {
                                                if (datas) {
                                                    this.serviciosActividade.viewActividadesMulta(this.Actividade).subscribe((dataactividad: any) => {
                                                        this.rows = dataactividad.actividade.cobrosmultas;
                                                        this.temp = [...this.rows];
                                                    });
                                                }
                                            });
                                        } else {
                                            this.notificationServices.setNotification(datasave);
                                        }
                                    });



                                });
                            }

                        }
                    });
                }
            } else {
                let estadoPago = "";
                if (dataPago.cobrosmulta.estado === 1) {
                    estadoPago = "Pagado";
                } else if (dataPago.cobrosmulta.estado === 2) {
                    estadoPago = "Anulado";
                }
                let smsFail = { 'estado': true, 'tipomsg': 'warning', 'msg': { 'ok': 'El pago ya esta <code>' + estadoPago + '</code>' } };
                this.notificationServices.setNotification(smsFail);
            }
        });
    }

    imprimir(dataMulta: any) {

        this.ngxService.startLoader('loaderPdfMultas');
        this.servicesReportes.generateReciboMultasPdf(dataMulta).subscribe((datapdf: any) => {
            if (datapdf.estado) {
                this.notificationServices.setNotification(datapdf).then((datas) => {
                    if (datas) {
                        console.log(dataMulta);
                        console.log(this.Actividade);
                        console.log(this.Periodo);

                        this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {
                            if (datadmin.administrativo.impresoras.length > 0) {
                                this.ip = datadmin.administrativo.impresoras[0].ip;
                            }
                            let sendCod = {
                                data: {
                                    "empresa": "AGUA DE RIEGO QUINDIALO",
                                    "accion": "RECIBO DE PAGO MULTA",
                                    "codigo": "MUL-" + dataMulta.id,
                                    "fecha": moment(dataMulta.modified).locale('es').format('LLL'),
                                    "valor": dataMulta.abono, "aporte": {
                                        "subperiodo": this.Periodo.subperiodo.nombre,
                                        "nombre": this.Periodo.multa.motivo,
                                        "detalle": this.Actividade.detalle,
                                        "fechaEvento": moment(this.Actividade.fecha).add(1, 'd').locale('es').format('LL'),
                                        "formaDePago": dataMulta.tipo
                                    },
                                    "usuario": {
                                        "identificador": dataMulta.socio.cedula,
                                        "nombre": dataMulta.socio.nombres,
                                        "apellido": dataMulta.socio.apellidos,
                                        "direccion": dataMulta.socio.direccion,
                                        "correo": dataMulta.socio.email,
                                        "telefono": dataMulta.socio.telefono,
                                        "celular": dataMulta.socio.celular
                                    },
                                    "sector": { "nombre": dataMulta.sectore.nombre },
                                    "tesorero": {
                                        "identificador": dataMulta.administrativo.socio.cedula,
                                        "nombre": dataMulta.administrativo.socio.nombres,
                                        "apellido": dataMulta.administrativo.socio.apellidos
                                    }
                                }, ip: this.ip
                            } as any;
                            this.servicioCobrosAportes.printerMulta(sendCod).subscribe();
                        });
                        let dialogRef = this.dialog.open(ImprimirMultasPagosComponent, this.config);
                        dialogRef.componentInstance.dataPdf = datapdf.datos.Multas.ruta;
                        dialogRef.result.then(
                            result => {
                            },
                            reason => {
                                console.log(reason);
                            }
                        );
                        this.ngxService.stopLoader('loaderPdfMultas');
                    }
                });
            } else {
                this.notificationServices.setNotification(datapdf);
            }
        });
    }

    cerrarModal() {
        this.activeModal.close(2);
    }



}