import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SociosRoutes } from './socios.routing';
import { SociosVistaComponent } from './vistas/sociovista.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MinutosComponent } from './minutos/minutos.component';
import { SimuladorComponent } from './simulador/simulador.component';
import { CalcularComponent } from './calcular/calcular.component';
import { ImpresionesModule } from '../reporte/impresiones/impresiones.module';
import { CalcularSectoresComponent } from './calcular-sectores/calcular-sectores.component';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(SociosRoutes),
		NgxDatatableModule,
		NgSelectModule,
		ImpresionesModule
	],
	declarations:[
		SociosVistaComponent,
		MinutosComponent,
		SimuladorComponent,
		CalcularComponent,
		CalcularSectoresComponent
	],
	exports:[
		MinutosComponent,
		CalcularComponent,
		CalcularSectoresComponent
	]
})

export class SociosModule {}
