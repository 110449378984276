import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Solicitudprestamos } from '../../../solicitudprestamos/solicitudprestamo/solicitudprestamos.model';
import { SolicitudprestamosServices } from '../../../solicitudprestamos/solicitudprestamo/solicitudprestamos.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { NgxUiLoaderService,SPINNER } from 'ngx-ui-loader';

@Component({
	selector: 'app-edit-motivo-cobros-multas',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditSolicitudesMotivoComponent implements OnInit {

	@Input() Solicitud:any;
	@Input() dataSubperiodo:Subperiodos;
	@Input() estado:number;
	public formCobroMultaMotivo:FormGroup;
	inconoLoder:string=SPINNER.wanderingCubes;
	
	userdata:any;
	htmlMotivo1="";
	htmlMotivo="";

	IP:number;
	TN:number;
    cuota:number;
    sumaInteres:number=0;
	total:number=0;
	verMotivo:boolean=false;
	solicitudPrestamo:any;
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private servicesSolicituds:SolicitudprestamosServices,
		private ngxService: NgxUiLoaderService,
		private Noti:NotificationServices,) { }

	ngOnInit(){
		this.ngxService.startLoader('loaderModifiSolicitud');
		this.servicesSolicituds.viewSolicitudprestamos(this.Solicitud.id).subscribe((data:any)=>{
			if(data.solicitudprestamo.prestamos.length===0){
				this.verMotivo=true;
				this.ngxService.stopLoader('loaderModifiSolicitud');
			}else{
				this.solicitudPrestamo=data.solicitudprestamo.prestamos[0];
				if(data.solicitudprestamo.prestamos[0].estado===0){
					this.verMotivo=true;
					this.ngxService.stopLoader('loaderModifiSolicitud');
				}
				this.ngxService.stopLoader('loaderModifiSolicitud');
			}
		});
		this.userdata=this.localdata.getValueFromLocalStorage();
		if(this.Solicitud.motivo){
			let estadoHtml="Aprobado";
			if(this.Solicitud.estado==2)
				estadoHtml="Anulado";
			this.htmlMotivo="<div class='row'>"
								+"<div class='text-muted label label-light-default'><b>Responsable: </b>"+this.Solicitud.administrativo.cargo+"</div><br>"
								+"<h5 class='text-muted m-b-0 label label-light-success'>"+this.Solicitud.administrativo.socio.cedula+" "+this.Solicitud.administrativo.socio.nombres+" "+this.Solicitud.administrativo.socio.apellidos+"</h5>"
								+"<div class='text-muted label label-light-default'><b>Motivo: </b>"+this.Solicitud.motivo+"</div><br>"
								+"<span class='m-b-0 label label-light-primary'><b>"+estadoHtml+"</b></span>"
							+"</div>";
		}
		
		
		this.formCobroMultaMotivo=this.fb.group({
            motivo:[null,Validators.compose([Validators.required,Validators.minLength(15), Validators.maxLength(50)])],
            estado:[this.estado,Validators.compose([Validators.required])],
		});

		this.generarTablaDeAmortizacion();

	}

	saveCobroMultaMotivo(){
		this.ngxService.startLoader('loaderModifiSolicitud');
		let SolicitudsDataModel:any=this.formCobroMultaMotivo.value;
		SolicitudsDataModel.id=this.Solicitud.id;
       	this.Solicitud.estado=SolicitudsDataModel.estado;
		this.Solicitud.motivo=SolicitudsDataModel.motivo;
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				
				this.Solicitud.administrativo_id=datadmin.administrativo.id;
				SolicitudsDataModel.administrativo_id=datadmin.administrativo.id;
				if(this.estado===2){
					this.htmlMotivo1="<hr><div class='row'>"
										+"<div class='text-muted label label-light-default'><b>Responsable: </b>"+datadmin.administrativo.cargo+"</div><br>"
										+"<h5 class='text-muted m-b-0 label label-light-success'>"+datadmin.administrativo.socio.cedula+" "+datadmin.administrativo.socio.nombres+" "+datadmin.administrativo.socio.apellidos+"</h5>"
										+"<div class='text-muted label label-light-default'><b>Motivo: </b>"+SolicitudsDataModel.motivo+"</div><br>"
										+"<span class='m-b-0 label label-light-primary'><b>Anulado</b></span>"
									+"</div>";
					SolicitudsDataModel.motivo=this.htmlMotivo+this.htmlMotivo1;
					this.Solicitud.motivo=SolicitudsDataModel.motivo;
				}
				SolicitudsDataModel.monto=this.Solicitud.monto;
				SolicitudsDataModel.cuota=this.cuota;
				SolicitudsDataModel.plazo=this.Solicitud.plazomeses;
				SolicitudsDataModel.total=this.total;
				SolicitudsDataModel.interesperiodico=this.sumaInteres;
				SolicitudsDataModel.subperiodo_id=this.dataSubperiodo.id;
				this.servicesSolicituds.updateSolicitudprestamos(SolicitudsDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.ngxService.stopLoader('loaderModifiSolicitud');
								this.activeModal.close(1);
							}
						});
					}else{
						this.ngxService.stopLoader('loaderModifiSolicitud');
						this.Noti.setNotification(data);
						this.htmlMotivo1="";
					}
				});
			});
		}
	}

	generarTablaDeAmortizacion(){
		this.TN=this.dataSubperiodo.tasanominal;
        this.sumaInteres=0;
        this.IP=(this.TN/100)/12;
        this.IP=Math.round(this.IP * 100000) / 100000;   
        this.cuota=(this.IP*this.Solicitud.monto)/(1-(1/Math.pow((1+this.IP),Number(this.Solicitud.plazomeses))));
        this.cuota=Math.round(this.cuota * 100) / 100;
        this.sumaInteres=(Number(this.Solicitud.plazomeses)*this.cuota)-this.Solicitud.monto;
        this.sumaInteres=Math.round(this.sumaInteres * 100) / 100;
        this.total=this.sumaInteres+this.Solicitud.monto;
    }

}