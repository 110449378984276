import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Aportes } from '../../aporte/aportes.model';
import { AportesServices } from '../../aporte/aportes.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';

@Component({
	selector: 'app-editaporte',
	templateUrl: './editaporte.component.html',
	styleUrls: ['./editaporte.component.scss']
})

export class EditAporteComponent implements OnInit {

	@Input() Aporte:Aportes;


	public formAporteNombre:FormGroup;
	public editNombre:boolean=false;


	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioAporte:AportesServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		//console.log("aporte",this.Aporte);
		this.formAporteNombre=this.fb.group({
			motivo:[this.Aporte.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editAporteNombreEnable(){
		this.editNombre=true;
		this.formAporteNombre=this.fb.group({
			motivo:[this.Aporte.motivo,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editAporteNombreDisable(){
		this.editNombre=false;
	}

	saveAporteNombre(){
		let AporteDataModel:Aportes=this.formAporteNombre.value;
		AporteDataModel.id=this.Aporte.id;
		this.Aporte.motivo=AporteDataModel.motivo;
		this.servicioAporte.updateAportes(AporteDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}

