import {Injectable} from '@angular/core';
import {TokenParams} from '../../global/tokenParams';

@Injectable()
export class localStorageServices {
    public setAuthorizationData(Auth:TokenParams):void{
        localStorage.setItem('Authorization',JSON.stringify(Auth));
    }

    public getValueFromLocalStorage():TokenParams{
        let datatoken= JSON.parse(localStorage.getItem('Authorization'));
        return datatoken==null ? null:datatoken;
    }

    public removeValueFromLocalStorage():void{
        localStorage.setItem('Authorization',JSON.stringify({}));
    }
}