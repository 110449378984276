import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Subperiodos } from '../../../subperiodos/subperiodo/subperiodos.model';
import { SubperiodosServices } from '../../../subperiodos/subperiodo/subperiodos.services';
import { CustomValidators } from 'ng2-validation';
import { tablaTazaAmortizacion } from '../../solicitudes/amortizacio.model';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-amortizacion',
	templateUrl: './amortizacion.component.html',
	styleUrls: ['./amortizacion.component.scss']
})

export class AmortizacionComponent implements OnInit {

	@Input() Subperiodo:Subperiodos;
  public formTablaAmortizacuon:FormGroup;
  IP:number;
  cuota:number;
  cuotaRows = [];
  sumaInteres:number=0;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
    private servicioSubperiodo:SubperiodosServices,
    private validatciones:customValidatorMondogo,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formTablaAmortizacuon=this.fb.group({
            monto:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
            meses:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(2,0)])],
		});
    }

    generarTablaDeAmortizacion(){
        this.sumaInteres=0;
        this.IP=(this.Subperiodo.tasanominal/100)/12;
        this.IP=Math.round(this.IP * 100000) / 100000;   
        this.cuota=(this.IP*this.formTablaAmortizacuon.value.monto)/(1-(1/Math.pow((1+this.IP),this.formTablaAmortizacuon.value.meses)));
        this.cuota=Math.round(this.cuota * 100) / 100;   
        this.generarTablaRow();
    }
    
    generarTablaRow(){
        this.cuotaRows=[];
        let saldo:number=0;
        let dataRow={} as tablaTazaAmortizacion;
        dataRow.index=1;
        let saldoinicial=this.formTablaAmortizacuon.value.monto;
        dataRow.saldoInicial=saldoinicial;
        let interes:number=dataRow.saldoInicial*this.IP;
        dataRow.interes=Math.round(this.trunc(interes,3) * 100) / 100;  
        dataRow.cuota=this.cuota;
        let amortizacion=dataRow.cuota-dataRow.interes;
        dataRow.amortzacion=Math.round(amortizacion * 100) / 100;  
        let saldofinal=dataRow.saldoInicial- dataRow.amortzacion;
        dataRow.saldoFinal=Math.round(saldofinal* 100) / 100; ;
        this.cuotaRows.push(dataRow);
        this.sumaInteres+=Math.round(this.trunc(interes,3) * 100) / 100;
        for (let index = 1; index < this.formTablaAmortizacuon.value.meses; index++) {
            let dataRowDos={} as tablaTazaAmortizacion;
            dataRowDos.index=index+1;
            let saldoinicial=this.cuotaRows[index-1].saldoFinal;
            dataRowDos.saldoInicial=saldoinicial;
            let interes=dataRowDos.saldoInicial*this.IP;
            dataRowDos.interes=Math.round(this.trunc(interes,3) * 100) / 100;
            this.sumaInteres+=Math.round(this.trunc(interes,3) * 100) / 100;
            dataRowDos.cuota=this.cuota;
            amortizacion=dataRowDos.cuota-dataRowDos.interes;
            dataRowDos.amortzacion=Math.round(amortizacion * 100) / 100;  
            let saldofinal=dataRowDos.saldoInicial- dataRowDos.amortzacion;
            dataRowDos.saldoFinal=Math.round(saldofinal * 100) / 100;
            if(index==this.formTablaAmortizacuon.value.meses-1)
            {
              saldo=(Math.round(saldofinal * 100) / 100)*-1;
              this.sumaInteres+=saldo;
              dataRowDos.amortzacion=(Math.round(amortizacion * 100) / 100)+(Math.round(saldofinal * 100) / 100);  
              dataRowDos.amortzacion=Math.round(this.trunc(dataRowDos.amortzacion,3) * 100) / 100; 
              dataRowDos.saldoFinal=0.00;

            } 
            this.cuotaRows.push(dataRowDos);
        }

        this.sumaInteres=Math.round(this.sumaInteres * 100) / 100;

        console.log(this.cuotaRows);
        
    }


    trunc (x, posiciones = 0) {
        var s = x.toString()
        var l = s.length
        var decimalLength = s.indexOf('.') + 1
      
        if (l - decimalLength <= posiciones){
          return x
        }
        // Parte decimal del número
        var isNeg  = x < 0
        var decimal =  x % 1
        var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
        // Parte decimal como número entero
        // Ejemplo: parte decimal = 0.77
        // decimalFormated = 0.77 * (10^posiciones)
        // si posiciones es 2 ==> 0.77 * 100
        // si posiciones es 3 ==> 0.77 * 1000
        var decimalFormated = Math.floor(
          Math.abs(decimal) * Math.pow(10, posiciones)
        )
        // Sustraemos del número original la parte decimal
        // y le sumamos la parte decimal que hemos formateado
        var finalNum = entera + 
          ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
        
        return finalNum
      }

    
    
}