import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Multas } from '../../multa/multas.model';
import { MultasServices } from '../../multa/multas.services';

@Component({
	selector: 'app-addmulta',
	templateUrl: './addmulta.component.html',
	styleUrls: ['./addmulta.component.scss']
})

export class AddMultaComponent implements OnInit {
	public formAddMulta: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioMulta:MultasServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddMulta = this.fb.group({
			motivo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(500)])],
			tipo:[1,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let multaDataModel:Multas=this.formAddMulta.value;
		this.servicioMulta.saveMultas(multaDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}