import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotoBeneficiariosComponent } from './foto/fotobeneficiario.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[FotoBeneficiariosComponent],
	entryComponents:[FotoBeneficiariosComponent],
})
export class ModalFotoBeneficiariosModule { }
