import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SociosServices } from '../socio/socios.services';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Socios } from '../socio/socios.model';
import { AddSocioComponent } from '../modal/add/addsocio.component';
import { EditSocioComponent } from '../modal/edit/editsocio.component';
import { Globals } from '../../../global/global.model';
import { FotoSociosComponent } from '../../fotosocios/foto/fotosocio.compente';
import { ReportesServices } from '../../reporte/reportes.services';
import { DomSanitizer } from '@angular/platform-browser';
import { AddRegistroComponent } from '../../registros/modal/add/addregistro.component';
import { RegistrosServices } from '../../registros/registro/registros.services';
import { SectoresServices } from '../../sectores/sectore/sectores.services';
import { ImprimirRecetaMedicaComponent } from '../../reporte/impresiones/imprimir/imprimir.component';

@Component({
	selector: 'ms-sociovista',
	templateUrl: './sociovista.component.html',
	styleUrls: ['./sociovista.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class SociosVistaComponent implements OnInit {
	rows = [];
	temp = [];
	public imgUrlSocios: string;
	pdfpath: any = "";
	public dataSectores: any;
	activos:number=0;
	inactivos:number=0;
	total: number = 0;
	buscar: string = '';
	dataPaging: any;
	numberPage: number = 1;
	@ViewChild('tableSocio') table;
	private isLoading: boolean = false;

	iconItems = [{
		text: 'Nuevo',
		icon: 'ti-file'
	}, {
		text: 'Refrescar',
		icon: 'ti-reload'
	}, {
		text: 'Pdf',
		icon: 'mdi mdi-file-pdf'
	}]

	configMotivo: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
	}

	config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}

	configPdf: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}


	select(text: string) {
		if (text == 'Refrescar') {
			this.listaSocios();
		} else if (text == 'Nuevo') {
			this.open();
		} else if (text == 'Pdf') {
			this.servicesReportes.generatePdfSocios().subscribe((datapdf: any) => {
				if (datapdf.estado) {
					this.notificationServices.setNotification(datapdf).then((datas) => {
						if (datas) {
							let dialogRef = this.dialog.open(ImprimirRecetaMedicaComponent, this.configPdf);
							dialogRef.componentInstance.dataPdf = datapdf.datos.Socios.ruta;
							dialogRef.componentInstance.titulo = 'LISTADO DE USUARIOS';
							dialogRef.result.then(
								result => {

								},
								reason => {
									console.log(reason);
								}
							);
						}
					});
				} else {
					this.notificationServices.setNotification(datapdf);
				}
			});
		}
	}

	constructor(public dialog: NgbModal,
		private serviciosSocios: SociosServices,
		private notificationServices: NotificationServices,
		public sanitizer: DomSanitizer,
		private serviciosRegistros: RegistrosServices,
		private servicioSectore: SectoresServices,
		private servicesReportes: ReportesServices,
	) { }

	ngOnInit() {
		this.imgUrlSocios = Globals.BASE_URL_API_REST;

		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			this.dataSectores = data.sectores;
		});
		this.listaSocios();
	}

	onChange(data: any) {
		this.rows = [];
		if (data != undefined) {
			this.serviciosSocios.buscarSociosPorSector(data).subscribe((data: any) => {

				this.rows = data.socios;
				this.inactivos=0;
				this.activos=0;
				this.rows.forEach(element => {
					if (element.pagos.length == 0) {
						element.pagos = [{ socio_id: element.id, cant: 0 }];
					}
					if (element.cobrosmultas.length == 0) {
						element.cobrosmultas = [{ socio_id: element.id, cant: 0 }];
					}
					
				});
				this.temp = [...this.rows];
				this.total = data.cantidad;
			});
		} else {
			this.listaSocios();
		}

	}

	listaSocios() {
		this.rows = [];
		this.serviciosSocios.getDataSociosVista().subscribe((data: any) => {

			this.rows = data.socios;
			this.rows.forEach(element => {
				if (element.pagos.length == 0) {
					element.pagos = [{ socio_id: element.id, cant: 0 }];
				}
				if (element.cobrosmultas.length == 0) {
					element.cobrosmultas = [{ socio_id: element.id, cant: 0 }];
				}

				if(element.estado==1){
					this.activos++;
				}else if(element.estado==0){
					this.inactivos++;
				}
			});
			this.temp = [...this.rows];
			this.total = data.cantidad;
		});
	}

	updateFilter(event) {

		this.buscar = event.target.value.toLowerCase();

	}

	findFilter() {
		this.rows = [];
		setTimeout(() => {
			const val = this.buscar;
			const temp = this.temp.filter(function (d) {
				return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
			});
			this.rows = temp;
		}, 2000);
	}

	open() {
		let dialogRef = this.dialog.open(AddSocioComponent, this.config);
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSocios();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	delete(dataSocio: any) {
		let data = { 'val': dataSocio.cedula, 'msg': 'Eliminar Socios' };
		this.notificationServices.msgEliminarConfir(data).then((datas) => {
			if (datas) {
				this.serviciosSocios.deleteSocios(dataSocio.id).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaSocios();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data: any) {
		let dialogRef = this.dialog.open(EditSocioComponent, this.config);
		dialogRef.componentInstance.Socio = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSocios();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	editEstado(dataSocio: Socios) {
		let dataEstadoSocio = {} as Socios;
		dataEstadoSocio.id = dataSocio.id;
		dataEstadoSocio.estado = Number(!dataSocio.estado);
		this.serviciosSocios.updateSocios(dataEstadoSocio).subscribe((data: any) => { });
	}

	editFotoSocios(data: any) {
		let dialogRef = this.dialog.open(FotoSociosComponent, this.configMotivo);
		dialogRef.componentInstance.Socio = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSocios();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	setPage(pageInfo) {
		this.isLoading = true;
		this.numberPage = pageInfo.offset + 1;
		this.serviciosSocios.getDataSociosVista().subscribe((data: any) => {

			this.rows = data.socios;
			this.temp = [...this.rows];
			this.isLoading = false;
		});

	}

	sector(data: any) {
		let dialogRef = this.dialog.open(AddRegistroComponent, this.config);
		dialogRef.componentInstance.Socio = data;
		dialogRef.result.then(
			result => {
				if (result === 1) {
					this.listaSocios();
				}
			},
			reason => {
				console.log(reason);
			}
		);
	}

	deletesector(dataRegistro: any) {
		let data = { 'val': dataRegistro.sectore.nombre, 'msg': 'Eliminar Registros' };
		this.notificationServices.msgEliminarConfir(data).then((datas) => {
			if (datas) {
				this.serviciosRegistros.deleteRegistros(dataRegistro.id).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaSocios();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}

	updatesector(dataRegistro: any) {

		let data = { 'val': dataRegistro.sectore.nombre, 'msg': 'Cancelar registros de sector' };
		this.notificationServices.setNotificationMotivo(data).then((datas: any) => {
			if (datas) {
				let sendInfo = { id: dataRegistro.id, estado: 0, motivo: datas };
				this.serviciosRegistros.updateRegistros(sendInfo).subscribe((smsdata: any) => {
					if (smsdata.estado) {
						this.notificationServices.setNotification(smsdata).then((datas) => {
							if (datas) {
								this.listaSocios();
							}
						});
					} else {
						this.notificationServices.setNotification(smsdata);
					}
				});
			}
		});
	}
}

