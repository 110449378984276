import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvidenciasComponent } from './foto/evidencia.compente';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { EvidenciasImagenesVistaComponent } from './imagenes/imagenes.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		NgbModule,
		ReactiveFormsModule,
	],
	declarations:[EvidenciasComponent,EvidenciasImagenesVistaComponent],
	entryComponents:[EvidenciasComponent,EvidenciasImagenesVistaComponent],
})
export class ModalEvidenciasModule { }