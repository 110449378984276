import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSectoreComponent } from './edit/editsectore.component';
import { AddSectoreComponent } from './add/addsectore.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditSectoreComponent,AddSectoreComponent],
	entryComponents:[EditSectoreComponent,AddSectoreComponent],
})
export class ModalSectoreModule { }
