import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAportesperiodoComponent } from './edit/editaportesperiodo.component';
import { AddAportesperiodoComponent } from './add/addaportesperiodo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditAportesperiodoComponent,AddAportesperiodoComponent],
	entryComponents:[EditAportesperiodoComponent,AddAportesperiodoComponent],
})
export class ModalAportesperiodoModule { }
