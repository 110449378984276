import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImprimirRecetaMedicaComponent } from './imprimir/imprimir.component';

@NgModule({
  declarations: [ImprimirRecetaMedicaComponent],
  imports: [
    CommonModule,
    NgxDatatableModule, 
    FormsModule,CustomFormsModule, 
    NgbModule, 
    ReactiveFormsModule, 
  ],
  entryComponents:[
    ImprimirRecetaMedicaComponent
  ]
})
export class ImpresionesModule { }
