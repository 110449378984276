import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Multas} from './multas.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class MultasServices {

	constructor(private http: HttpClient) { }

	getDataMultas(page:number):Observable<Multas[]>{
		return this.http.get<Multas[]>(Globals.BASE_URL_API_REST+'multas.json?page='+page);
	}

	saveMultas(data:Multas){
		return this.http.post(Globals.BASE_URL_API_REST+'multas.json',data);
	}

	deleteMultas(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'multas/'+id+'.json');
	}

	updateMultas(data:Multas){
		 return this.http.patch(Globals.BASE_URL_API_REST+'multas/'+data.id+'.json',data);
	}

	viewMultas(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'multas/'+id+'.json');
	}

	viewMultasSubperiodo(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'listamultas.json',data);
	}

	saveMultasYPeriodo(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'listamultas.json',data);
	}

	getMultasSocio(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'multasocios.json',data);
	}

}