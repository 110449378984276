import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Multasperiodos} from './multasperiodos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Subperiodos } from '../../subperiodos/subperiodo/subperiodos.model';


@Injectable()
export class MultasperiodosServices {

	constructor(private http: HttpClient) { }

	getDataMultasperiodos():Observable<Multasperiodos[]>{
		return this.http.get<Multasperiodos[]>(Globals.BASE_URL_API_REST+'multasperiodos.json');
	}

	saveMultasperiodos(data:Multasperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'multasperiodos.json',data);
	}

	deleteMultasperiodos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'multasperiodos/'+id+'.json');
	}

	updateMultasperiodos(data:Multasperiodos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'multasperiodos/'+data.id+'.json',data);
	}

	viewMultasperiodos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'multasperiodos/'+id+'.json');
	}

	viewMultasPeriodosAsignados(data:Subperiodos){
		return this.http.put(Globals.BASE_URL_API_REST+'multasasignadas.json',data);
	}

	viewMultasPeriodosAsignadosConValores(data:Subperiodos){
		return this.http.patch(Globals.BASE_URL_API_REST+'multasasignadas.json',data);
	}
	
	viewMultasPeriodosAsignadosConValoresTemporales(data:Subperiodos){
		return this.http.post(Globals.BASE_URL_API_REST+'multasasignadas.json',data);
   	}
}