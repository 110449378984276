import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditPeriodoComponent } from './edit/editperiodo.component';
import { AddPeriodoComponent } from './add/addperiodo.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[EditPeriodoComponent,AddPeriodoComponent],
	entryComponents:[EditPeriodoComponent,AddPeriodoComponent],
})
export class ModalPeriodoModule { }
