import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Sectores } from '../../sectore/sectores.model';
import { SectoresServices } from '../../sectore/sectores.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-editsectore',
	templateUrl: './editsectore.component.html',
	styleUrls: ['./editsectore.component.scss']
})

export class EditSectoreComponent implements OnInit {

	@Input() Sectore:Sectores;

	public formSectoreNombre:FormGroup;
	public editNombre:boolean=false;

	public formSectoreEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioSectore:SectoresServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formSectoreNombre=this.fb.group({
			nombre:[this.Sectore.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}
	

	editSectoreNombreEnable(){
		this.editNombre=true;
		this.formSectoreNombre=this.fb.group({
			nombre:[this.Sectore.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(150)])],
		});
	}

	editSectoreNombreDisable(){
		this.editNombre=false;
	}

	saveSectoreNombre(){
		let SectoreDataModel:Sectores=this.formSectoreNombre.value;
		SectoreDataModel.id=this.Sectore.id;
		this.Sectore.nombre=SectoreDataModel.nombre;
		this.servicioSectore.updateSectores(SectoreDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

