import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { AbstractControl,ValidatorFn } from '@angular/forms';
import { Fecha } from './fecha.model';

@Injectable()
export class customValidatorMondogo {

    ageRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
        if (control.value !== undefined && (isNaN(control.value) || control.value < 1 || control.value > 500)) {
            return { 'ageRange': true };
        }
        return null;
    }

    numeric(control: AbstractControl) {
        let val = control.value;
        if (val === null || val === '') return null;
        if (!val.toString().match(/^[0-9]+(\.?[0-9]+)?$/)) return { 'invalidNumber': true };
        return null;
      }
      
      digitoVerificador(control: AbstractControl) {
        let val = control.value;
        if (val === null || val === '') return null;
        if(val){
            var array = val.split("");
            if (array.length == 10) {
                var total = 0;
                var digito = (array[9] * 1);
                for (var i = 0; i < 9; i++) {
                    var mult = 0;
                    if ((i % 2) != 0) {
                        total = total + (array[i] * 1);
                    }else{
                        mult = array[i] * 2;
                        if (mult > 9)
                            total = total + (mult - 9);
                        else
                            total = total + mult;
                    }
                }
              var decena = total / 10;
              decena = Math.floor(decena);
              decena = (decena + 1) * 10;
              var final = (decena - total);
              if ((final == 10 && digito == 0) || (final == digito)) 
                return null;
              else 
                return { 'invalidNumber': true }
          }
        }
        return null;     		
    }

    Cedula(cedula: any) {
        
        if(cedula){
            var array = cedula.split("");
            if (array.length == 10) {
                var total = 0;
                var digito = (array[9] * 1);
                for (var i = 0; i < 9; i++) {
                    var mult = 0;
                    if ((i % 2) != 0) {
                        total = total + (array[i] * 1);
                    }else{
                        mult = array[i] * 2;
                        if (mult > 9)
                            total = total + (mult - 9);
                        else
                            total = total + mult;
                    }
                }
              var decena = total / 10;
              decena = Math.floor(decena);
              decena = (decena + 1) * 10;
              var final = (decena - total);
              if ((final == 10 && digito == 0) || (final == digito)) 
                return true;
              else 
                return false;
          }
        }   		
    }

    cakePhpValidateDate(data:any):Fecha{
        let inicio= {} as Fecha;
		inicio.year=Number(data.split('-')[0]);
		inicio.month=Number(data.split('-')[1]);
		inicio.day=Number(data.split('-')[2]);
        return inicio;
    }

    checkLimit(enteros: number, decimal: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if(c.value){
                let cadena=c.value.toString();
                if(cadena.indexOf('.') != -1){
                    let valor=cadena.split('.');
                    if (c.value && (valor[0].length > enteros || valor[1].length > decimal) ) {
                        if(valor[0].length > enteros){
                            c.setValue(valor[0].substring(0,enteros)+'.'+valor[1]);
                            return { 'entero': true };
                        }else if(valor[1].length > decimal){
                            c.setValue(valor[0]+'.'+valor[1].substring(0,decimal));
                            return { 'decimal': true };
                        }
                       
                    }
                }else{
                    if (c.value && cadena.length>enteros ) {
                        c.setValue(cadena.substring(0,enteros));
                        return { 'entero': true };
                    }
                }
            }
            
            return null;
        };
    }

}