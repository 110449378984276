import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Horarios } from '../../horario/horarios.model';
import { HorariosServices } from '../../horario/horarios.servicies';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-edit-motivo-horarios-multas',
	templateUrl: './changemotivo.component.html',
	styleUrls: ['./changemotivo.component.scss']
})

export class EditHorariosMotivoComponent implements OnInit {

	@Input() Horario:Horarios;
	public formHorarioMotivo:FormGroup;
	userdata:any;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		public localdata:localStorageServices,
		private servicioadministrativo:AdministrativosServices,
		private servicesHorarios:HorariosServices,
		private Noti:NotificationServices,) { }

	ngOnInit(){
		this.userdata=this.localdata.getValueFromLocalStorage();
		this.formHorarioMotivo=this.fb.group({
            motivo:[this.Horario.motivo,Validators.compose([Validators.required,Validators.minLength(15), Validators.maxLength(50)])],
            estado:[2,Validators.compose([Validators.required])],
		});
	}

	saveHorarioMotivo(){
		let HorariosDataModel:Horarios=this.formHorarioMotivo.value;
		HorariosDataModel.id=this.Horario.id;
        this.Horario.motivo=HorariosDataModel.motivo;
		this.Horario.estado=HorariosDataModel.estado;
		
		if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
				this.Horario.administrativo_id=datadmin.administrativo.id;
				HorariosDataModel.administrativo_id=datadmin.administrativo.id;
				this.servicesHorarios.updateHorarios(HorariosDataModel).subscribe((data:any)=>{
					if(data.estado){
						this.Noti.setNotification(data).then((datas)=>{
							if(datas){
								this.activeModal.close(1);
							}
						});
					}else{
						this.Noti.setNotification(data);
					}
				});
			});
		}
	}

}