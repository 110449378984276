import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl,FormGroupDirective } from '@angular/forms';
import { Users } from '../../user/users.model';
import { CustomValidators } from 'ng2-validation';
import {UserServices} from '../../user/users.services';
import {NotificationServices} from '../../../notification/notificatio.services';
import {GroupsServices} from '../../../group-pages/groups/groups.services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { customValidatorMondogo } from '../../../../appccamg/custom-validator/customValidator.services';

@Component({
    selector: 'app-add-user',
    templateUrl:'./useraddmodal-component.html',
    styleUrls: ['./useraddmodal.component.scss'],
    encapsulation: ViewEncapsulation.None
  })
  export class UserAddModalComponent implements OnInit {
    public formAddUser: FormGroup;
    breakpoint: number;
    ratio = '4:1';
    groupdata:any;
    public dataSocios:any;
    dataSocio:any;
    vertipo:boolean=false;
    ced:boolean=false;
    pass:boolean=false;

    constructor(
                private fb: FormBuilder,
                private servicesUser:UserServices,
                private servicesGroup:GroupsServices,
                private validatciones:customValidatorMondogo,
                private activeModal:NgbActiveModal,
                private Noti:NotificationServices,) {}
    
    ngOnInit(){
      let data={};

      this.servicesUser.buscarSociosUser(data).subscribe((datap:any)=>{
        this.dataSocios=datap.socios;
      });	
      
      this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
      this.ratio=(window.innerWidth <= 800) ? '4:1.6':'4:1';
      
      this.servicesGroup.getDataGroups().subscribe((data:any)=>{
        this.groupdata=data.groups;
        
      });
      this.formAddUser = this.fb.group({
        username: [null, Validators.compose([Validators.required, CustomValidators.email])],
        fullname: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
        identificador: [null,Validators.compose([Validators.required,Validators.minLength(10),Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
        group_id: [null, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])],
        estado: [1,null],
      });
    }

    saveUser(){
      
      let userDataModel:Users=this.formAddUser.value;
      if(this.formAddUser.value.group_id==1){
        userDataModel.identificador=null;
      }
      this.servicesUser.saveUser(userDataModel).subscribe((data:any)=>{
        if(data.estado){
          this.Noti.setNotification(data).then((datas)=>{
              if(datas){
                this.activeModal.close(1);
              }
          });
        
        }else{
          this.Noti.setNotification(data);
        }
      });
    }

    onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
      this.ratio=(event.target.innerWidth <= 800) ? '4:1.6':'4:1';
      
    }

    tipo(data:any){
        if(Number(data.target.value)===1){
          this.vertipo=false;
          this.formAddUser = this.fb.group({
            username: [this.dataSocio.email, Validators.compose([Validators.required, CustomValidators.email])],
            fullname: [this.dataSocio.nombres+" "+this.dataSocio.apellidos, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
            identificador: [this.dataSocio.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
            group_id: [null, Validators.compose([Validators.required])],
            password: [null, Validators.compose([Validators.required])],
            estado: [1,null],
          });
        }else{
          this.vertipo=true;
          this.formAddUser = this.fb.group({
            username: [this.dataSocio.email, Validators.compose([Validators.required, CustomValidators.email])],
            fullname: [this.dataSocio.nombres+" "+this.dataSocio.apellidos, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
            identificador: [this.dataSocio.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
            group_id: [null, Validators.compose([Validators.required])],
            password: [null, Validators.compose([Validators.required])],
            estado: [1,null],
          });
        }
        
    }

    seleccionar(data:any){
     this.dataSocio=data;
      
      if(this.validatciones.Cedula(this.dataSocio.cedula)){
        this.ced=true;
        this.pass=false;
      }else{
        this.ced=false;
        this.pass=true;
      }

     if(this.vertipo){
        this.formAddUser = this.fb.group({
          username: [this.dataSocio.email, Validators.compose([Validators.required, CustomValidators.email])],
          fullname: [this.dataSocio.nombres+" "+this.dataSocio.apellidos, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
          identificador: [this.dataSocio.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
          group_id: [null, Validators.compose([Validators.required])],
          password: [null, Validators.compose([Validators.required])],
          estado: [1,null],
        });
     }else{
      this.formAddUser = this.fb.group({
        username: [this.dataSocio.email, Validators.compose([Validators.required, CustomValidators.email])],
        fullname: [this.dataSocio.nombres+" "+this.dataSocio.apellidos, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(250)])],
        identificador: [this.dataSocio.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
        group_id: [null, Validators.compose([Validators.required])],
        password: [null, Validators.compose([Validators.required])],
        estado: [1,null],
      });
     }
    }

    cerrarModal(){
      this.activeModal.close(2);
    }

  }

  