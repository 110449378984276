import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule,registerLocaleData } from '@angular/common';
import { NgModule,LOCALE_ID } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

//New modules Will
import {AppauthModule} from './appauth/appauth.module';
import { MatDialogModule } from '@angular/material';
import { AppAppccamgModule } from './appccamg/appccamg.module';
import { DeviceDetectorModule } from 'ngx-device-detector';

import localeEsEC from '@angular/common/locales/es-EC';
registerLocaleData(localeEsEC, 'es-EC');

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from  'ngx-ui-loader';
import { ListaImpresorasServices } from './global/global.services';
import { AppCobrosModule } from './appcobros/appcobros.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { AppComponenteElementModule } from './appcomponente/appcomponenent.module';

@NgModule({
  declarations: [AppComponent, SpinnerComponent, FullComponent, BlankComponent, NavigationComponent, BreadcrumbComponent, SidebarComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(Approutes,{useHash: true}),
    DeviceDetectorModule.forRoot(),
    PerfectScrollbarModule,
    AppauthModule,
    AppAppccamgModule,
    AppCobrosModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule,
    AuthenticationModule,
    AppComponenteElementModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: LOCALE_ID, useValue: 'es-EC' },
    ListaImpresorasServices
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
