import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Socios } from '../../socio/socios.model';
import { SociosServices } from '../../socio/socios.services';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-addsocio',
	templateUrl: './addsocio.component.html',
	styleUrls: ['./addsocio.component.scss']
})

export class AddSocioComponent implements OnInit {
	public formAddSocio: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private validatciones:customValidatorMondogo,
		private servicioSocio:SociosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.formAddSocio = this.fb.group({
			tipo:[1,Validators.compose([Validators.required])],
			cedula: [null,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			nombres:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
			apellidos:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
			direccion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
			telefono:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
			celular:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
			email:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300), Validators.email])],
			estado:[1,null],
		});
	}


	save(){
		let socioDataModel:Socios=this.formAddSocio.value;
		this.servicioSocio.saveSocios(socioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	tipo(data:any){
        if(Number(data.target.value)===1){
          	this.formAddSocio = this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [null,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
				nombres:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
				apellidos:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
				direccion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
				telefono:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
				celular:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
				email:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300), Validators.email])],
				estado:[1,null],
			});
        }else{
          
		  this.formAddSocio = this.fb.group({
			tipo:[2,Validators.compose([Validators.required])],
			cedula: [null,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			nombres:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
			apellidos:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
			direccion:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
			telefono:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
			celular:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
			email:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300), Validators.email])],
			estado:[1,null],
		});
        }
        
	}
	
	cerrarModal(){
		this.activeModal.close(2);
	}
}