import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Administrativos } from '../../administrativo/administrativos.model';
import { AdministrativosServices } from '../../administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Periodos } from '../../../periodos/periodo/periodos.model';
import { PeriodosServices } from '../../../periodos/periodo/periodos.services';

@Component({
	selector: 'app-addadministrativo',
	templateUrl: './addadministrativo.component.html',
	styleUrls: ['./addadministrativo.component.scss']
})

export class AddAdministrativoComponent implements OnInit {
	public dataSocios:any;
	public dataPeriodos:any;
	public formAddAdministrativo: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioAdministrativo:AdministrativosServices,
		private servicioSocio:SociosServices,
		private servicioPeriodo:PeriodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.dataSocios=data.socios;
		});
		this.servicioPeriodo.getDataPeriodos().subscribe((data:any)=>{
			this.dataPeriodos=data.periodos;
		});
		this.formAddAdministrativo = this.fb.group({
			socio_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			periodo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			cargo:[null,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50)])],
			estado:[1,null],
		});
	}


	save(){
		let administrativoDataModel:Administrativos=this.formAddAdministrativo.value;
		this.servicioAdministrativo.saveAdministrativos(administrativoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}