import { Component } from '@angular/core';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html'
})
export class LockComponent {
  constructor() {}

}
