import { Component, OnInit } from '@angular/core';
import { ConsultasAportesMutlasServices } from '../servicios/consultas.service';

@Component({
  selector: 'app-home-page-consultas',
  templateUrl: './home-page-consultas.component.html',
  styleUrls: ['./home-page-consultas.component.scss']
})
export class HomePageConsultasComponent implements OnInit {

  dataSocios = { 'nombres': '' } as any;
  public imgUrlSocios: string;
  rowsocios: any;
  deviceInfo = null;
  resultDevices: boolean = false;
  dataUsuario: any;
  infoCobros: any;
  verMonto: boolean = true;

  constructor(private servicio: ConsultasAportesMutlasServices) { }

  ngOnInit() {
  }

  buscarEvento(event) {
    const val = event.target.value.toLowerCase();
    this.dataSocios.nombres = val;
  }

  eviarBusqueda() {
    this.dataUsuario=undefined;
    this.infoCobros=undefined;
    this.servicio.getDataConsultaEstadoSocio(this.dataSocios.nombres).subscribe((data: any) => {
      if (data.socios && data.socios.id != undefined) {
				this.dataUsuario = data.socios;
				
			}
    });
  }

  listaCobros() {
    this.servicio.getDataDetalleCobros(this.dataUsuario).subscribe((dataCobro: any) => {
      this.infoCobros = dataCobro.socios;
      this.verMonto = true;
    });
  }

}
