import { Socios } from "../../socios/socio/socios.model";

export class Asistencias{
	id?:number;
	motivo?:string;
	socio_id?:number;
	actividade_id?:number;
	administrativo_id?:number;
	sectore_id?:number;
	estado?:number;
	created?:Date;
	modified?:Date;
	socio?:Socios;
}