import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Administrativos} from './administrativos.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';


@Injectable()
export class AdministrativosServices {

	constructor(private http: HttpClient) { }

	getDataAdministrativos():Observable<Administrativos[]>{
		return this.http.get<Administrativos[]>(Globals.BASE_URL_API_REST+'administrativos.json');
	}

	saveAdministrativos(data:Administrativos){
		return this.http.post(Globals.BASE_URL_API_REST+'administrativos.json',data);
	}

	deleteAdministrativos(id:number){
		return this.http.delete(Globals.BASE_URL_API_REST+'administrativos/'+id+'.json');
	}

	updateAdministrativos(data:Administrativos){
		 return this.http.patch(Globals.BASE_URL_API_REST+'administrativos/'+data.id+'.json',data);
	}

	viewAdministrativos(id:number){
		 return this.http.get(Globals.BASE_URL_API_REST+'administrativos/'+id+'.json');
	}

	viewAdministrativosPeriodos(data:Administrativos){
		return this.http.patch(Globals.BASE_URL_API_REST+'administradoresperiodo.json',data);
	}

	viewAdministrativosPorSocio(data:Administrativos){
		return this.http.put(Globals.BASE_URL_API_REST+'administradoresperiodo.json',data);
    }
}