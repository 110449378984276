import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'; 
import { localStorageServices } from './localStorageServices';

@Injectable()
export class AuthLoginGuard implements CanActivate {
 
    constructor(private router: Router, private localdata:localStorageServices) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log(this.localdata.getValueFromLocalStorage());
        
        if(JSON.stringify(this.localdata.getValueFromLocalStorage())=='null' || JSON.stringify(this.localdata.getValueFromLocalStorage())=='{}' ){
            return true;
        }

        this.router.navigate(['home']);
        return false;
    }
}