import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Periodos } from '../../periodo/periodos.model';
import { PeriodosServices } from '../../periodo/periodos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import * as moment from 'moment';
@Component({
	selector: 'app-editperiodo',
	templateUrl: './editperiodo.component.html',
	styleUrls: ['./editperiodo.component.scss']
})

export class EditPeriodoComponent implements OnInit {

	@Input() Periodo:Periodos;

	public formPeriodoNombre:FormGroup;
	public editNombre:boolean=false;

	public formPeriodoFechainicio:FormGroup;
	public editFechainicio:boolean=false;

	public formPeriodoFechafin:FormGroup;
	public editFechafin:boolean=false;

	public formPeriodoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPeriodo:PeriodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		this.formPeriodoNombre=this.fb.group({
			nombre:[this.Periodo.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});

		this.formPeriodoFechainicio=this.fb.group({
			fechainicio:[moment(this.Periodo.fechainicio).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
		
		this.formPeriodoFechafin=this.fb.group({
			fechafin:[moment(this.Periodo.fechafin).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}
	

	editPeriodoNombreEnable(){
		this.editNombre=true;
		this.formPeriodoNombre=this.fb.group({
			nombre:[this.Periodo.nombre,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editPeriodoNombreDisable(){
		this.editNombre=false;
	}

	savePeriodoNombre(){
		let PeriodoDataModel:Periodos=this.formPeriodoNombre.value;
		PeriodoDataModel.id=this.Periodo.id;
		this.Periodo.nombre=PeriodoDataModel.nombre;
		this.servicioPeriodo.updatePeriodos(PeriodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombre=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPeriodoFechainicioEnable(){
		this.editFechainicio=true;
		this.formPeriodoFechainicio=this.fb.group({
			fechainicio:[moment(this.Periodo.fechainicio).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editPeriodoFechainicioDisable(){
		this.editFechainicio=false;
	}

	savePeriodoFechainicio(){
		let PeriodoDataModel:Periodos=this.formPeriodoFechainicio.value;
		PeriodoDataModel.id=this.Periodo.id;
		this.Periodo.fechainicio=PeriodoDataModel.fechainicio;
		this.servicioPeriodo.updatePeriodos(PeriodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFechainicio=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPeriodoFechafinEnable(){
		this.editFechafin=true;
		this.formPeriodoFechafin=this.fb.group({
			fechafin:[moment(this.Periodo.fechafin).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editPeriodoFechafinDisable(){
		this.editFechafin=false;
	}

	savePeriodoFechafin(){
		let PeriodoDataModel:Periodos=this.formPeriodoFechafin.value;
		PeriodoDataModel.id=this.Periodo.id;
		this.Periodo.fechafin=PeriodoDataModel.fechafin;
		this.servicioPeriodo.updatePeriodos(PeriodoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFechafin=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
	
}

