import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Pagos } from '../../pago/pagos.model';
import { PagosServices } from '../../pago/pagos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Eventos } from '../../../eventos/evento/eventos.model';
import { EventosServices } from '../../../eventos/evento/eventos.services';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';

@Component({
	selector: 'app-addpago',
	templateUrl: './addpago.component.html',
	styleUrls: ['./addpago.component.scss']
})

export class AddPagoComponent implements OnInit {
	public dataSocios:any;
	public dataEventos:any;
	public dataAdministrativos:any;
	public formAddPago: FormGroup;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPago:PagosServices,
		private servicioSocio:SociosServices,
		private servicioEvento:EventosServices,
		private servicioAdministrativo:AdministrativosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioSocio.getDataSocios().subscribe((data:any)=>{
			this.dataSocios=data.socios;
		});
		this.servicioEvento.getDataEventos().subscribe((data:any)=>{
			this.dataEventos=data.eventos;
		});
		this.servicioAdministrativo.getDataAdministrativos().subscribe((data:any)=>{
			this.dataAdministrativos=data.administrativos;
		});
		this.formAddPago = this.fb.group({
			tipo:[null,Validators.compose([Validators.required,CustomValidators.number])],
			socio_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			evento_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			administrativo_id:[null,Validators.compose([Validators.required,CustomValidators.number])],
			estado:[1,null],
		});
	}


	save(){
		let pagoDataModel:Pagos=this.formAddPago.value;
		this.servicioPago.savePagos(pagoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.activeModal.close(1);
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}
}