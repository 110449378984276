import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../../global/global.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Rx';


@Injectable()
export class EvidenciasServices {

	constructor(private http: HttpClient) { }

	saveEvidencia(data:any){
		return this.http.post(Globals.BASE_URL_API_REST+'evidencias.json',data);
	}

	viewEvidencias(data:any):Observable<any[]>{
		return this.http.put<any[]>(Globals.BASE_URL_API_REST+'listaevidencia.json',data);
    }

}