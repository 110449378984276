import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { FotoBeneficiariosServices } from '../fotobeneficiario/fotobeneficiarios.services';

@Component({
	selector: 'app-add-foto-beneficiario',
	templateUrl: './fotobeneficiario.compente.html',
	styleUrls: ['./fotobeneficiario.compente.scss']
})

export class FotoBeneficiariosComponent implements OnInit {
    
    @Input() Beneficiario:any;
    
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioFotoBeneficiarios:FotoBeneficiariosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		console.log(this.Beneficiario);
        
	}


	onFileChange(event) {
    if(event.target.files.length > 0) {
        var file = event.target.files[0];
        var formData = new FormData();
        
        formData.append('filename', file);
        formData.append('beneficiario_id',this.Beneficiario.id);
        this.servicioFotoBeneficiarios.saveFotoBeneficiario(formData).subscribe((data:any)=>{
        if(data.estado){
            this.Noti.setNotification(data).then((datas)=>{
            if(datas){
                this.activeModal.close(1);
            }
            }); 
            
        }else{
            this.Noti.setNotification(data);
        }
        });
    }
    }

}