import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditBeneficiarioComponent } from './edit/editbeneficiario.component';
import { AddBeneficiarioComponent } from './add/addbeneficiario.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
	],
	declarations:[EditBeneficiarioComponent,AddBeneficiarioComponent],
	entryComponents:[EditBeneficiarioComponent,AddBeneficiarioComponent],
})
export class ModalBeneficiarioModule { }
