import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Socios } from '../../socio/socios.model';
import { SociosServices } from '../../socio/socios.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-editsocio',
	templateUrl: './editsocio.component.html',
	styleUrls: ['./editsocio.component.scss']
})

export class EditSocioComponent implements OnInit {

	@Input() Socio:Socios;

	public formSocioCedula:FormGroup;
	public editCedula:boolean=false;

	public formSocioNombres:FormGroup;
	public editNombres:boolean=false;

	public formSocioApellidos:FormGroup;
	public editApellidos:boolean=false;

	public formSocioDireccion:FormGroup;
	public editDireccion:boolean=false;

	public formSocioTelefono:FormGroup;
	public editTelefono:boolean=false;

	public formSocioCelular:FormGroup;
	public editCelular:boolean=false;

	public formSocioEmail:FormGroup;
	public editEmail:boolean=false;

	public formSocioEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private validatciones:customValidatorMondogo,
		private servicioSocio:SociosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {

		if(this.Socio.tipo==1){
			this.formSocioCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.Socio.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formSocioCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Socio.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
		

		this.formSocioNombres=this.fb.group({
			nombres:[this.Socio.nombres,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});

		this.formSocioApellidos=this.fb.group({
			apellidos:[this.Socio.apellidos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});

		this.formSocioDireccion=this.fb.group({
			direccion:[this.Socio.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});

		this.formSocioTelefono=this.fb.group({
			telefono:[this.Socio.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
		});

		this.formSocioCelular=this.fb.group({
			celular:[this.Socio.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
		});

		this.formSocioEmail=this.fb.group({
			email:[this.Socio.email,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}
	

	editSocioCedulaEnable(){
		this.editCedula=true;
		this.formSocioCedula=this.fb.group({
			cedula: [this.Socio.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
		});
	}

	editSocioCedulaDisable(){
		this.editCedula=false;
	}

	saveSocioCedula(){
		let SocioDataModel:Socios=this.formSocioCedula.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.cedula=SocioDataModel.cedula;
		this.Socio.tipo=SocioDataModel.tipo;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCedula=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSocioNombresEnable(){
		this.editNombres=true;
		this.formSocioNombres=this.fb.group({
			nombres:[this.Socio.nombres,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});
	}

	editSocioNombresDisable(){
		this.editNombres=false;
	}

	saveSocioNombres(){
		let SocioDataModel:Socios=this.formSocioNombres.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.nombres=SocioDataModel.nombres;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editNombres=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSocioApellidosEnable(){
		this.editApellidos=true;
		this.formSocioApellidos=this.fb.group({
			apellidos:[this.Socio.apellidos,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(50), Validators.pattern('^[a-zA-ZñÑáéíóúÁÉÍÓÚ. ]+$')])],
		});
	}

	editSocioApellidosDisable(){
		this.editApellidos=false;
	}

	saveSocioApellidos(){
		let SocioDataModel:Socios=this.formSocioApellidos.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.apellidos=SocioDataModel.apellidos;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editApellidos=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSocioDireccionEnable(){
		this.editDireccion=true;
		this.formSocioDireccion=this.fb.group({
			direccion:[this.Socio.direccion,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}

	editSocioDireccionDisable(){
		this.editDireccion=false;
	}

	saveSocioDireccion(){
		let SocioDataModel:Socios=this.formSocioDireccion.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.direccion=SocioDataModel.direccion;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editDireccion=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSocioTelefonoEnable(){
		this.editTelefono=true;
		this.formSocioTelefono=this.fb.group({
			telefono:[this.Socio.telefono,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(9), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(9,0)])],
		});
	}

	editSocioTelefonoDisable(){
		this.editTelefono=false;
	}

	saveSocioTelefono(){
		let SocioDataModel:Socios=this.formSocioTelefono.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.telefono=SocioDataModel.telefono;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editTelefono=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSocioCelularEnable(){
		this.editCelular=true;
		this.formSocioCelular=this.fb.group({
			celular:[this.Socio.celular,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(10), Validators.pattern('^[0-9]+$'),this.validatciones.checkLimit(10,0)])],
		});
	}

	editSocioCelularDisable(){
		this.editCelular=false;
	}

	saveSocioCelular(){
		let SocioDataModel:Socios=this.formSocioCelular.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.celular=SocioDataModel.celular;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editCelular=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editSocioEmailEnable(){
		this.editEmail=true;
		this.formSocioEmail=this.fb.group({
			email:[this.Socio.email,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(300)])],
		});
	}

	editSocioEmailDisable(){
		this.editEmail=false;
	}

	saveSocioEmail(){
		let SocioDataModel:Socios=this.formSocioEmail.value;
		SocioDataModel.id=this.Socio.id;
		this.Socio.email=SocioDataModel.email;
		this.servicioSocio.updateSocios(SocioDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editEmail=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	tipo(data:any){
		if(Number(data.target.value)===1){
			this.formSocioCedula=this.fb.group({
				tipo:[1,Validators.compose([Validators.required])],
				cedula: [this.Socio.cedula,Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]*'),this.validatciones.digitoVerificador])],
			});
		}else{
			this.formSocioCedula=this.fb.group({
				tipo:[2,Validators.compose([Validators.required])],
				cedula: [this.Socio.cedula,Validators.compose([Validators.required,Validators.minLength(5),Validators.maxLength(50)])],
			});
		}
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

