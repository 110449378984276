import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { Globals, Impresora } from './global.model';


@Injectable()
export class ListaImpresorasServices {

	constructor(private http: HttpClient) { }

	viewAdministradoresImpresoras(data:any){
		return this.http.put(Globals.BASE_URL_API_REST+'administradoresperiodo.json',data);
    }
    
    saveImpresoras(data:Impresora){
		return this.http.post(Globals.BASE_URL_API_REST+'impresoras.json',data);
    }
    
    updateImpresoras(data:Impresora){
        return this.http.patch(Globals.BASE_URL_API_REST+'impresoras/'+data.id+'.json',data);
    }

}