import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {Globals} from '../../../global/global.model';
import {Groups} from './groups.model';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable()
export class GroupsServices {
    constructor(private http: HttpClient) { }
    
    getDataGroups():Observable<Groups[]>{
        return this.http.get<Groups[]>(Globals.BASE_URL_API_REST+'groups.json');
    }
}
