import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Globals } from '../../../global/global.model';

import 'rxjs/Rx';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class ElementComponentService {

    constructor(private http: HttpClient) { }

    getDataPeriodosElemento(): Observable<any[]> {
        return this.http.get<any[]>(Globals.BASE_URL_API_REST + 'periodos.json');
    }

}