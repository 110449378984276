import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cuotas } from '../../cuota/cuotas.model';
import { CuotasServices } from '../../cuota/cuotas.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { Administrativos } from '../../../administrativos/administrativo/administrativos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { EditCuotaComponent } from '../edit/editcuota.component';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';
import { customValidatorMondogo } from '../../../custom-validator/customValidator.services';

@Component({
	selector: 'app-edit-cuotas-pagar',
	templateUrl: './listcuotaspagadas.component.html',
	styleUrls: ['./listcuotaspagadas.component.scss']
})

export class ListaCuotaPagarFechasPagoComponent implements OnInit {

	@Input() Pago:any;
    public imgUrlSocios:string;
    inconoLoder:string=SPINNER.wanderingCubes;
    userdata:any;
    temp = [];
    rows = [];
    dataPago:Pagos;
    totalAbono:number=0;
    totalPendiente:number=0;
    config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}
    public formAddCuota: FormGroup;
    dataAdministrativo ={'id':0} as Administrativos;

    configMotivo: any = {
		size: 'sm',
		centered: true,
		resolve: { datasend: null }
    }
    
    constructor(private fb: FormBuilder,
        public dialog: NgbModal,
        private activeModal:NgbActiveModal,
        private serviciosCuotas:CuotasServices,
        private servicioCuota:CuotasServices,
        private validatciones:customValidatorMondogo,
        public localdata:localStorageServices,
        private ngxService: NgxUiLoaderService,
        private servicioadministrativo:AdministrativosServices,
		private servicioPago:PagosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
        this.userdata=this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios=Globals.BASE_URL_API_REST;
        this.listaCuotas();

        if(this.userdata.datos.socio){
			this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((data:any)=>{
				this.dataAdministrativo=data.administrativo;
            });
        }

        let pipe = new DatePipe('en-US');

        this.formAddCuota = this.fb.group({
            fecha:[null,Validators.compose([Validators.required,CustomValidators.date])],
            abono:[null,Validators.compose([Validators.required,CustomValidators.number,this.validatciones.checkLimit(6,2)])],
            administrativo_id:[this.dataAdministrativo.id,Validators.compose([Validators.required,CustomValidators.number])],
            pago_id:[this.Pago.id,Validators.compose([Validators.required,CustomValidators.number])],
            estado:[2,null],
        });

        
	}
	
    listaCuotas(){
        let promise = new Promise((resolve, reject) => {
            this.ngxService.startLoader('loaderAddCuotas');
            this.servicioPago.viewPagos(this.Pago.id).subscribe((data:any)=>{
                this.totalAbono=0;
                this.totalAbono=0;
                this.dataPago=data.pago;
                this.rows=data.pago.cuotas;
                this.temp = [...this.rows];
                this.rows.forEach((obj:Cuotas,index)=>{
                    if(obj.estado===1 || obj.estado===2)
                        this.totalAbono+=(obj.abono);
                });
                this.totalPendiente=this.dataPago.montototal-this.totalAbono;
                resolve(true);
                this.ngxService.stopLoader('loaderAddCuotas');
            });
        });
        return promise;
        
	}

    save(){

        this.listaCuotas().then(
		result=>{
            if(result)
                {
                    let cuotasDataModel:Cuotas=this.formAddCuota.value;
                    cuotasDataModel.administrativo_id=this.dataAdministrativo.id;
                    if(cuotasDataModel.abono > 0 && cuotasDataModel.abono <= this.totalPendiente){
                        this.servicioCuota.saveCuotas(cuotasDataModel).subscribe((data:any)=>{
                            if(data.estado){
                                this.Noti.setNotification(data).then((datas)=>{
                                    if(datas){
                                        this.listaCuotas();
                                    }
                                });
                            }else{
                                this.Noti.setNotification(data);
                            }
                        });
                    }else{
                        let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El monto debe ser mayor que <code>0</code> y menor o igual que <code>'+this.totalPendiente+'</code>'}};
                        this.Noti.setNotification(smsFail);
                    }
                }
            },
            reason=>{
                console.log(reason);
            }
        );
    }
    
    delete(dataCuota:any){
		let data={'val':dataCuota.abono,'msg':'Eliminar Cuotas'};
		this.Noti.msgEliminarConfir(data).then((datas)=>{
			if(datas){
				this.serviciosCuotas.deleteCuotas(dataCuota.id).subscribe((smsdata:any)=>{
					if(smsdata.estado){
						this.Noti.setNotification(smsdata).then((datas)=>{
							if(datas){
								this.listaCuotas();
							}
						});
					}else{
						this.Noti.setNotification(smsdata);
					}
				});
			}
		});
	}

	edit(data:any){
		let dialogRef = this.dialog.open(EditCuotaComponent, this.config);
		dialogRef.componentInstance.Cuota = data;
		dialogRef.result.then(
			result=>{
				if(result===1){
					this.listaCuotas();
				}
			},
			reason=>{
				console.log(reason);
			}
		);
	}
    
    cerrarModal(){
        this.activeModal.close(1);
    }

}

