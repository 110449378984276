import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { SideBarMenuServices } from '../../appauth/sidebarmenu/sidebarmenu.services';
import { AuthServices } from '../../appauth/auth/auth.services';
import { localStorageServices } from '../../appauth/auth/localStorageServices';
import { Globals } from '../../global/global.model';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  dataMenus:RouteInfo[]=[];
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  dataUser:any;
  public imgUrlProfile:string;


  constructor(private modalService: NgbModal, 
              private router: Router, 
              private route: ActivatedRoute,
              public authServices:AuthServices,
              public localdata:localStorageServices,
              private menubar:SideBarMenuServices) {
                this.dataUser=localdata.getValueFromLocalStorage().datos;
              }
  // End open close
  ngOnInit() {
    console.log(this.dataUser);

    if(this.dataUser.fotousers[0]!=undefined)
    { 
      
      
      this.imgUrlProfile=Globals.BASE_URL_API_REST+this.dataUser.fotousers[0].dir+this.dataUser.fotousers[0].filename;
    }else{
      this.imgUrlProfile="assets/images/user.png";
    }

    this.menubar.getmenu().subscribe((dataMenu:any)=>{
      dataMenu.menus.forEach(function(currentValue,index,arr) {
        delete currentValue['id']; 
        if(currentValue.children){
          delete currentValue['id'];
        }
      });

      this.dataMenus=dataMenu.menus;
     
      console.log(this.dataMenus);
      
      this.sidebarnavItems = this.dataMenus.filter(sidebarnavItem => sidebarnavItem);
      
    })

    
    //
  }

  cerrarSession(){
    console.log("cerrar session");
    
    this.authServices.logout().subscribe( (data:any) => {
      if(data.message.estado){
        this.localdata.removeValueFromLocalStorage();
        this.router.navigate(['inicio']);
      }
    });
  }

  profile(){
    this.router.navigate(['user-pages/userprofile']);
  }

}
