import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { FotoSociosServices } from '../fotosocio/fotosocios.services';

@Component({
	selector: 'app-add-foto-socio',
	templateUrl: './fotosocio.compente.html',
	styleUrls: ['./fotosocio.compente.scss']
})

export class FotoSociosComponent implements OnInit {
    
    @Input() Socio:any;
    
	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioFotoSocios:FotoSociosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		console.log(this.Socio);
        
	}


	onFileChange(event) {
    if(event.target.files.length > 0) {
        var file = event.target.files[0];
        var formData = new FormData();
        
        formData.append('filename', file);
        formData.append('socio_id',this.Socio.id);
        this.servicioFotoSocios.saveFotoSocio(formData).subscribe((data:any)=>{
        if(data.estado){
            this.Noti.setNotification(data).then((datas)=>{
            if(datas){
                this.activeModal.close(1);
            }
            }); 
            
        }else{
            this.Noti.setNotification(data);
        }
        });
    }
    }

}