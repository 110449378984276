import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Pagogastos } from '../../pagogasto/pagogastos.model';
import { PagogastosServices } from '../../pagogasto/pagogastos.services';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { MultasperiodosServices } from '../../../multasperiodos/multasperiodo/multasperiodos.services';
import * as moment from 'moment';

@Component({
	selector: 'app-editpagogasto',
	templateUrl: './editpagogasto.component.html',
	styleUrls: ['./editpagogasto.component.scss']
})

export class EditPagogastoComponent implements OnInit {

	@Input() Pagogasto:Pagogastos;
	public dataMultasperiodos={'id':0} as Multasperiodos;
	public multasperiodosData:any;

	public formPagogastoFecha:FormGroup;
	public editFecha:boolean=false;

	public formPagogastoDetalle:FormGroup;
	public editDetalle:boolean=false;

	public formPagogastoTotal:FormGroup;
	public editTotal:boolean=false;

	public formPagogastoMultasperiodo:FormGroup;
	public editMultasperiodo:boolean=false;

	public formPagogastoEstado:FormGroup;
	public editEstado:boolean=false;

	constructor(private fb: FormBuilder,
		private activeModal:NgbActiveModal,
		private servicioPagogasto:PagogastosServices,
		private servicioMultasperiodo:MultasperiodosServices,
		private Noti:NotificationServices,) { }

	ngOnInit() {
		this.servicioMultasperiodo.getDataMultasperiodos().subscribe((data:any)=>{
			this.multasperiodosData=data.multasperiodos;
			this.dataMultasperiodos=this.multasperiodosData.find(obj => obj.id == this.Pagogasto.gastosperiodo_id);
		});

		
		this.formPagogastoFecha=this.fb.group({
			fecha:[moment(this.Pagogasto.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});

		this.formPagogastoDetalle=this.fb.group({
			detalle:[this.Pagogasto.detalle,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});


		this.formPagogastoMultasperiodo=this.fb.group({
			gastosperiodo_id:[this.Pagogasto.gastosperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}
	

	editPagogastoFechaEnable(){
		this.editFecha=true;
		this.formPagogastoFecha=this.fb.group({
			fecha:[moment(this.Pagogasto.fecha).utc().format('YYYY-MM-DD'),Validators.compose([Validators.required,CustomValidators.date])],
		});
	}

	editPagogastoFechaDisable(){
		this.editFecha=false;
	}

	savePagogastoFecha(){
		let PagogastoDataModel:Pagogastos=this.formPagogastoFecha.value;
		PagogastoDataModel.id=this.Pagogasto.id;
		this.Pagogasto.fecha=PagogastoDataModel.fecha;
		this.servicioPagogasto.updatePagogastos(PagogastoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editFecha=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPagogastoDetalleEnable(){
		this.editDetalle=true;
		this.formPagogastoDetalle=this.fb.group({
			detalle:[this.Pagogasto.detalle,Validators.compose([Validators.required,Validators.minLength(1), Validators.maxLength(200)])],
		});
	}

	editPagogastoDetalleDisable(){
		this.editDetalle=false;
	}

	savePagogastoDetalle(){
		let PagogastoDataModel:Pagogastos=this.formPagogastoDetalle.value;
		PagogastoDataModel.id=this.Pagogasto.id;
		this.Pagogasto.detalle=PagogastoDataModel.detalle;
		this.servicioPagogasto.updatePagogastos(PagogastoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editDetalle=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}


	editPagogastoMultasperiodoEnable(){
		this.editMultasperiodo=true;
		this.formPagogastoMultasperiodo=this.fb.group({
			gastosperiodo_id:[this.Pagogasto.gastosperiodo_id,Validators.compose([Validators.required,CustomValidators.number])],
		});
	}

	editPagogastoMultasperiodoDisable(){
		this.editMultasperiodo=false;
	}

	savePagogastoMultasperiodo(){
		let PagogastoDataModel:Pagogastos=this.formPagogastoMultasperiodo.value;
		PagogastoDataModel.id=this.Pagogasto.id;
		this.Pagogasto.gastosperiodo_id=PagogastoDataModel.gastosperiodo_id;
		this.servicioPagogasto.updatePagogastos(PagogastoDataModel).subscribe((data:any)=>{
			if(data.estado){
				this.Noti.setNotification(data).then((datas)=>{
					if(datas){
						this.editMultasperiodo=false;
					}
				});
			}else{
				this.Noti.setNotification(data);
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}

