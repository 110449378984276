import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CustomFormsModule } from 'ng2-validation';
import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';
import { ElementComponentService } from './servicios/servicios-component.services';
import { SubPeriodosSeleccionComponent } from './sub-periodos-seleccion/sub-periodos-seleccion.component';

@NgModule({
  declarations: [SubPeriodosSeleccionComponent],
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    CustomFormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    ChartsModule,
    ChartistModule,
  ],
  providers: [
    ElementComponentService
  ],
  exports: [
    SubPeriodosSeleccionComponent
  ]
})
export class ElementComponentModule { }
