import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Globals } from '../../../../global/global.model';
import { Eventos } from '../../../eventos/evento/eventos.model';
import { Asistencias } from '../../asistencia/asistencias.model';
import { AsistenciasServices } from '../../asistencia/asistencias.services';
import { EventosServices } from '../../../eventos/evento/eventos.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportesServices } from '../../../reporte/reportes.services';

@Component({
	selector: 'app-asistencias-pagar',
	templateUrl: './asistencia.component.html',
	styleUrls: ['./asistencia.component.scss']
})

export class AsistenciasComponent implements OnInit {

    @Input() Evento:Eventos;
    rows = [];
    temp = [];
    estados=[];
    public imgUrlSocios:string;
    inconoLoder:string=SPINNER.wanderingCubes;

    userdata:any;
    
    config: any = {
		size: 'lg',
		centered: true,
		resolve: { datasend: null }
	}
    
    constructor(private activeModal:NgbActiveModal,
        private serviciosEvento:EventosServices,
        public dialog: NgbModal,
        public localdata:localStorageServices,
        private ngxService: NgxUiLoaderService,
        private servicioCobrosAportes:AsistenciasServices,
        private servicesReportes:ReportesServices,
        private servicioadministrativo:AdministrativosServices,
		private notificationServices:NotificationServices,
    ){}
    
    ngOnInit(){
        this.userdata=this.localdata.getValueFromLocalStorage();
        this.imgUrlSocios=Globals.BASE_URL_API_REST;
        console.log(this.Evento);
        
        this.serviciosEvento.viewEventoAporte(this.Evento).subscribe((dataactividad:any)=>{
            //console.log(dataactividad);
            this.rows=dataactividad.evento.asistencias;
            this.temp = [...this.rows];
            //console.log(this.rows);
            
        });
        
    }

    updateFilter(event){
        const val = event.target.value.toLowerCase();
       
		const temp = this.temp.filter(d=>{
            let ex:boolean=false;
            if(this.estados.length==0)
                ex=true;
                
            this.estados.forEach(data=>{
                if(d.estado===data){
                    ex=true;
                }               
            });
            
			return (d.socio.nombres.toLowerCase().indexOf(val) !== -1 && ex);
		});
		this.rows = temp;
    }

    setEstado(event){
        let existe:boolean=false;
        let indice:number=0;
        this.estados.forEach(function (data,index) {
            if(event.target.value==data){
                existe=true;
                indice=index;
            }
        })

        if(existe)
            this.estados.splice(indice, 1);
        else
            this.estados.push(Number(event.target.value));
        
       
        const temp = this.temp.filter(item=>{
            let ex:boolean=false;

            if(this.estados.length==0)
                ex=true;

            this.estados.forEach(data=>{
               if(item.estado===data){
                   ex=true;
               }               
            });

            return ex;

        });
        this.rows = temp;
    
    }

    pagar(dataMulta:Asistencias){
		let dataAsistencias= {} as Asistencias;
		let dataconfirmar={'val':dataMulta.id,'msg':'Pagar evento'};
        let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'Inicie sesión como <code>Tesorero</code>'}};
        
        this.servicioCobrosAportes.viewAsistencias(dataMulta.id).subscribe((dataAsistencia:any)=>{
            if(dataAsistencia.asistencia.estado==0){
                if(this.userdata.datos.socio == undefined){
                    this.notificationServices.setNotification(smsFail);
                }else{
                    this.notificationServices.msgEliminarConfir(dataconfirmar).then((datas)=>{
                        if(datas){
        
                            if(this.userdata.datos.socio){
                                this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin:any)=>{
                                    
                                    dataAsistencias.administrativo_id=datadmin.administrativo.id;
                                    dataAsistencias.estado=1;
                                    dataAsistencias.id=dataMulta.id;
        
                                    this.servicioCobrosAportes.updateAsistencias(dataAsistencias).subscribe((datasave:any)=>{
                                        if(datasave.estado){
                                            this.notificationServices.setNotification(datasave).then((datas)=>{
                                                if(datas){
                                                    this.serviciosEvento.viewEventoAporte(this.Evento).subscribe((dataactividad:any)=>{
                                                        this.rows=dataactividad.evento.asistencias;
                                                        this.temp = [...this.rows];
                                                    });
                                                }
                                            });
                                        }else{
                                            this.notificationServices.setNotification(datasave);
                                        }
                                    });
                    
                                    
                    
                                });
                            }
                        
                        }
                    });
                }
            }else{
                let estadoAsistencia="";
                if(dataAsistencia.asistencia.estado===1){
                    estadoAsistencia="Pagado";
                }else if(dataAsistencia.asistencia.estado===2){
                    estadoAsistencia="Anulado";
                }
                let smsFail={'estado':true,'tipomsg':'warning','msg':{'ok':'El asistencia ya esta <code>'+estadoAsistencia+'</code>'}};
                this.notificationServices.setNotification(smsFail);
            }
        });
    }
    
    
}