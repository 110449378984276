import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from '../../../../global/global.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-imprimir-multa',
	templateUrl: './imprimir.component.html',
	styleUrls: ['./imprimir.component.scss']
})

export class ImprimirMultasPagosComponent implements OnInit {
    @Input() dataPdf:any;
    pdfpath:any="";

    constructor(public sanitizer: DomSanitizer,private activeModal:NgbActiveModal,) {}

    ngOnInit(){
        this.pdfpath=this.sanitizer.bypassSecurityTrustResourceUrl(Globals.BASE_URL_API_REST+this.dataPdf); 
    }

    cerrarModal(){
        this.activeModal.close(2);
    }
}