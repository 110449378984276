import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { Eventos } from '../../evento/eventos.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Sectores } from '../../../sectores/sectore/sectores.model';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';


@Component({
	selector: 'app-addevento-customsector',
	templateUrl: './customsector.component.html',
	styleUrls: ['./customsector.component.scss']
})

export class AddCustomSectorEventoComponent implements OnInit {
	rows = [];
	temp = [];
	public imgUrlSocios: string;
	userdata: any;
	ver: boolean = true;
	@Input() Evento = { 'tipo': 3 } as Eventos;
	@Input() Aporteperiodo = { 'montoaporte': 0 } as Aportesperiodos;
	inconoLoder: string = SPINNER.wanderingCubes;
	public dataSectores: any;
	public dataSector: Sectores;
	tiempoacumulado: number = 0;
	montoacumulado: number = 0;
	resulh: number = 0;
	resulm: number = 0;
	filter: boolean = false;
	dataBuscar:string="";

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		public localdata: localStorageServices,
		private changeDetector: ChangeDetectorRef,
		private servicioSocios: SociosServices,
		private servicioPagos: PagosServices,
		private ngxService: NgxUiLoaderService,
		private servicioSectore: SectoresServices,
		private servicioadministrativo: AdministrativosServices,
		private Noti: NotificationServices, ) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios = Globals.BASE_URL_API_REST;
		console.log(this.Aporteperiodo.montoaporte);
		console.log(this.Aporteperiodo.tiempo);
		this.ngxService.startLoader('loaderAportesAgregarSosio');
		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			const temp = data.sectores.filter(function (d) {
				return d.id != 0;
			});
			this.dataSectores = temp;
		});
		this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
			this.rows = data.socios;
			this.temp = [...this.rows];
			this.ngxService.stopLoader('loaderAportesAgregarSosio');
		});
	}

	updateFilter(event) {
		this.filter = true;
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}

	ngAfterViewChecked() { this.changeDetector.detectChanges(); }

	saveallTodo(obj: any) {
		let datasms = { 'val': this.Evento.detalle+' '+obj.cedula+' '+obj.nombres+' '+obj.apellidos, 'msg': 'Asignar valor aporte del socios' };
		this.Noti.setNotificationValor(datasms).then((datas) => {
			if (datas) {
				this.ngxService.startLoader('loaderAportesAgregarSosio');
				let dataPagos = {} as Pagos;
				if (this.userdata.datos.socio) {
					this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

						dataPagos.administrativo_id = datadmin.administrativo.id;
						dataPagos.evento_id = this.Evento.id;
						dataPagos.socio_id = obj.id;
						dataPagos.montototal = Number(datas);
						dataPagos.estado = 0;
						dataPagos.sectore_id = 0;
						this.servicioPagos.savePagos(dataPagos).subscribe((datasave: any) => {
							if(datasave){
								this.servicioSocios.listaactivos(this.Evento).subscribe((data: any) => {
									this.rows = data.socios;
									this.temp = [...this.rows];
									this.ngxService.stopLoader('loaderAportesAgregarSosio');
								});
							}
						});

					});
				}
						
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}