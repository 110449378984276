import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationServices } from '../../../../appauth/notification/notificatio.services';
import { CustomValidators } from 'ng2-validation';
import { Actividades } from '../../actividade/actividades.model';
import { SociosServices } from '../../../socios/socio/socios.services';
import { Globals } from '../../../../global/global.model';
import { localStorageServices } from '../../../../appauth/auth/localStorageServices';
import { Pagos } from '../../../pagos/pago/pagos.model';
import { AdministrativosServices } from '../../../administrativos/administrativo/administrativos.services';
import { Socios } from '../../../socios/socio/socios.model';
import { Aportesperiodos } from '../../../aportesperiodos/aportesperiodo/aportesperiodos.model';
import { PagosServices } from '../../../pagos/pago/pagos.services';
import { Multasperiodos } from '../../../multasperiodos/multasperiodo/multasperiodos.model';
import { Cobrosmultas } from '../../../cobrosmultas/cobrosmulta/cobrosmultas.model';
import { CobrosmultasServices } from '../../../cobrosmultas/cobrosmulta/cobrosmultas.services';
import { SPINNER, NgxUiLoaderService } from 'ngx-ui-loader';
import { Asistencias } from '../../../asistencias/asistencia/asistencias.model';
import { AsistenciasServices } from '../../../asistencias/asistencia/asistencias.services';
import { SectoresServices } from '../../../sectores/sectore/sectores.services';
import { Sectores } from '../../../sectores/sectore/sectores.model';


@Component({
	selector: 'app-addActividade-sector',
	templateUrl: './sector.component.html',
	styleUrls: ['./sector.component.scss']
})

export class AddSectorEventoComponent implements OnInit {
	rows = [];
	temp = [];
	rowsa = [];
	tempa = [];
	ver: boolean = true;
	public imgUrlSocios: string;
	userdata: any;
	inconoLoder: string = SPINNER.wanderingCubes;
	public dataSectores: any;
	public dataSector: Sectores;

	@Input() Actividade = { 'tipo': 3 } as Actividades;
	@Input() Multasperiodo = { 'montomulta': 0 } as Multasperiodos;

	constructor(private fb: FormBuilder,
		private activeModal: NgbActiveModal,
		private servicioSectore: SectoresServices,
		private asistenciasServices: AsistenciasServices,
		public localdata: localStorageServices,
		private servicioSocios: SociosServices,
		private servicioCobrosMultas: CobrosmultasServices,
		private ngxService: NgxUiLoaderService,
		private servicioadministrativo: AdministrativosServices,
		private Noti: NotificationServices, ) { }

	ngOnInit() {
		this.userdata = this.localdata.getValueFromLocalStorage();
		this.imgUrlSocios = Globals.BASE_URL_API_REST;
		this.ngxService.startLoader('loaderMultasAgregarSosio');
		this.servicioSectore.getDataSectores().subscribe((data: any) => {
			const temp = data.sectores.filter(function (d) {
				return d.id != 0;
			});
			this.dataSectores = temp;
		});
		this.servicioSocios.listaactivosMultas(this.Actividade).subscribe((data: any) => {
			this.rows = data.socios;
			this.temp = [...this.rows];
			this.asistenciasServices.getAsistenciasPorActividadConformar(this.Actividade).subscribe((data: any) => {
				this.rowsa = data.asistencias;
				this.tempa = [...this.rows];
			});
			this.ngxService.stopLoader('loaderMultasAgregarSosio');
		});
	}

	updateFilter(event) {
		const val = event.target.value.toLowerCase();
		const temp = this.temp.filter(function (d) {
			return (d.cedula.toLowerCase().indexOf(val) && d.nombres.toLowerCase().indexOf(val) && d.apellidos.toLowerCase().indexOf(val)) !== -1 || !val;
		});
		this.rows = temp;
	}

	onChange(data: any) {
		if (data != undefined) {
			this.dataSector = data;
			const temp = this.temp.filter(function (d) {
				let existe: boolean = false;
				for (let index = 0; index < d.registros.length; index++) {
					if (d.registros[index].sectore_id == data.id) {
						existe = true;
					}
				}
				return existe == true;
			});
			this.rows = temp;
		} else {
			this.dataSector = undefined;
			const temp = this.temp.filter(function (d) {
				return d.id != undefined;
			});
			this.rows = temp;
		}


	}

	saveallGeneral() {
		let datasms = { 'val': this.dataSector.nombre+', '+this.Actividade.detalle, 'msg': 'Asignar actividad al sector' };
		this.Noti.msgConfir(datasms).then((datas) => {
			if (datas) {
				this.ver = false;
				this.rows.forEach((obj: any, index) => {
					setTimeout(() => {
						let dataAsistencias = {} as Asistencias;
						if (this.userdata.datos.socio) {
							this.servicioadministrativo.viewAdministrativosPorSocio(this.userdata.datos.socio).subscribe((datadmin: any) => {

								dataAsistencias.administrativo_id = datadmin.administrativo.id;
								dataAsistencias.actividade_id = this.Actividade.id;
								dataAsistencias.socio_id = obj.id;
								dataAsistencias.estado = 2;
								dataAsistencias.sectore_id = this.dataSector.id;

								this.asistenciasServices.saveAsistencias(dataAsistencias).subscribe((datasave: any) => {

								});

							});
						}
						this.rows.splice(0, 1);
					}, 1500 * index);
				});
			}
		});
	}

	cerrarModal(){
		this.activeModal.close(2);
	}

}