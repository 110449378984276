import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AmortizacionComponent } from './amortizacion/amortizacion.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CrearSolicitudComponent } from './solicitud/crearsolicitud.component';
import { EditSolicitudesMotivoComponent } from './change/changemotivo.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
	PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from  'ngx-ui-loader';

@NgModule({
	imports:[
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		NgxUiLoaderModule,
		NgxUiLoaderRouterModule,
		NgxUiLoaderHttpModule
	],
	declarations:[AmortizacionComponent,CrearSolicitudComponent,EditSolicitudesMotivoComponent],
	entryComponents:[AmortizacionComponent,CrearSolicitudComponent,EditSolicitudesMotivoComponent],
})
export class SolicitudCreditoModalModule { }
